import { Button, Input, Pagination, Select, message } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetchBranchMembers } from "../../apis/branch.api";
import {
  editProcurementEstimateItemDetails,
  fetchProcurementEstimateItemDetails,
} from "../../apis/procurement.api";
import { fetchVendors } from "../../apis/vendor.api";
import { ProcurementItemForm } from "../../components/procurement/form";
import { usePermissions } from "../../custom_hooks/permission/permission_hook";
import { getFormattedDateAndTime } from "../../helpers/date_helper";

const { Option } = Select;

export default function ProcurementManagerNew() {
  const [data, setData] = useState<Array<any>>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState<Array<any>>([]);
  const [filtered_members, setFilteredMembers] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [vendors, setVendors] = useState<Array<any>>([]);

  const [searchLeadId, setSearchLeadId] = useState("");
  const [user, setUser] = useState<any>({});
  const [total_items, setTotalItems] = useState<number>(0);
  const [onlyBlanks, setOnlyBlanks] = useState(true);

  const { hasPermission } = usePermissions();

  const getVendors = async () => {
    const vendors = await fetchVendors({});
    setVendors(vendors);
  };

  useEffect(() => {
    getVendors();
  }, []);

  const getData = async (search?: string, page?: number) => {
    setLoading(true);
    const response = await fetchProcurementEstimateItemDetails(
      undefined,
      search,
      page ?? 1,
      search ? true : onlyBlanks
    );

    setLoading(false);
    if (response !== null) {
      setData(response?.results);
      setTotalItems(response?.count);
    } else {
      setError(true);
    }
  };

  const columns: ColumnsType<any> = [
    {
      key: "lead_id",
      // dataIndex: "lead_id",
      title: "Lead ID",
      width: 150,
      render: (data) => (
        <div style={{ minWidth: 230 }} className="d-flex align-items-center">
          {" "}
          {data?.estimate?.lead_id}
          <Button
            className="ms-2"
            onClick={async () => {
              try {
                // Copy text to clipboard
                var product_name = data?.estimate_item?.boq_item;
                var quantity = data?.estimate_item?.quantity ?? "";
                var price_unit = data?.estimate_item?.unit ?? "";
                var brand = data?.estimate_item?.brand ?? "";

                await navigator.clipboard.writeText(
                  product_name +
                    " \nQuantity:" +
                    quantity +
                    " " +
                    price_unit +
                    " \nBrand - " +
                    brand
                );

                message.success("Item copied");
              } catch (err) {
                console.error("Failed to copy text:", err);
              }
            }}
            type="default"
          >
            Copy Item
          </Button>
        </div>
      ),
    },
    {
      title: "Created at",
      key: "created_at",
      render: (data) => (
        <div style={{ width: 160 }}>
          {getFormattedDateAndTime(data?.estimate?.created_at)}
        </div>
      ),
    },
    {
      key: "boq_item",
      title: "Item Name",
      width: 300,
      render: (data) => (
        <div style={{ minWidth: 300 }}>{data?.estimate_item?.boq_item}</div>
      ),
    },
    hasPermission("pi.private_label_brand_name_column")
      ? {
          key: "private_label_product_name",
          title: "Private Label Name",
          render: (data) => (
            <div style={{ minWidth: 300 }}>
              {data?.estimate_item?.private_label_product_name}
            </div>
          ),
        }
      : {},
    {
      key: "brand",
      title: "Brand",
      width: 120,
      render: (data) => {
        return (
          <div style={{ minWidth: 120 }}>{data?.estimate_item?.brand}</div>
        );
      },
    },
    {
      key: "category_name",
      title: "Category",
      width: 250,
      render: (data) => {
        return (
          <div style={{ minWidth: 250 }}>
            {data?.estimate_item?.category_name}
          </div>
        );
      },
    },
    {
      key: "quantity",
      title: "Quantity",
      width: 150,
      render: (data) => {
        return <div style={{ minWidth: 150 }}>{data?.quantity}</div>;
      },
    },
    {
      key: "unit",
      title: "Unit",
      width: 150,
      render: (data) => {
        return <div style={{ minWidth: 150 }}>{data?.estimate_item?.unit}</div>;
      },
    },
    // {
    //   key: "unit_cost_price",
    //   dataIndex: "unit_cost_price",
    //   title: "Unit Cost Price",
    //   width: 150,
    // },
    // {
    //   key: "mrp",
    //   dataIndex: "mrp",
    //   title: "MRP",
    //   width: 150,
    // },
    // {
    //   key: "landing_price",
    //   dataIndex: "landing_price",
    //   title: "Landing Price",
    //   width: 150,
    // },
    // {
    //   key: "margin",
    //   dataIndex: "margin",
    //   title: "Margin",
    //   width: 150,
    // },
    // {
    //   key: "tax",
    //   title: "Tax",
    //   render: (_, record) => `${record.tax_type} (${record.tax_value}%)`,
    // },
    {
      key: "vendor",
      title: "Vendor",
      width: 150,
      render: (data: any) => {
        return (
          <div style={{ minWidth: 150 }}>
            {data?.estimate_item?.vendor_data
              ?.filter((vendor: any) => vendor?.vendor_code)
              ?.map((vendor: any) => `${vendor?.vendor_code} - ${vendor?.name}`)
              .join(", ") ?? ""}
          </div>
        );
      },
    },
    {
      key: "edit",
      title: "Edit",
      render: (_, record) => {
        const onSubmit = async (values: Record<string, string | number>) => {
          try {
            setLoading(true);
            if (
              (values.total_stock === "" ||
                values.total_stock === "0" ||
                values.total_stock === 0) &&
              (record.total_stock === null || record.total_stock === 0)
            ) {
              delete values.total_stock;
            }

            if (Number(values?.quantity) > Number(record?.quantity)) {
              message.error(
                "Quantity cannot be more than the original quantity"
              );
              return;
            }

            const response = await editProcurementEstimateItemDetails({
              ...values,
              id: record?.id,
            });
            if (response !== null) {
              // Show success message
              message.success("Saved details.");
              getData(searchLeadId);
            }
          } catch (error) {
          } finally {
            setLoading(false);
          }
        };

        const initData = {
          lead_time: record?.lead_time,
          selected_vendor_id:
            record?.selected_vendor ??
            record?.estimate_item?.vendor_data?.[0]?.id,
          selected_vendor_ware_house_id:
            record?.selected_vendor_ware_house?.id ??
            record?.selected_vendor_ware_house,
          remarks: record?.remark,
          // quantity: record?.quantity,
        };

        return (
          <div style={{ minWidth: 600, maxWidth: 1200 }}>
            {vendors?.length > 0 && (
              <ProcurementItemForm
                onSubmit={onSubmit}
                initData={initData}
                vendors={vendors}
                key={record?.id}
                useQuantityField
                originalQuantity={record?.quantity}
              />
            )}
          </div>
        );
      },
      width: 470,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {}, [data, members, filtered_members]);

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      const list: Array<any> = [];
      res?.map((member: any) => {
        list.push(member?.user);
      });
      setMembers(list);
      setFilteredMembers(list);
    });
  }, []);

  const searchWithLeadId = () => {
    getData(searchLeadId, 1);
    setPage(1);
  };

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <h3 className="pb-2">Procurement Manager</h3>
      <div className="d-flex justify-content-end mb-4">
        {/* <Select
          style={{ width: 200 }}
          key="prop"
          onChange={(e) => {
            setSelectedMember(e);
          }}
          showSearch
          onSearch={(e) => {
            let filtered = members?.filter((member) => {
              return member?.f_name?.toLowerCase()?.includes(e);
            });
            console.log("filtered", filtered, e);
            setFilteredMembers([...filtered]);
          }}
          filterOption={false}
          value={selected_member}
          allowClear
        >
          {filtered_members?.map((each) => {
            return (
              <Option key={each.id} value={each.id}>
                {each?.f_name}
              </Option>
            );
          })}
        </Select> */}
        <Input
          value={searchLeadId}
          onChange={(ev) => setSearchLeadId(ev.target.value)}
          onPressEnter={searchWithLeadId}
          style={{ maxWidth: 400, marginLeft: 8, marginRight: 4 }}
          placeholder="Search with Lead Id"
        />
        <Button
          onClick={() => {
            searchWithLeadId();
          }}
        >
          Search
        </Button>
      </div>
      <Table
        dataSource={data}
        loading={loading}
        pagination={false}
        columns={columns}
        key={"id"}
        scroll={{ x: "100%" }}
        onRow={(record, index) => ({
          style: {
            background:
              isNaN(record?.lead_time) &&
              moment(new Date())
                .add(
                  new Date(record?.order_placed_time) < new Date("2024-03-22")
                    ? 5.5
                    : 0,
                  "hours"
                )
                .diff(record?.created_at, "minutes") > 60
                ? "#FFB4A3"
                : isNaN(record?.lead_time) &&
                  moment(new Date())
                    .add(
                      new Date(record?.order_placed_time) <
                        new Date("2024-03-22")
                        ? 5.5
                        : 0,
                      "hours"
                    )
                    .diff(record?.created_at, "minutes") > 20
                ? "#FFFFC0"
                : "",
            // Number(record?.quantity) === Number(record?.po_raised)
            //   ? "#84FACF"
            //   : Number(record?.quantity) > Number(record?.po_raised)
            //   ? "#FFFFC0"
            //   : "#FFB4A3",
          },
        })}
      />
      <Pagination
        current={page}
        total={total_items ?? 1}
        showSizeChanger={false}
        pageSize={100}
        className="mt-3"
        onChange={(p) => {
          setPage(p);
          getData(searchLeadId, p);
        }}
      />
    </div>
  );
}
