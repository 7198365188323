import {} from "@ant-design/icons";
import { Button, Drawer, message, Radio, Spin } from "antd";
import { useEffect, useState } from "react";
import { fetchClientAddresses } from "../../../apis/client.api";
import { getPurchaseOrders } from "../../../apis/po.api";
import { editPI } from "../../../apis/pro_forma_invoice.api";
import AddClientAddressForm from "../../client/add_client_address_form";
import EditClientAddressForm from "../../client/edit_client_address_form";

export interface PIEditAddressProps {
  estimate: any;
  type: "billing" | "shipping";
  refresh: () => void;
  onClose: () => void;
}

export function PIEditAddress({
  estimate,
  type,
  refresh,
  onClose,
}: PIEditAddressProps) {
  const [clientAddresses, setClientAddresses] = useState<any[]>([]);
  const [pos, setPos] = useState<any[]>([]);
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [showEditAddressForm, setShowEditAddressForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(
    type === "billing"
      ? estimate?.bill_address?.id ?? null
      : estimate?.ship_address?.id ?? null
  );

  const fetchPOs = () => {
    getPurchaseOrders({ estimate: estimate?.id }).then((res) => {
      if (res?.results) {
        setPos(res?.results ?? []);
      }
    });
  };

  const fetchAddresses = () => {
    setLoading(true);
    fetchClientAddresses(estimate?.client)
      .then((res) => {
        setClientAddresses(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!estimate?.client || !estimate?.id) {
      return;
    }

    fetchPOs();
    fetchAddresses();
  }, [estimate?.id]);

  const editAddress = () => {
    if (!selectedAddressId) {
      return;
    }

    const payload = {} as any;
    const selectedAddress = clientAddresses.find(
      (a) => a.id === selectedAddressId
    );
    if (type === "billing") {
      payload.bill_address = selectedAddressId;
      payload.bill_address_description = `${selectedAddress?.flat_no}, ${selectedAddress?.address}`;
    } else {
      payload.ship_address = selectedAddressId;
      payload.ship_address_description = `${selectedAddress?.flat_no}, ${selectedAddress?.address}`;
    }

    message.loading({ content: "Updating address...", key: "edit-address" });
    editPI(estimate?.id, payload).then((res) => {
      if (!res) {
        message.error({
          content: "Failed to update address. Please try again.",
          key: "edit-address",
        });
        return;
      }
      refresh();
      onClose();
      message.destroy("edit-address");
    });
  };

  return (
    <>
      <div>
        <div className="mb-2 d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Editing {type} address</h5>
          <Button type="primary" onClick={editAddress}>
            Save
          </Button>
        </div>
        {loading && (
          <div className="d-flex justify-content-center mb-3">
            <Spin />
          </div>
        )}
        {!loading && (
          <Button
            className="w-100 d-block mb-2"
            type="default"
            onClick={() => setShowAddAddressForm(true)}
          >
            Add Address
          </Button>
        )}
        {clientAddresses?.map((address) => (
          <div
            key={address?.id}
            className="rounded border shadow-sm mb-2 d-flex gap-1 align-items-center p-2"
            role="button"
            onClick={() => {
              setSelectedAddressId(address?.id);
            }}
          >
            <div>
              <Radio checked={address?.id === selectedAddressId} />
            </div>
            <div className="flex-grow-1">
              {address?.name}
              <div className="text-muted" style={{ fontSize: 12 }}>
                {address?.address}
              </div>
            </div>
            <div>
              {/* <Button
                type="default"
                size="small"
                onClick={(ev) => {
                  setShowEditAddressForm(true);
                }}
              >
                Edit
              </Button> */}
            </div>
          </div>
        ))}
      </div>
      <Drawer
        open={showEditAddressForm}
        onClose={() => {
          setShowEditAddressForm(false);
        }}
        title="Edit Address"
      >
        <EditClientAddressForm
          address={clientAddresses.find((a) => a.id === selectedAddressId)}
          fetchAddresses={fetchAddresses}
          onClientEditAddressClose={() => {
            setShowEditAddressForm(false);
          }}
        />
      </Drawer>
      <Drawer
        open={showAddAddressForm}
        onClose={() => setShowAddAddressForm(false)}
        title="Add Address"
      >
        <AddClientAddressForm
          fetchAddresses={fetchAddresses}
          onClientAddAddressDrawerClose={() => {
            setShowAddAddressForm(false);
          }}
          user_id={estimate?.client}
        />
      </Drawer>
    </>
  );
}
