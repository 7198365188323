import { PushpinOutlined } from "@ant-design/icons";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import { MapAddressExport } from "..";

const libraries = ["places"];

export interface CenterMapProps {
  initCenter: {
    lat: number;
    lng: number;
  };
  containerStyle: React.CSSProperties;
  finalAddress?: MapAddressExport;
  selectedAddress?: MapAddressExport;
  setFinalAddress: (address: MapAddressExport) => void;
  finalising?: boolean;
  setFinalising?: (finalising: boolean) => void;
}

export const GOOGLE_MAPS_API_KEY = "AIzaSyBLtE-GIJXxkxbfwMatgEqr8ZKyDHUBcN8";

export function CenterMap({
  containerStyle,
  initCenter,
  finalAddress,
  selectedAddress,
  setFinalAddress,
  finalising,
  setFinalising,
}: CenterMapProps) {
  const mapRef = useRef<google.maps.Map | null>(null);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [centerPannedTo, setCenterPannedTo] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: libraries as any,
  });

  useEffect(() => {
    if (
      finalAddress &&
      mapRef.current &&
      centerPannedTo?.lat !== finalAddress.lat &&
      centerPannedTo?.lng !== finalAddress.long
    ) {
      // Zoom in
      // mapRef.current.setZoom(16);
      mapRef.current.panTo({
        lat: finalAddress.lat,
        lng: finalAddress.long,
      });
      setCenterPannedTo({
        lat: finalAddress.lat,
        lng: finalAddress.long,
      });
    }
  }, [finalAddress]);

  const onCenterChanged = () => {
    clearTimeout(debounceTimeout.current!);
    const center = mapRef.current?.getCenter();

    if (
      !selectedAddress ||
      (finalAddress?.lat === center?.lat() &&
        finalAddress?.long === center?.lng()) ||
      // If the center is within 10-15 meters of the final address, don't do anything
      (Math.abs((center?.lat() ?? 0) - (finalAddress?.lat ?? 0)) < 0.0001 &&
        Math.abs((center?.lng() ?? 0) - (finalAddress?.long ?? 0)) < 0.0001)
    )
      return;

    debounceTimeout.current = setTimeout(() => {
      setFinalising && setFinalising(true);

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: center,
          // @ts-ignore
          // extraComputations: ["ADDRESS_DESCRIPTORS"],
        },
        (results, status) => {
          if (status === "OK") {
            if (results?.[0]) {
              const address = results[0];

              const placeId = address.place_id;
              // Get place details with this ID
              const service = new google.maps.places.PlacesService(
                mapRef.current!
              );

              service.getDetails({ placeId }, (place, status) => {
                if (status === "OK" && place) {
                  if (setFinalAddress && finalAddress) {
                    if (setFinalising) {
                      setFinalising(false);
                    }

                    setFinalAddress({
                      ...finalAddress,
                      name: place?.name ?? "",
                      address: address?.formatted_address ?? "",
                      lat: center?.lat() || 0,
                      long: center?.lng() || 0,
                    });
                  }
                }
              });
            } else {
              console.log("No results found");
            }
          } else {
            console.log("Geocoder failed due to: " + status);
          }
        }
      );
    }, 1000);

    // Get place ID for center of map

    // const center = map.getCenter();
    // setCenter({
    //   lat: center.lat(),
    //   lng: center.lng(),
    // });
  };

  if (!isLoaded)
    return (
      <div
        style={{
          ...containerStyle,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading...
      </div>
    );

  return (
    <div
      style={{
        ...containerStyle,
        position: "relative",
      }}
    >
      {selectedAddress && (
        <div
          className="w-100 h-100 top-0 start-0 d-flex flex-column align-items-center justify-content-center"
          style={{ position: "absolute", zIndex: 5, pointerEvents: "none" }}
        >
          <div
            className="bg-black text-white th-fontSize-12 rounded p-2 d-flex align-items-center"
            // give a specific height, and move it up by that height such that the pin is at the center of the map
            style={{
              marginTop: -50,
              height: 32,
              width: "fit-content",
              marginBottom: 8,
            }}
          >
            Move pin to your exact location
          </div>

          <PushpinOutlined
            color="#4386F1"
            style={{
              filter: `drop-shadow(0 0 2px rgba(0,0,0,.6))`,
              fontSize: 32,
            }}
          />
        </div>
      )}
      <GoogleMap
        zoom={selectedAddress ? 18 : 12}
        // center={{
        //   lat: initCenter.lat,
        //   lng: initCenter.lng,
        // }}
        options={{
          // maxZoom: 12,
          minZoom: 14,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          zoomControl: false,
        }}
        mapContainerClassName="map-container"
        mapContainerStyle={containerStyle}
        onLoad={(map) => {
          mapRef.current = map;
          map.panTo({
            lat: initCenter.lat,
            lng: initCenter.lng,
          });
        }}
        onCenterChanged={onCenterChanged}
        clickableIcons={false}
      ></GoogleMap>
    </div>
  );
}
