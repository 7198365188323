import { Button, Pagination, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  fetchClientAddresses,
  fetchClientDetails,
} from "../../../apis/client.api";
import { fetchProFormaInvoices } from "../../../apis/pro_forma_invoice.api";
import { getFormattedDateAndTime } from "../../../helpers/date_helper";

export interface ClientDetailsMobileProps {
  userId: string;
  onBack: () => void;
}

const statusOptions = [
  { id: "1", value: "draft", label: "Draft", disabled: true },
  {
    id: "2",
    value: "availability_check",
    label: "Request Delivery Timeline",
  },
  {
    id: "14",
    value: "payment_pending",
    label: "Payment Pending",
  },
  {
    id: "3",
    value: "quote_approval_pending",
    label: "Quote Approval pending",
  },
  {
    id: "4",
    value: "order_placed",
    label: "Order Placed",
  },
  {
    id: "5",
    value: "order_lost",
    label: "Order lost",
  },
  {
    id: "12",
    value: "order_cancelled",
    label: "Order Cancelled",
  },
  {
    id: "6",
    value: "order_confirmed",
    label: "Order confirmed",
    disabled: true,
  },
  {
    id: "7",
    value: "partly_shipped",
    label: "Partly shipped",
    disabled: true,
  },
  {
    id: "8",
    value: "shipped",
    label: "Shipped",
    disabled: true,
  },
  {
    id: "9",
    value: "partly_delivered",
    label: "Partly delivered",
    disabled: true,
  },
  {
    id: "10",
    value: "delivered",
    label: "Delivered",
    disabled: true,
  },
  {
    id: "11",
    value: "refunded",
    label: "Refunded",
  },
];

export function ClientDetailsMobile({
  userId,
  onBack,
}: ClientDetailsMobileProps) {
  const [details, setDetails] = useState<any>();
  const [addresses, setAddresses] = useState<any[]>([]);
  const [clientPIs, setClientPIs] = useState<any[]>([]);
  const [clientPIsCount, setClientPIsCount] = useState<number>(0);
  const [clientPIsPage, setClientPIsPage] = useState<number>(1);

  useEffect(() => {
    fetchClientDetails(userId).then((res) => {
      setDetails(res);
    });
    fetchClientAddresses(userId).then((res) => {
      setAddresses(res);
    });
  }, [userId]);

  useEffect(() => {
    fetchProFormaInvoices({
      client: userId,
      page: clientPIsPage,
      is_revised: false,
    }).then((res) => {
      setClientPIs(res.results);
      setClientPIsCount(res.count);
    });
  }, [userId, clientPIsPage]);

  return (
    <div className="">
      <div
        className="d-flex justify-content-between bg-white p-3"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <h4 className="fw-medium mb-3">Client Details</h4>
        <div>
          <Button type="link" onClick={onBack}>
            Back
          </Button>
        </div>
      </div>
      <div className="row p-3 mx-0 shadow-sm rounded">
        <div className="col">
          {details?.f_name && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Name </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.f_name}</div>
                </div>
              </div>
            </div>
          )}
          {details?.email && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Email </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.email}</div>
                </div>
              </div>
            </div>
          )}
          {details?.contact && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Phone</div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.contact}</div>
                </div>
              </div>
            </div>
          )}
          {details?.user_source && (
            <div className="row">
              <div className="col-4 th-font-weight-500">User source </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.user_source}</div>
                </div>
              </div>
            </div>
          )}
          {details?.city && (
            <div className="row">
              <div className="col-4 th-font-weight-500">City </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.city}</div>
                </div>
              </div>
            </div>
          )}
          {details?.company_name && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Company Name </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.company_name}</div>
                </div>
              </div>
            </div>
          )}
          {details?.whatsapp_group_link && (
            <div className="row">
              <div className="col-4 th-font-weight-500">WA group link </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <a
                    href={details?.whatsapp_group_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {details?.whatsapp_group_link}
                  </a>
                </div>
              </div>
            </div>
          )}
          {details?.gst_number && (
            <div className="row">
              <div className="col-4 th-font-weight-500">GST No. </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.gst_number}</div>
                </div>
              </div>
            </div>
          )}
          {details?.rupifi_activation_url && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Rupifi link </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.rupifi_activation_url}</div>
                </div>
              </div>
            </div>
          )}
          {details?.created_at && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Created at </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{getFormattedDateAndTime(details?.created_at)}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <h5 className="mt-4 px-3">Addresses</h5>
      {addresses?.length === 0 && (
        <div className="p-3 shadow-sm px-3 rounded mt-2 gy-2">No Addresses</div>
      )}
      {addresses?.map((address) => (
        <div className="p-3 shadow-sm rounded mt-2 px-3" key={address?.id}>
          <h6>{address?.name}</h6>
          <div style={{ fontSize: 13 }}>{address?.address}</div>
        </div>
      ))}
      <h5 className="mt-4 px-3" id="client-pis-header">
        Client PIs
      </h5>
      {clientPIs?.length === 0 && (
        <div className="p-3 shadow-sm px-3 rounded mt-2 gy-2">No PIs found</div>
      )}
      {clientPIs.map((pi) => (
        <div className="row p-3 shadow-sm mx-0 rounded mt-2 gy-2" key={pi?.id}>
          <div className="col-12">
            <h6>Order #{pi?.lead_id}</h6>
          </div>
          <div className="col-6">
            <div style={{ fontSize: 11 }} className="fw-bold">
              Created At
            </div>
            <div>{moment(pi?.created_at).format("DD/MM/YYYY HH:mm")}</div>
          </div>
          <div className="col-6">
            <div style={{ fontSize: 11 }} className="fw-bold">
              Total Amount
            </div>
            <div>₹ {pi?.total_estimate_amount}</div>
          </div>
          <div className="col-6">
            <div style={{ fontSize: 11 }} className="fw-bold">
              Status
            </div>
            <div>
              {
                statusOptions.find(
                  (status) => status?.value === pi?.estimate_status
                )?.label
              }
            </div>
          </div>
          <div className="col-6">
            <div style={{ fontSize: 11 }} className="fw-bold">
              Actions
            </div>
            <Space>
              <a
                href={`https://materialdepot.in/public-orders/${pi?.lead_id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="primary" size="small">
                  Open Tracking
                </Button>
              </a>
            </Space>
          </div>
        </div>
      ))}
      {clientPIsCount > 0 && (
        <Pagination
          total={clientPIsCount}
          current={clientPIsPage}
          onChange={(page) => {
            setClientPIsPage(page);
            // Scroll to header of PIs
            const header = document.getElementById("client-pis-header");
            if (header) {
              header.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }}
          pageSize={10}
          showSizeChanger={false}
          style={{ textAlign: "center" }}
          className="mt-3"
        />
      )}
    </div>
  );
}
