import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  FormInstance,
  Input,
  message,
  Radio,
  Select,
  Space,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchBranchMembers } from "../../../apis/branch.api";
import {
  addClient,
  assignClientBM,
  fetchClients,
} from "../../../apis/client.api";
import {
  fetchUserDetails,
  getUserProperties,
  getUserPropertyList,
  incrementUserVisit,
  updateOrCreateUserProperty,
} from "../../../apis/user.api";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";
import { ClientProperties, UserInfoProperty } from "../client_properties";
import EditClientForm from "../edit_client_form";
const { Option } = Select;

export interface AddClientFormProps {
  onComplete?: () => void;
  onClose?: () => void;
  initContact?: string;
}

export const SkipBMList = [
  "Altamash",
  "Amritha",
  "Rupal Sahu",
  "Nirbhay",
  "Vikas old",
  "Kalyan",
  // "Reception Monica",
  "Reception",
];

const AddClientForm = ({
  onComplete,
  onClose,
  initContact,
}: AddClientFormProps) => {
  const { hasPermission } = usePermissions();
  const [form] = Form.useForm();
  const [view, setView] = useState<"details" | "properties">("details");
  const [properties, setProperties] = useState<UserInfoProperty[]>([]);
  const [branchMembers, setBranchMembers] = useState<any[]>([]);
  const [preExistingUser, setPreExistingUser] = useState<any | null>(null);
  const [isReceptionist, setIsReceptionist] = useState<boolean>(false);
  const [requirementsOptionCounterMap, setRequirementsOptionCounterMap] =
    useState<any>({});
  const [optionalRequirementOthers, setOptionalRequirementOthers] =
    useState<string>("");
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (preExistingUser) {
      form.setFieldsValue({
        contact: preExistingUser?.contact,
        salutation: preExistingUser?.salutation,
        f_name: preExistingUser?.f_name,
        l_name: preExistingUser?.l_name,
        // email: preExistingUser?.email,
        bm: preExistingUser?.user_manager?.id,
      });

      getUserProperties(preExistingUser?.id).then((res) => {
        res?.forEach((prop: any) => {
          if (prop?.property?.name === "Source") {
            form.setFieldsValue({
              source: prop?.value,
            });
          }
          if (prop?.property?.name === "Requirements") {
            form.setFieldsValue({
              requirements: prop?.value?.split(","),
            });
          }
          if (prop?.property?.name === "Location") {
            form.setFieldsValue({
              location: prop?.value,
            });
          }
          if (prop?.property?.name === "Potential Order Value") {
            form.setFieldsValue({
              potential_order_value: prop?.value,
            });
          }
        });
      });
    }
  }, [preExistingUser]);

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      setBranchMembers(res);

      fetchUserDetails().then((user) => {
        const idList = [
          "d2c3e746-240f-45bd-9aea-c64222915f85",
          // "1e0411d9-a5b1-45bd-bd2d-02c5bbcc0962",
        ];

        const orgUser = res?.find((u: any) => u?.user?.id === user?.id);

        if (
          idList.includes(user?.id) ||
          [4, 11].includes(orgUser?.user_permission)
        ) {
          setIsReceptionist(true);
        }
      });
    });
    getUserPropertyList().then((res) => {
      setProperties(res);
    });
  }, []);

  const [client, setClient] = useState<any>();

  const onFinish = async (updatedValues: any) => {
    const source = updatedValues?.source;
    const location = updatedValues?.location;
    const bm = updatedValues?.bm;
    const age = updatedValues?.age;
    const type_of_property = updatedValues?.type_of_property;
    const project_type = updatedValues?.project_type;
    const family = updatedValues?.family;
    const des_arch = updatedValues?.des_arch;
    const potential_order_value = updatedValues?.potential_order_value;

    // If all the requirement object properties are 0, show error message and return early.
    if (
      Object.values(requirementsOptionCounterMap).every((val) => val === 0) &&
      !isReceptionist &&
      !optionalRequirementOthers
    ) {
      message.error("Please increment at least one requirement");
      return;
    }

    // updatedValues.roles = 10;

    const payloadForPropertyUpdation = {
      user_id: null as any,
      properties: [] as Array<any>,
    };

    if (source) {
      const sourceProperty = properties?.find((p) => p?.name === "Source");
      payloadForPropertyUpdation.properties.push({
        property_id: sourceProperty?.id,
        value: source,
      });

      delete updatedValues?.source;
    }

    const requirementsProperty = properties?.find(
      (p) => p?.name === "Requirements"
    );
    const req = {
      ...requirementsOptionCounterMap,
      Others: optionalRequirementOthers,
    };

    payloadForPropertyUpdation.properties.push({
      property_id: requirementsProperty?.id,
      value: JSON.stringify(req),
    });

    delete updatedValues?.requirements;

    if (age) {
      const ageProperty = properties?.find((p) => p?.name === "Age");
      payloadForPropertyUpdation.properties.push({
        property_id: ageProperty?.id,
        value: age,
      });

      delete updatedValues?.age;
    }

    if (type_of_property) {
      const typeOfPropertyProperty = properties?.find(
        (p) => p?.name === "Type of Property"
      );
      payloadForPropertyUpdation.properties.push({
        property_id: typeOfPropertyProperty?.id,
        value: type_of_property,
      });

      delete updatedValues?.type_of_property;
    }

    if (project_type) {
      const projectTypeProperty = properties?.find(
        (p) => p?.name === "Project Type"
      );
      payloadForPropertyUpdation.properties.push({
        property_id: projectTypeProperty?.id,
        value: project_type,
      });

      delete updatedValues?.project_type;
    }

    if (family) {
      const familyTypeProperty = properties?.find((p) => p?.name === "Family");
      payloadForPropertyUpdation.properties.push({
        property_id: familyTypeProperty?.id,
        value: family,
      });

      delete updatedValues?.family;
    }

    if (location) {
      const locationProperty = properties?.find((p) => p?.name === "Location");
      payloadForPropertyUpdation.properties.push({
        property_id: locationProperty?.id,
        value: location,
      });

      delete updatedValues?.location;
    }

    if (des_arch) {
      const desArchProperty = properties?.find(
        (p) => p?.name === "Has Designer / Architect?"
      );
      payloadForPropertyUpdation.properties.push({
        property_id: desArchProperty?.id,
        value: des_arch,
      });

      delete updatedValues?.des_arch;
    }

    if (potential_order_value) {
      const potentialOrderValueProperty = properties?.find(
        (p) => p?.name === "Potential Order Value"
      );
      payloadForPropertyUpdation.properties.push({
        property_id: potentialOrderValueProperty?.id,
        value: potential_order_value,
      });

      delete updatedValues?.potential_order_value;
    }

    const client = (await addClient({
      ...updatedValues,
      roles: 10,
      email: null,
    })) as any;

    if (!client) {
      message.error("Error adding client. Please check and try again");
      return;
    }

    if (hasPermission("client.footfall")) {
      let result = null;

      if (bm) {
        // If bm is already selected, get user ID
        const bmUserId = branchMembers?.find(
          (bmO) => String(bmO?.id) === String(bm)
        )?.user?.id;
        result = await incrementUserVisit(client?.id, bmUserId);
      } else {
        result = await incrementUserVisit(client?.id);
      }

      if (result?.message) {
        message.warning(result?.message);
      } else if (Boolean(result)) {
        message.success("Visit counter incremented successfully");
      } else {
        message.error("Error incrementing visit counter");
      }
    }

    setClient(client);

    payloadForPropertyUpdation.user_id = client?.id;

    if (payloadForPropertyUpdation.properties.length > 0) {
      updateOrCreateUserProperty(payloadForPropertyUpdation).then((res) => {
        console.log("Source updated", res);
      });
    }

    if (updatedValues?.bm) {
      const bmObj = branchMembers?.find((bm) => bm.id === updatedValues?.bm);
      assignClientBM(client?.id, bmObj?.id, bmObj?.user_permission);

      delete updatedValues?.bm;
    }

    if (updatedValues?.sales_bm) {
      const bmObj = branchMembers?.find(
        (bm) => bm?.id === updatedValues?.sales_bm
      );
      if (bmObj) {
        assignClientBM(client?.id, bmObj?.id, bmObj?.user_permission);
      }

      delete updatedValues?.sales_bm;
    }

    form.resetFields();
    // Reset other states too
    setPreExistingUser(null);
    setRequirementsOptionCounterMap({});
    setOptionalRequirementOthers("");
    setView("details");
    if (onComplete) {
      onComplete();
    }
  };

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable || preExistingUser}
      >
        Submit
      </Button>
    );
  };

  const getView = () => {
    if (view === "details") {
      return (
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={{
            contact: initContact,
          }}
        >
          <Form.Item
            name="contact"
            label="Phone"
            rules={[
              {
                required: true,
                pattern: /^[1-9]\d{9}$/gi,
                message: "Enter a valid phone number",
              },
            ]}
          >
            <Input
              type="number"
              maxLength={10}
              onChange={async (ev) => {
                if (ev.target.value.length === 10) {
                  console.log("search", ev.target.value);
                  const clientDetails = await fetchClients({
                    params: {
                      search: ev.target.value,
                    },
                  });

                  if (clientDetails?.results?.length > 0) {
                    setPreExistingUser(clientDetails?.results?.[0]);
                  } else {
                    setPreExistingUser(null);
                  }
                }
              }}
            />
          </Form.Item>

          {preExistingUser && hasPermission("client.footfall") && (
            <>
              <Space className="mb-2">
                <div>User already exists. Increment visit counter?</div>
                <Button
                  type="primary"
                  onClick={async () => {
                    if (!hasPermission("client.footfall")) {
                      message.error(
                        "You don't have permission to increment visit"
                      );
                      return;
                    }

                    let result = null;
                    if (form.getFieldValue("bm")) {
                      // If bm is already selected, get user ID
                      const bmUserId = branchMembers?.find(
                        (bm) =>
                          String(bm?.id) === String(form.getFieldValue("bm"))
                      )?.user?.id;
                      result = await incrementUserVisit(
                        preExistingUser?.id,
                        bmUserId
                      );
                    } else {
                      result = await incrementUserVisit(preExistingUser?.id);
                    }

                    if (result?.message) {
                      message.warning(result?.message);
                    } else if (Boolean(result)) {
                      message.success("Visit counter incremented successfully");
                    } else {
                      message.error("Error incrementing visit counter");
                    }
                    onComplete?.();
                    onClose?.();
                  }}
                >
                  Add
                </Button>
              </Space>
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <span>Edit Client Instead?</span>
                <Button
                  type="primary"
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      navigate(`/client/${preExistingUser?.id}/edit`);
                    } else {
                      setShowEditDrawer(true);
                    }
                  }}
                >
                  Edit
                </Button>
              </div>
            </>
          )}
          <div className="d-flex gap-2">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Enter a valid salutation",
                },
              ]}
              name="salutation"
              label="Salutation"
              style={{ width: 90 }}
            >
              <Select placeholder="Select salutation">
                <Option value="Mr">Mr</Option>
                <Option value="Mrs">Ms</Option>
                <Option value="Mx">Mx</Option>
              </Select>
            </Form.Item>
            <Form.Item
              required
              name="f_name"
              label="Name"
              className="flex-grow-1"
              rules={
                [
                  // {
                  //   pattern: /^[a-zA-Z\s]*$/gi,
                  //   message: "Enter a valid name",
                  // }
                ]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="l_name"
              label="Last Name"
              className="flex-grow-1"
              rules={
                [
                  // {
                  //   pattern: /^[a-zA-Z\s]*$/gi,
                  //   message: "Enter a valid name",
                  // }
                ]
              }
            >
              <Input />
            </Form.Item>
          </div>
          {/* <Form.Item
            name="project_type"
            label="Project Type"
            rules={[
              {
                required: true,
                message: "Enter a valid project type",
              },
            ]}
          >
            <Select placeholder="Select project type">
              {properties
                ?.find((p) => p?.name === "Project Type")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="project_type"
            label="Project Type"
            rules={[
              {
                required: !isReceptionist,
                message: "Enter a valid project type",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Project Type")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>

          {/* <Form.Item
            name="type_of_property"
            label="Property Type"
            rules={[
              {
                required: true,
                message: "Enter a valid property type",
              },
            ]}
          >
            <Select placeholder="Select property type">
              {properties
                ?.find((p) => p?.name === "Type of Property")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="type_of_property"
            label="Property Type"
            rules={[
              {
                required: !isReceptionist,
                message: "Enter a valid property type",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Type of Property")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            rules={[
              {
                required: true,
                message: "Enter a valid location",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            name="source"
            label="Source"
            rules={[
              {
                required: true,
                message: "Enter a valid source",
              },
            ]}
          >
            <Select>
              {properties
                ?.find((p) => p?.name === "Source")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="source"
            label="Source"
            rules={[
              {
                required: true,
                message: "Enter a valid source",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Source")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>
          {/* <Form.Item
            name="requirements"
            label="Requirements"
            rules={[
              {
                required: true,
                message: "Enter a valid requirement",
              },
            ]}
          >
            <Select mode="multiple" placeholder="Select requirements">
              {properties
                ?.find((p) => p?.name === "Requirements")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item> */}
          <div className="mb-2">
            {!isReceptionist && <span className="text-danger">*</span>}{" "}
            Requirements
          </div>
          <div
            id="requirements-grid"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: ".5rem",
            }}
          >
            {properties
              ?.find((p) => p?.name === "Requirements")
              ?.options?.map((option) => {
                return (
                  <div className="d-flex gap-1" key={option}>
                    <Button
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        setRequirementsOptionCounterMap((prev: any) => {
                          const copyPrev = { ...prev };
                          copyPrev[option] = (copyPrev[option] ?? 0) + 1;
                          return copyPrev;
                        });
                      }}
                    />
                    <span>{requirementsOptionCounterMap[option] ?? 0}</span>
                    <Button
                      size="small"
                      icon={<MinusOutlined />}
                      onClick={() => {
                        setRequirementsOptionCounterMap((prev: any) => {
                          const copyPrev = { ...prev };
                          copyPrev[option] = (copyPrev[option] ?? 0) - 1;
                          if (copyPrev[option] < 0) {
                            copyPrev[option] = 0;
                          }
                          return copyPrev;
                        });
                      }}
                    />
                    <span className="flex-grow-1">{option}</span>
                  </div>
                );
              })}
            {/* Others */}
            <div className="d-flex gap-1 mb-3">
              <Input
                placeholder="Others"
                value={optionalRequirementOthers}
                onChange={(ev) => setOptionalRequirementOthers(ev.target.value)}
                size="small"
              />
            </div>
          </div>
          <Form.Item
            name="potential_order_value"
            label="Potential Order Value"
            rules={[
              {
                // required: !isReceptionist,
                message: "Enter a valid potential order value",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          {/* <Form.Item
            name="age"
            label="Age"
            rules={[
              {
                required: true,
                message: "Enter a valid age",
              },
            ]}
          >
            <Select placeholder="Select age">
              {properties
                ?.find((p) => p?.name === "Age")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item> */}

          <Form.Item
            name="age"
            label="Age"
            rules={[
              {
                required: !isReceptionist,
                message: "Enter a valid age",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Age")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>

          {/* <Form.Item
            name="family"
            label="Family Type"
            rules={[
              {
                required: true,
                message: "Enter a valid family type",
              },
            ]}
          >
            <Select placeholder="Select family type">
              {properties
                ?.find((p) => p?.name === "Family")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item> */}
          <Form.Item
            name="family"
            label="Family Type"
            rules={[
              {
                required: !isReceptionist,
                message: "Enter a valid family type",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Family")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="des_arch"
            label="Has Designer / Architect?"
            rules={[
              {
                required: !isReceptionist,
                message: "Enter a valid value",
              },
            ]}
          >
            <Radio.Group>
              {properties
                ?.find((p) => p?.name === "Has Designer / Architect?")
                ?.options?.map((option) => {
                  return (
                    <Radio key={option} value={option}>
                      {option}
                    </Radio>
                  );
                })}
            </Radio.Group>
          </Form.Item>
          {/* <Form.Item
              label="Medium"
              name={"medium"}
              rules={[
                {
                  required: true,
                  message: "Enter a valid user medium",
                },
              ]}
            >
              <Select placeholder="Select user medium">
                <Option value="Fbig">Fbig</Option>
                <Option value="SEO">SEO</Option>
                <Option value="SEM">SEM</Option>
                <Option value="SMM">SMM</Option>
                <Option value="Referral">Referral</Option>
                <Option value="Offline">Offline</Option>
                <Option value="Newsletter">Email Marketing/Newsletter</Option>
              </Select>
            </Form.Item> */}
          <div className="d-flex gap-2">
            <Form.Item
              name="bm"
              label="BM"
              style={{
                flexGrow: 1,
                flexShrink: 0,
              }}
            >
              <Select
                placeholder="Select BM"
                showSearch
                filterOption={(input, option) =>
                  ((option?.children as unknown as string) ?? "")
                    .toLowerCase()
                    ?.indexOf(input.toLowerCase()) >= 0
                }
              >
                {branchMembers
                  ?.filter((bm) => bm?.user_permission === 3)
                  ?.filter(
                    (bm) =>
                      !SkipBMList.map((s) => s.toLowerCase()).includes(
                        bm?.user?.f_name?.toLowerCase()
                      )
                  )
                  ?.map((bm) => {
                    return <Option value={bm.id}>{bm.user.f_name}</Option>;
                  })}
              </Select>
            </Form.Item>
            {/* <Form.Item
              name="sales_bm"
              label="Pre Sales BM"
              style={{ flexShrink: 0 }}
            >
              <Select
                placeholder="Select Pre Sales BM"
                showSearch
                filterOption={(input, option) =>
                  ((option?.children as unknown as string) ?? "")
                    .toLowerCase()
                    ?.indexOf(input.toLowerCase()) >= 0
                }
              >
                {branchMembers
                  ?.filter((bm) => bm?.user_permission === 4)
                  ?.map((bm) => {
                    return (
                      <Option value={bm?.id} key={bm?.id}>
                        {bm?.user?.f_name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item> */}
          </div>
          <Form.Item>
            <Space>
              <SubmitButton form={form} />
            </Space>
          </Form.Item>
        </Form>
      );
    }

    if (view === "properties") {
      if (!client) {
        return (
          <div>
            <div className="alert alert-danger">
              Please fill basic details first to continue adding extra details
            </div>
          </div>
        );
      }

      if (client?.roles?.id) {
        client.roles = client?.roles?.id;
      }

      return (
        <ClientProperties
          userId={client?.id}
          onComplete={() => {
            message.success("Client extra details added successfully");
          }}
          isStudio={client?.roles === 8}
          client={client}
        />
      );
    }
  };

  return (
    <>
      <Tabs
        items={[
          {
            key: "details",
            label: "Basic Details",
          },
          {
            key: "properties",
            label: "+ Details",
          },
        ]}
        activeKey={view}
        onChange={(view) => setView(view as "details" | "properties")}
      />
      {getView()}
      {/* {showEditDrawer && (
        
      )} */}
      <Drawer
        open={showEditDrawer}
        onClose={() => {
          setShowEditDrawer(false);
          setTimeout(() => {
            // Reset this form and close self
            form.resetFields();
            setPreExistingUser(null);
            setView("details");
            setRequirementsOptionCounterMap({});
            setOptionalRequirementOthers("");
          }, 1500);
        }}
        title="Edit Client"
      >
        <EditClientForm
          client={{
            ...preExistingUser,
            roles: preExistingUser?.roles?.id,
          }}
          fetchBasicDetails={fetchClients}
          onClientEditDrawerClose={() => {
            setShowEditDrawer(false);
            setTimeout(() => {
              // Reset this form and close self
              form.resetFields();
              setPreExistingUser(null);
              setView("details");
              setRequirementsOptionCounterMap({});
              setOptionalRequirementOthers("");
            }, 1500);
          }}
        />
      </Drawer>
    </>
    // <Form
    //   form={form}
    //   name="validateOnly"
    //   layout="vertical"
    //   autoComplete="off"
    //   onFinish={onFinish}
    // >
    //   <Form.Item
    //     name="contact"
    //     label="Phone"
    //     rules={[
    //       {
    //         required: true,
    //         pattern: /^[1-9]\d{9}$/gi,
    //         message: "Enter a valid phone number",
    //       },
    //     ]}
    //   >
    //     <Input type="number" maxLength={10} />
    //   </Form.Item>

    //   <Form.List name="alternate_contact">
    //     {(fields, { add, remove }, { errors }) => (
    //       <>
    //         {fields.map((field, index) => (
    //           <Form.Item
    //             label={index === 0 ? "Alternate phone" : ""}
    //             required={false}
    //             key={field.key}
    //           >
    //             <Form.Item
    //               {...field}
    //               validateTrigger={["onChange", "onBlur"]}
    //               rules={[
    //                 {
    //                   pattern: /^[1-9]\d{9}$/gi,
    //                   message: "Enter a valid phone number",
    //                 },
    //               ]}
    //               noStyle
    //             >
    //               <Input placeholder="Phone" style={{ width: "80%" }} />
    //             </Form.Item>
    //             {fields.length > 0 ? (
    //               <MinusCircleOutlined
    //                 className="dynamic-delete-button mx-2"
    //                 onClick={() => remove(field.name)}
    //               />
    //             ) : null}
    //           </Form.Item>
    //         ))}
    //         <Form.Item>
    //           <Button
    //             type="dashed"
    //             onClick={() => add()}
    //             style={{ width: "60%" }}
    //             icon={<PlusOutlined />}
    //           >
    //             Add alternate phone
    //           </Button>
    //           <Form.ErrorList errors={errors} />
    //         </Form.Item>
    //       </>
    //     )}
    //   </Form.List>
    //   <Form.Item
    //     rules={[
    //       {
    //         required: true,
    //         message: "Enter a valid salutation",
    //       },
    //     ]}
    //     name="salutation"
    //     label="Salutation"
    //   >
    //     <Select placeholder="Select salutation">
    //       <Option value="Mr">Mr</Option>
    //       <Option value="Mrs">Ms</Option>
    //       <Option value="Mx">Mx</Option>
    //     </Select>
    //   </Form.Item>
    //   <Form.Item
    //     rules={[
    //       {
    //         required: true,
    //         message: "Enter a valid name",
    //       },
    //       {
    //         message: "No spaces allowed",
    //         pattern: /^[a-zA-Z]*$/gi,
    //       },
    //     ]}
    //     name="f_name"
    //     label="First Name"
    //   >
    //     <Input />
    //   </Form.Item>
    //   <Form.Item name="l_name" label="Last Name">
    //     <Input />
    //   </Form.Item>
    //   <Form.Item
    //     name="email"
    //     label="Email"
    //     rules={[
    //       {
    //         type: "email",
    //         message: "Enter a valid email",
    //       },
    //     ]}
    //   >
    //     <Input />
    //   </Form.Item>
    //   <Form.List name="alternate_email">
    //     {(fields, { add, remove }, { errors }) => (
    //       <>
    //         {fields.map((field, index) => (
    //           <Form.Item
    //             label={index === 0 ? "Alternate email" : ""}
    //             required={false}
    //             key={field.key}
    //             rules={[
    //               {
    //                 type: "email",
    //                 message: "Enter a valid email",
    //               },
    //             ]}
    //           >
    //             <Form.Item
    //               {...field}
    //               validateTrigger={["onChange", "onBlur"]}
    //               noStyle
    //             >
    //               <Input placeholder="Email" style={{ width: "80%" }} />
    //             </Form.Item>
    //             {fields.length > 0 ? (
    //               <MinusCircleOutlined
    //                 className="dynamic-delete-button mx-2"
    //                 onClick={() => remove(field.name)}
    //               />
    //             ) : null}
    //           </Form.Item>
    //         ))}
    //         <Form.Item>
    //           <Button
    //             type="dashed"
    //             onClick={() => add()}
    //             style={{ width: "60%" }}
    //             icon={<PlusOutlined />}
    //           >
    //             Add alternate email
    //           </Button>
    //           <Form.ErrorList errors={errors} />
    //         </Form.Item>
    //       </>
    //     )}
    //   </Form.List>
    //   <Form.Item
    //     label="User Type"
    //     name={"roles"}
    //     rules={[
    //       {
    //         required: true,
    //         message: "Enter a valid user type",
    //       },
    //     ]}
    //   >
    //     <Select
    //       placeholder="Select user type"
    //       onChange={(val) => {
    //         if (val === 8) {
    //           setIsStudio(true);
    //         } else {
    //           setIsStudio(false);
    //         }
    //       }}
    //     >
    //       <Option value={10}>Public</Option>
    //       <Option value={8}>Studio</Option>
    //     </Select>
    //   </Form.Item>
    //   {
    //     <>
    //       <Form.Item
    //         label="GST Number"
    //         name="gst_number"
    //         rules={[
    //           {
    //             required: isStudio,
    //             message: "Enter a valid gst number",
    //           },
    //           {
    //             pattern:
    //               /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g,
    //             message: "Enter a valid GST Number",
    //           },
    //         ]}
    //       >
    //         <Input />
    //       </Form.Item>
    //       {/* <Form.Item label="COA Reg. Number" name="coa_reg_no">
    //         <Input />
    //       </Form.Item> */}
    //       <Form.Item label="Company name" name="company_name">
    //         <Input required={isStudio} />
    //       </Form.Item>
    //       <Form.Item
    //         required={isStudio}
    //         label="Whatsapp group link"
    //         name="whatsapp_group_link"
    //         rules={[
    //           {
    //             type: "url",
    //             message: "Enter a valid url",
    //           },
    //         ]}
    //       >
    //         <Input />
    //       </Form.Item>
    //     </>
    //   }

    //   <Form.Item
    //     label="Source"
    //     rules={[
    //       {
    //         required: true,
    //         message: "Enter user source",
    //       },
    //     ]}
    //     name={"channel"}
    //   >
    //     <Select placeholder="Select user source">
    //       <Option value="whatsapp">Whatsapp/DMs</Option>
    //       <Option value="call">Call</Option>
    //       <Option value="store_walk_in">Store Walk In</Option>
    //       <Option value="email">Email</Option>
    //       <Option value="website_order">Website Orders</Option>
    //     </Select>
    //   </Form.Item>
    //   <Form.Item
    //     rules={[
    //       {
    //         required: true,
    //         message: "Enter user source",
    //       },
    //     ]}
    //     label="Medium"
    //     name={"medium"}
    //   >
    //     <Select placeholder="Select user medium">
    //       <Option value="Fbig">Fbig</Option>
    //       <Option value="SEO">SEO</Option>
    //       <Option value="SEM">SEM</Option>
    //       <Option value="SMM">SMM</Option>
    //       <Option value="Referral">Referral</Option>
    //       <Option value="Offline">Offline</Option>
    //       <Option value="Newsletter">Email Marketing/Newsletter</Option>
    //     </Select>
    //   </Form.Item>
    //   <Form.Item>
    //     <Space>
    //       <SubmitButton form={form} />
    //       <Button htmlType="reset">Reset</Button>
    //     </Space>
    //   </Form.Item>
    // </Form>
  );
};

export default AddClientForm;
