import { Card, Col, Row, Statistic } from "antd";
import React from "react";
import Chart from "react-apexcharts";
import { estimateDashboardData } from "../../apis/dasboad.api";

export const statusOptions = [
  { id: "1", value: "draft", label: "Draft" },
  {
    id: "2",
    value: "pricing_and_availability_check",
    label: "Pricing and Availability Check",
  },
  {
    id: "3",
    value: "availability_check",
    label: "Request Delivery Timeline",
  },
  {
    id: "14",
    value: "payment_pending",
    label: "Payment Pending",
  },
  {
    id: "4",
    value: "quote_approval_pending",
    label: "Quote Approval Pending",
  },
  {
    id: "5",
    value: "quotation_approved",
    label: "Quotation Approved - Initiate Delivery",
  },
  {
    id: "6",
    value: "order_lost",
    label: "Order Lost",
  },
  {
    id: "7",
    value: "vendor_po_raised",
    label: "Vendor Po Raised",
  },
  {
    id: "8",
    value: "dispatched",
    label: "Dispatched",
  },
  {
    id: "9",
    value: "delivered",
    label: "Delivered",
  },
  {
    id: "10",
    value: "refunded",
    label: "Refunded",
  },
  {
    id: "11",
    value: "cancelled",
    label: "Cancelled",
  },
  {
    id: "12",
    value: "partly_shipped",
    label: "Partly Shipped",
  },
  {
    id: "13",
    value: "shipped",
    label: "Shipped",
  },
  {
    id: "14",
    value: "partly_delivered",
    label: "Partly Delivered",
  },
];

// Blue monochromatic colors (9 shades)
const pieChartColors = [
  "#CAF0F8",
  "#ADE8F4",
  "#90E0EF",
  "#48CAE4",
  "#00B4D8",
  "#0096C7",
  "#0077B6",
  "#023E8A",
  "#03045E",
];

const EstimateDashboard = () => {
  const [data, setData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);

  const fetchData = React.useCallback(async () => {
    try {
      setLoading(true);
      setData(await estimateDashboardData());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const majorStatus = React.useMemo(() => {
    // Get the estimate status with maximum amount of 'all' parameter
    if (!data || !data.estimate_status) return null;

    let status: any = null;
    let max = 0;
    data?.estimate_status.forEach((item: any) => {
      if (item.all > max) {
        status = item;
        max = item.all;
      }
    });

    console.log("debug>>> status max", status, max);

    return status;
  }, [data]);

  const labelFromKey = React.useCallback((key: any) => {
    const option = statusOptions.find((option) => option.value === key);
    return option?.label ?? "";
  }, []);

  const chartLabels = React.useMemo(
    () =>
      data
        ? data.estimate_status.map(
            (status: any) => labelFromKey(status?.key) ?? ""
          )
        : [],
    [data, labelFromKey]
  );

  const chartOptions = React.useMemo(
    () => ({
      labels: chartLabels,
      colors: pieChartColors,
    }),
    [chartLabels]
  );

  const lastDayChartData = React.useMemo(() => {
    if (!data || !data.estimate_status)
      return {
        series: [],
        labels: [],
        options: {},
      };

    const series = data.estimate_status.map(
      (status: any) => status?.last_day ?? 0
    );

    return {
      series,
      labels: chartLabels,
      options: chartOptions,
    };
  }, [data, chartLabels, chartOptions]);

  const lastWeekChartData = React.useMemo(() => {
    if (!data || !data.estimate_status)
      return {
        series: [],
        labels: [],
        options: {},
      };

    const series = data.estimate_status.map(
      (status: any) => status?.last_7_day ?? 0
    );

    return {
      series,
      labels: chartLabels,
      options: chartOptions,
    };
  }, [chartLabels, chartOptions, data]);

  const lastMonthChartData = React.useMemo(() => {
    if (!data || !data.estimate_status)
      return {
        series: [],
        labels: [],
        options: {},
      };

    const series = data.estimate_status.map((status: any) => status?.all ?? 0);

    return {
      series,
      labels: chartLabels,
      options: chartOptions,
    };
  }, [chartLabels, chartOptions, data]);

  return (
    <main className="container my-4">
      <Row gutter={16} className="mb-3">
        <Col md={6}>
          <Card loading={loading}>
            <Statistic
              title="Estimates (last week)"
              value={data?.estimate_last_7_day_count}
            />
          </Card>
        </Col>
        <Col md={6}>
          <Card loading={loading}>
            <Statistic
              title="Estimates (last month)"
              value={data?.estimate_last_30_day_count}
            />
          </Card>
        </Col>
        <Col md={6}>
          <Card loading={loading}>
            <Statistic title="Estimate Count" value={data?.estimate_count} />
          </Card>
        </Col>
        <Col md={6}>
          <Card loading={loading}>
            <Statistic
              title="PO's raised (last week)"
              value={data?.po_last_7_day_count}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} className="mb-3">
        <Col md={6}>
          <Card loading={loading}>
            <Statistic
              title="PO's raised (last month)"
              value={data?.po_last_30_day_count}
            />
          </Card>
        </Col>
        <Col md={6}>
          <Card loading={loading}>
            <Statistic title="PO Count" value={data?.po_count} />
          </Card>
        </Col>
        <Col md={12}>
          <Card loading={loading}>
            <Statistic
              title="Majority Status"
              value={
                statusOptions.find(
                  (option) => majorStatus?.key === option.value
                )?.label
              }
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} className="mb-3">
        <Col md={12}>
          <Card loading={loading}>
            <Card.Meta title="Last Day Status" />
            <Chart
              options={lastDayChartData.options}
              series={lastDayChartData.series}
              type="pie"
              width="100%"
            />
          </Card>
        </Col>
        <Col md={12}>
          <Card loading={loading}>
            <Card.Meta title="Last Week Status" />
            <Chart
              options={lastWeekChartData.options}
              series={lastWeekChartData.series}
              type="pie"
              width="100%"
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} className="mb-3">
        <Col md={12} offset={6}>
          <Card loading={loading}>
            <Card.Meta title="Last Month Status" />
            <Chart
              options={lastMonthChartData.options}
              series={lastMonthChartData.series}
              type="pie"
              width="100%"
            />
          </Card>
        </Col>
      </Row>
    </main>
  );
};

export default EstimateDashboard;
