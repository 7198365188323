import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { Button } from "antd";
import { useCallback, useRef, useState } from "react";
import { CenterMap } from "./center-map";
import styles from "./index.module.scss";

export type MapAddressExport = {
  id: string;
  name?: string;
  address: string;
  pincode: string;
  lat: number;
  long: number;
  city?: string;
  state?: string;
  country?: string;
  google_map_address?: string;
};

export interface ProcurementMapPickerProps {
  pickedAddress?: MapAddressExport;
  onPick: (data: MapAddressExport) => void;
  openInModal?: boolean;
  defaultMapOpen?: boolean;
}

const GOOGLE_MAPS_API_KEY = "AIzaSyBLtE-GIJXxkxbfwMatgEqr8ZKyDHUBcN8";

export function ProcurementMapPicker({
  pickedAddress,
  onPick,
  openInModal,
}: ProcurementMapPickerProps) {
  const [showFullScreen, setShowFullScreen] = useState(true);
  const [centerLat, setCenterLat] = useState(12.950771);
  const [centerLng, setCenterLng] = useState(77.584236);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [previewLatLong, setPreviewLatLong] = useState<[number, number] | null>(
    null
  );
  const [unserviceablePincode, setUnserviceablePincode] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchTimeout, setSearchTimeout] = useState<any>(null);
  const [selectedAddress, setSelectedAddress] =
    useState<MapAddressExport | null>(null);
  const [finalAddress, setFinalAddress] = useState<MapAddressExport | null>(
    null
  );
  const [searchInputFocused, setSearchInputFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finalisingAddress, setFinalisingAddress] = useState(false);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  // useEffect(() => {
  //   if (
  //     showFullScreen &&
  //     window.innerWidth < 576 &&
  //     pathname.includes("/user/address/new")
  //   ) {
  //     document.body.style.overflow = "hidden";
  //   }

  //   return () => {
  //     document.body.style.overflow = "";
  //   };
  // }, [showFullScreen, pathname]);

  const onLatLongChange = useCallback((text: string) => {
    clearTimeout(debounceTimeout.current!);
    clearTimeout(searchTimeout);
    clearTimeout(debounceTimeout.current!);

    // Clear the selected and final address
    setSelectedAddress(null);
    setFinalAddress(null);
    setFinalisingAddress(false);

    const [lat, lng] = text.split(",");
    const center = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
    debounceTimeout.current = setTimeout(() => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: center,
          // @ts-ignore
          // extraComputations: ["ADDRESS_DESCRIPTORS"],
        },
        (results, status) => {
          if (status === "OK") {
            setLoading(false);
            setFinalisingAddress(true);

            if (results?.[0]) {
              const address = results[0];

              const placeId = address.place_id;
              // Get place details with this ID
              const map = new google.maps.Map(document.createElement("div"));
              map.setCenter(center);
              const service = new google.maps.places.PlacesService(map);

              service.getDetails({ placeId }, (place, status) => {
                if (status === "OK" && place) {
                  const addr = {
                    ...finalAddress,
                    name: place?.name ?? "",
                    address: address?.formatted_address ?? "",
                    lat: center?.lat() || 0,
                    long: center?.lng() || 0,
                    city: address?.address_components?.find(
                      (component: any) => {
                        return component.types.includes("locality");
                      }
                    )?.long_name,
                    state: address?.address_components?.find(
                      (component: any) => {
                        return component.types.includes(
                          "administrative_area_level_1"
                        );
                      }
                    )?.long_name,
                    country: address?.address_components?.find(
                      (component: any) => {
                        return component.types.includes("country");
                      }
                    )?.long_name,
                    google_map_address: `https://google.com/maps?q=${center.lat()},${center.lng()}`,
                    id: placeId,
                    pincode:
                      address?.address_components?.find((component: any) => {
                        return component.types.includes("postal_code");
                      })?.short_name ?? "",
                  };
                  setFinalAddress(addr as any);
                  setSelectedAddress(addr as any);
                  setFinalisingAddress(false);
                }
              });
            } else {
              console.log("No results found");
            }
          } else {
            console.log("Geocoder failed due to: " + status);
          }
        }
      );
    }, 1000);
  }, []);

  const getResults = useCallback(async (query: string) => {
    setSearchQuery(query);
    setUnserviceablePincode(false);

    // Fetch results from API
    const response = await fetch(
      `https://places.googleapis.com/v1/places:searchText`,
      {
        method: "POST",
        body: JSON.stringify({
          textQuery: query,
          locationBias: {
            circle: {
              center: {
                latitude: 12.950771,
                longitude: 77.584236,
              },
              radius: 50000,
            },
          },
        }),
        headers: {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": GOOGLE_MAPS_API_KEY,
          "X-Goog-FieldMask":
            "places.id,places.googleMapsLinks,places.displayName,places.formattedAddress,places.location,places.plusCode,places.addressComponents",
        },
      }
    );

    const data = await response.json();

    setSearchResults(data?.places ?? []);
    setLoading(false);
  }, []);

  const checkPincodeZone = useCallback(async (pincode: string | number) => {
    if (!pincode || pincode === "undefined") return true;

    // const pincodeZone = await getZoneFromPincode(pincode);
    // if (!pincodeZone || pincodeZone?.zone !== "blr") {
    //   setUnserviceablePincode(true);
    //   return false;
    // } else {
    setUnserviceablePincode(false);
    return true;
    // }
  }, []);

  if (!showFullScreen) {
    if (finalAddress) {
      return (
        <div>
          <GoogleMap
            center={{
              lat: finalAddress?.lat,
              lng: finalAddress?.long,
            }}
            options={{
              zoom: 19,
              zoomControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              scaleControl: false,
              rotateControl: false,
              panControl: false,
              gestureHandling: "none",
              scrollwheel: false,
              draggable: false,
              clickableIcons: false,
            }}
            clickableIcons={false}
            mapContainerClassName="map-container"
            mapContainerStyle={{
              height: 90,
              width: "100%",
              transition: "height 0.3s",
              margin: "0.5rem 0",
            }}
          >
            <MarkerF
              position={{
                lat: finalAddress?.lat,
                lng: finalAddress?.long,
              }}
            />
          </GoogleMap>
          <div className="d-flex align-items-center justify-content-center mb-2">
            <div
              className="blue-color-text blue-color-text th-fontSize-12"
              role="button"
              onClick={() => {
                setShowFullScreen(true);
                setFinalisingAddress(false);
              }}
            >
              Change location
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        className="d-flex align-items-center justify-content-center w-100 border rounded my-3"
        style={{ height: 70 }}
        role="button"
        onClick={() => setShowFullScreen(true)}
      >
        <span className="th-fontSize-14 th-fontWeight-400 d-flex align-items-center text-decoration-underline">
          {/* <BsGeoAlt className="me-2" /> */}
          Pick location
        </span>
      </div>
    );
  }

  return (
    <div
      className={
        !openInModal
          ? "d-flex w-100 h-100 bg-white fixed-top top-0 left-0 flex-column"
          : `d-flex w-100 h-100 fixed-top bg-black`
      }
      style={
        openInModal
          ? ({
              "--bs-bg-opacity": 0.5,
              alignItems: "center",
              justifyContent: "center",
            } as React.CSSProperties)
          : {}
      }
    >
      <div
        className={`d-flex flex-column ${
          !openInModal ? "w-100 h-100" : "p-0 p-md-3"
        } ${styles.modal}`}
      >
        <div className="p-3 p-md-1 mb-md-3 d-flex align-items-center gap-2">
          <div
            className="d-flex align-items-center gap-1"
            onClick={() => {
              setShowFullScreen(false);
            }}
          >
            {/* <BsX size={21} /> */}
            <span style={{ fontSize: 24 }}>&times;</span>
          </div>
          <input
            type="search"
            placeholder="Search for location"
            className="form-control"
            style={{ border: "none", outline: "none", boxShadow: "none" }}
            name="search"
            value={inputValue}
            onChange={(e) => {
              clearTimeout(searchTimeout);
              setFinalAddress(null);
              setSelectedAddress(null);
              setSearchResults([]);
              setLoading(true);
              setInputValue(e.target.value);
              const latLongRegexCheck =
                /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/;
              if (latLongRegexCheck.test(e.target.value)) {
                onLatLongChange(e.target.value);
              } else {
                setSearchTimeout(
                  setTimeout(() => {
                    getResults(e.target.value);
                  }, 500)
                );
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
                e.preventDefault();

                const value = (e.target as HTMLInputElement).value;
                setFinalAddress(null);
                setSelectedAddress(null);
                getResults(value);
              }
            }}
            onFocus={() => setSearchInputFocused(true)}
            onBlur={() => {
              setSearchInputFocused(false);
              // document.body.style.overflow = "hidden";
            }}
          />
          <div
            className="mx-2"
            onClick={() => {
              setFinalAddress(null);
              setSelectedAddress(null);
              getResults(inputValue);
            }}
          >
            Search
          </div>
        </div>
        {
          <>
            {/* {selectedAddress && (
              <div
                className="bg-body-secondary px-2 py-2 th-fontSize-14 blue-color-text text-end"
                onClick={() => {
                  setPreviewLatLong(null);
                  setSelectedAddress(null);
                  setFinalAddress(null);
                  setFinalisingAddress(false);
                }}
                role="button"
              >
                Cancel
              </div>
            )} */}
            {/* <GoogleMap
              zoom={12}
              center={{
                lat: centerLat,
                lng: centerLng,
              }}
              options={{
                // maxZoom: 12,
                minZoom: 14,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                zoomControl: false,
              }}
              mapContainerClassName="map-container"
              mapContainerStyle={{
                height: openInModal
                  ? "30vh"
                  : searchInputFocused
                  ? "30vh"
                  : "50vh",
                width: "100%",
                minHeight: openInModal
                  ? "30vh"
                  : searchInputFocused
                  ? "30vh"
                  : "50vh",
                transition: "height 0.3s",
              }}
            >
              {previewLatLong && (
                <MarkerF
                  position={{
                    lat: previewLatLong[0],
                    lng: previewLatLong[1],
                  }}
                />
              )}
            </GoogleMap> */}
            <CenterMap
              initCenter={{
                lat: 12.950771,
                lng: 77.584236,
              }}
              containerStyle={{
                height: openInModal
                  ? finalAddress
                    ? "70vh"
                    : "30vh"
                  : searchInputFocused
                  ? "30vh"
                  : finalAddress
                  ? "80vh"
                  : "50vh",
                width: "100%",
                minHeight: openInModal
                  ? finalAddress
                    ? "70vh"
                    : "30vh"
                  : searchInputFocused
                  ? "30vh"
                  : finalAddress
                  ? "80vh"
                  : "50vh",
                transition: "height 0.3s",
              }}
              finalAddress={finalAddress ?? undefined}
              selectedAddress={selectedAddress ?? undefined}
              setFinalAddress={(addr) => setFinalAddress(addr)}
              finalising={finalisingAddress}
              setFinalising={(val) => setFinalisingAddress(val)}
            />
          </>
        }
        <div
          className="flex-grow-1 overflow-auto"
          onScroll={(e) => {
            e.stopPropagation();

            setSearchInputFocused(true);
          }}
        >
          {searchResults.length === 0 &&
            inputValue.length === 0 &&
            !loading && (
              <div className="text-muted th-fontSize-14 my-3 mx-3 mx-md-0">
                Start searching to get location suggestions...
              </div>
            )}
          {searchResults.length === 0 && inputValue.length > 0 && !loading && (
            <div className="alert alert-sm alert-warning th-fontSize-14 my-3 mx-3 mx-md-0">
              No results found for your search
            </div>
          )}
          {loading && (
            <div className="placeholder-glow px-3 px-md-0">
              {new Array(5).fill(0).map((_, index) => (
                <div
                  className="placeholder w-100 mt-3 rounded"
                  style={{ height: 70 }}
                  key={index}
                />
              ))}
            </div>
          )}
          {unserviceablePincode && (
            <div className="text-danger alert-warning my-3 th-fontSize-12 text-center">
              This location is outside our serviceable area
            </div>
          )}
          <div className="p-3 p-md-0 mt-md-3">
            {searchResults.length > 0 &&
              !finalAddress &&
              searchResults.map((result) => (
                <div
                  className={`d-flex align-items-center rounded p-2 border gap-2 mb-3 ${
                    previewLatLong &&
                    previewLatLong[0] === result?.location?.latitude &&
                    previewLatLong[1] === result?.location?.longitude
                      ? "bg-body-secondary"
                      : ""
                  }`}
                  role="button"
                  key={result.place_id}
                  onClick={async () => {
                    setFinalisingAddress(false);
                    setSearchInputFocused(false);
                    setPreviewLatLong([
                      result?.location?.latitude,
                      result?.location?.longitude,
                    ]);
                    setCenterLat(result?.location?.latitude);
                    setCenterLng(result?.location?.longitude);

                    if (
                      !(await checkPincodeZone(
                        result?.addressComponents?.find((component: any) => {
                          return component.types.includes("postal_code");
                        })?.shortText
                      ))
                    ) {
                      return;
                    }

                    const address = {
                      id: result?.id,
                      label: result?.displayName?.text,
                      address: result?.formattedAddress,
                      pincode: result?.addressComponents?.find(
                        (component: any) => {
                          return component.types.includes("postal_code");
                        }
                      )?.shortText,
                      city: result?.addressComponents?.find(
                        (component: any) => {
                          return component.types.includes("locality");
                        }
                      )?.shortText,
                      state: result?.addressComponents?.find(
                        (component: any) => {
                          return component.types.includes(
                            "administrative_area_level_1"
                          );
                        }
                      )?.longText,
                      country: result?.addressComponents?.find(
                        (component: any) => {
                          return component.types.includes("country");
                        }
                      )?.longText,
                      lat: result?.location?.latitude,
                      long: result?.location?.longitude,
                      google_map_address: `https://google.com/maps?q=${result?.location?.latitude},${result?.location?.longitude}`,
                    };

                    setSelectedAddress(address);
                    setFinalAddress(address);
                  }}
                >
                  <div>
                    <div className="th-fontSize-16 th-fontWeight-500">
                      {result?.displayName?.text}
                    </div>
                    <div className="th-fontSize-10">
                      {result?.formattedAddress}
                    </div>
                  </div>
                  {/* <button
                  className="btn btn-primary btn-sm ms-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    onPick({
                      label: result?.displayName?.text,
                      address: result?.formattedAddress,
                      pincode: result?.addressComponents?.find(
                        (component: any) => {
                          return component.types.includes("postal_code");
                        }
                      )?.shortText,
                      city: result?.addressComponents?.find(
                        (component: any) => {
                          return component.types.includes("locality");
                        }
                      )?.shortText,
                      state: result?.addressComponents?.find(
                        (component: any) => {
                          return component.types.includes(
                            "administrative_area_level_1"
                          );
                        }
                      )?.longText,
                      country: result?.addressComponents?.find(
                        (component: any) => {
                          return component.types.includes("country");
                        }
                      )?.longText,
                      latitude: result?.location?.latitude,
                      longitude: result?.location?.longitude,
                    });

                    setShowFullScreen(false);
                  }}
                >
                  Pick
                </button> */}
                </div>
              ))}

            {/* {finalAddress && (
              <>
                <div className="th-fontSize-14 th-fontWeight-500 mb-1">
                  Selected location
                </div>
                <div
                  className={`d-flex align-items-center rounded p-2 border gap-2 mb-3`}
                >
                  <div>
                    
                  </div>
                </div>
              </>
            )} */}
            {finalAddress && (
              <div
                className={`rounded-top bg-white p-3 fixed-bottom bottom-0 ${styles.bottomSelect}`}
              >
                <div className="th-fontSize-14 th-fontWeight-500 text-muted d-flex align-items-center mb-1 justify-content-between">
                  <span>Selected location</span>
                  {/* <BsX
                    onClick={() => {
                      setFinalAddress(null);
                      setSelectedAddress(null);
                      setFinalisingAddress(false);
                    }}
                    fontSize={28}
                  /> */}
                  <span
                    onClick={() => {
                      setFinalAddress(null);
                      setSelectedAddress(null);
                      setFinalisingAddress(false);
                    }}
                  >
                    &times;
                  </span>
                </div>
                <div
                  className="mb-1 d-flex align-items-start"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  {/* <BsGeoAltFill
                    className="me-2 mt-1 flex-shrink-0"
                    fontSize={20}
                  /> */}
                  {!finalisingAddress ? (
                    <span className="flex-grow-1">{finalAddress?.name}</span>
                  ) : (
                    <div
                      className="placeholder-glow rounded flex-grow-1"
                      style={{ height: 20, width: 100 }}
                    >
                      <div className="placeholder w-100 h-100" />
                    </div>
                  )}
                </div>
                <div className="th-fontSize-12 mb-2">
                  {!finalisingAddress ? (
                    finalAddress?.address
                  ) : (
                    <div
                      className="placeholder-glow rounded"
                      style={{ height: 20, width: 200 }}
                    >
                      <div className="placeholder w-100 h-100" />
                    </div>
                  )}
                </div>
                <Button
                  // className={`text-black btn w-100 ${
                  //   unserviceablePincode ? "" : "primary-color-bg"
                  // }`}
                  type="primary"
                  className="w-100"
                  onClick={async () => {
                    if (unserviceablePincode) return;

                    onPick(finalAddress);
                    setShowFullScreen(false);
                  }}
                  disabled={unserviceablePincode}
                >
                  Add more details
                </Button>
              </div>
            )}
            <div style={{ height: 70 }} />
          </div>
        </div>
      </div>
    </div>
  );
}
