import {
  CheckOutlined,
  FilterOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Input, message, Pagination } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { clientBMCartsV2 } from "../../../apis/client.api";
import { fetchUserDetails } from "../../../apis/user.api";

export function MobileBMCarts() {
  const [carts, setCarts] = useState([]);
  const [totalClients, setTotalClients] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [selectedFilter, setSelectedFilter] = useState<
    "all" | "pending" | "done"
  >("all");

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [ordering, setOrdering] = useState<
    "-lead_time_updated_at" | "-cart_value"
  >("-lead_time_updated_at");

  useEffect(() => {
    fetchUserDetails().then((res) => {
      setCurrentUser(res);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    const bmCartParams = {
      page,
      search,
      ordering,
    } as any;
    if (selectedFilter !== "all") {
      bmCartParams.all_lead_time_filled = selectedFilter === "done";
    }

    clientBMCartsV2(bmCartParams).then((res) => {
      setCarts(res?.results ?? []);
      setTotalClients(res?.count ?? 0);
      setLoading(false);
    });
  }, [page, search, pageSize, selectedFilter, ordering]);

  const downloadPDF = async (userId: string) => {
    message.loading("Downloading PDF...");

    const pdfResponse = await axios.get(
      "https://api-dev2.materialdepot.in/apiV1/cart-pdf/",
      {
        params: {
          user_id: userId,
        },
      }
    );
    const fileName = `${userId}_cart.pdf`;

    const url = window.URL.createObjectURL(new Blob([pdfResponse.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-3">
      <div className="text-center">
        <h3 className="fw-bold mb-3">Carts</h3>
      </div>
      <div className="mb-3">
        <div className="d-flex align-items-center gap-2 w-100">
          <Input.Search
            onSearch={(value) => {
              setPage(1);
              setSearch(value);
            }}
            allowClear
            placeholder="Search with Client Name or Phone No..."
            size="large"
            className="rounded-pill"
            style={{ flexGrow: 1 }}
          />
          <Dropdown
            menu={{
              items: [
                {
                  key: "all",
                  label: (
                    <div className="d-flex align-items-center gap-2">
                      {selectedFilter === "all" && <CheckOutlined />}
                      <span>All</span>
                    </div>
                  ),
                  onClick: () => {
                    setPage(1);
                    setSelectedFilter("all");
                  },
                },
                {
                  key: "pending",
                  label: (
                    <div className="d-flex align-items-center gap-2">
                      {selectedFilter === "pending" && <CheckOutlined />}
                      <span>Pending</span>
                    </div>
                  ),
                  onClick: () => {
                    setPage(1);
                    setSelectedFilter("pending");
                  },
                },
                {
                  key: "done",
                  label: (
                    <div className="d-flex align-items-center gap-2">
                      {selectedFilter === "done" && <CheckOutlined />}
                      <span>Completed</span>
                    </div>
                  ),
                  onClick: () => {
                    setPage(1);
                    setSelectedFilter("done");
                  },
                },
              ],
            }}
          >
            <Button
              size="large"
              icon={<FilterOutlined className="" />}
              // className="d-none"
            >
              {/* Filter */}
            </Button>
          </Dropdown>
          <Dropdown
            menu={{
              items: [
                {
                  key: "-lead_time_updated_at",
                  label: (
                    <div className="d-flex align-items-center gap-2">
                      {ordering === "-lead_time_updated_at" && (
                        <CheckOutlined />
                      )}
                      <span>Latest Updated</span>
                    </div>
                  ),
                  onClick: () => {
                    setPage(1);
                    setOrdering("-lead_time_updated_at");
                  },
                },
                {
                  key: "-cart_value",
                  label: (
                    <div className="d-flex align-items-center gap-2">
                      {ordering === "-cart_value" && <CheckOutlined />}
                      <span>Cart Value</span>
                    </div>
                  ),
                  onClick: () => {
                    setPage(1);
                    setOrdering("-cart_value");
                  },
                },
              ],
            }}
          >
            <Button icon={<SortAscendingOutlined />} size="large">
              {/* Sort */}
            </Button>
          </Dropdown>
        </div>
      </div>
      {loading && (
        <div className="text-center alert alert-info">Loading...</div>
      )}
      {carts.length === 0 && !loading && (
        <div className="text-center alert alert-warning">No Carts Found</div>
      )}
      <div>
        {carts.map((userCart: any) => (
          <div key={userCart?.user_id} className="rounded p-3 mb-3 shadow-sm">
            <h5>{userCart?.user_name}</h5>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: ".5rem 0",
              }}
            >
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Cart Number
                </div>
                {userCart.cart_number}
              </div>
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Cart Value
                </div>
                Rs. {Number(userCart?.cart_value ?? 0)?.toFixed(2)}
              </div>
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Total Items
                </div>
                {userCart?.total_items ?? 0}
              </div>
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  BM
                </div>
                {currentUser?.f_name}
              </div>
              {/* <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Total Products
                </div>
                {userCart?.total_products ?? 0}
              </div> */}
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Last Updated
                </div>
                {dayjs(userCart?.last_modified).format("DD/MM/YYYY")}
              </div>
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Delivery Timeline
                </div>
                {userCart?.all_lead_time_filled
                  ? "Check Complete"
                  : "Check Pending"}
              </div>
            </div>
            <div className="mt-3 d-flex align-items-center gap-2">
              <Link to={`/carts/${userCart?.user_id}`}>
                <Button size="small" type="default">
                  View Details
                </Button>
              </Link>
              <Button
                size="small"
                type="default"
                onClick={() => downloadPDF(userCart?.user_id)}
              >
                Download PDF
              </Button>
              <a
                href={`https://materialdepot.in/auto-login-v2?use_procurement_user_login=${userCart?.user_id}&use_procurement_user_contact=${userCart?.user_contact}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button size="small" type="primary">
                  Edit Cart
                </Button>
              </a>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <Pagination
          current={page}
          pageSize={pageSize}
          total={totalClients}
          onChange={(page) => setPage(page)}
          onShowSizeChange={(current, size) => setPageSize(size)}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
}
