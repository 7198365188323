import { CheckOutlined, FilterOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  message,
  Pagination,
  Space,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  checkAndConfirmOrderPayment,
  fetchProFormaInvoices,
} from "../../../apis/pro_forma_invoice.api";
import { fetchUserDetails } from "../../../apis/user.api";

export const statusOptions = [
  { id: "1", value: "draft", label: "Draft", disabled: true },
  {
    id: "2",
    value: "availability_check",
    label: "Request Delivery Timeline",
    disabled: true,
  },
  {
    id: "14",
    value: "payment_pending",
    label: "Payment Pending",
  },
  {
    id: "3",
    value: "quote_approval_pending",
    label: "Quote Approval pending",
  },
  {
    id: "4",
    value: "order_placed",
    label: "Order Placed",
  },
  {
    id: "5",
    value: "order_lost",
    label: "Order lost",
  },
  {
    id: "12",
    value: "order_cancelled",
    label: "Order Cancelled",
  },
  {
    id: "6",
    value: "order_confirmed",
    label: "Order confirmed",
    disabled: true,
  },
  {
    id: "7",
    value: "partly_shipped",
    label: "Partly shipped",
    disabled: true,
  },
  {
    id: "8",
    value: "shipped",
    label: "Shipped",
    disabled: true,
  },
  {
    id: "9",
    value: "partly_delivered",
    label: "Partly delivered",
    disabled: true,
  },
  {
    id: "10",
    value: "delivered",
    label: "Delivered",
    disabled: true,
  },
  {
    id: "11",
    value: "refunded",
    label: "Refunded",
  },
];

export function MobileOrders() {
  const [estimates, setEstimates] = useState<any[]>([]);
  const [currentUser, setCurrentUser] = useState<any>();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<string[]>([
    // "quote_approval_pending",
    // "availability_check",
    "order_placed",
    "order_confirmed",
    "partly_shipped",
    "shipped",
    "delivered",
    "partly_delivered",
  ]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showExtraFilters, setShowExtraFilters] = useState(false);
  const [filter, setFilter] = useState<any>({});

  useEffect(() => {
    (async () => {
      const user = await fetchUserDetails();
      setCurrentUser(user);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!currentUser) return;

      setLoading(true);
      const estimates = await fetchProFormaInvoices({
        created_by: currentUser?.id,
        page,
        search,
        estimate_status__in: status.join(","),
        ...filter,
      });

      setEstimates(estimates?.results ?? []);
      setTotal(estimates?.count ?? 0);

      setLoading(false);
    })();
  }, [currentUser, page, search, status, filter]);

  const checkPayment = useCallback(async (estimateId: string) => {
    message.loading({
      content: "Confirming Payment Status...",
      key: "payment-check",
    });
    const response = await checkAndConfirmOrderPayment(estimateId);
    if (response) {
      message.success({
        content: "Payment confirmed, order is placed.",
        key: "payment-check",
      });
    }
  }, []);

  return (
    <div className="p-3">
      <h3 className="fw-bold mb-3 text-center">Orders</h3>
      <div className="mb-4 d-flex gap-2">
        <Input.Search
          placeholder="Search"
          onSearch={setSearch}
          allowClear
          size="large"
        />
        <Dropdown
          menu={{
            items: statusOptions.map((s) => ({
              key: s.value,
              label: (
                <div className="d-flex align-items-center gap-2">
                  {status.includes(s.value) && <CheckOutlined />}
                  <span>{s.label}</span>
                </div>
              ),
              onClick: () => {
                setStatus((status) => {
                  if (status.includes(s.value)) {
                    return status.filter((st) => st !== s.value);
                  } else {
                    return [...status, s.value];
                  }
                });
              },
            })),
          }}
          placement="bottomRight"
          trigger={["click"]}
        >
          <Button size="large" icon={<FilterOutlined className="" />} />
        </Dropdown>
        <Button
          size="large"
          icon={<MoreOutlined />}
          onClick={() => {
            setShowExtraFilters((show) => !show);
          }}
        />
      </div>
      {showExtraFilters && (
        <div>
          <DatePicker.RangePicker
            style={{ width: "95%" }}
            onChange={(event) => {
              if (event) {
                setFilter({
                  ...filter,
                  created_at__lte: event[1]
                    ?.add(1, "day")
                    ?.format("YYYY-MM-DD"),
                  created_at__gte: event[0]?.format("YYYY-MM-DD"),
                });
              } else {
                setFilter({
                  ...filter,
                  created_at__gte: undefined,
                  created_at__lte: undefined,
                });
              }
            }}
          />
          <div className="th-font-size-14 px-1">Choose Date</div>
        </div>
      )}
      {loading && (
        <div className="text-center alert alert-info">Loading...</div>
      )}
      {estimates.length === 0 && !loading && (
        <div className="text-center alert alert-info">No Orders Found</div>
      )}
      {estimates.map((estimate) => (
        <div key={estimate?.id} className="rounded shadow-sm p-3 mb-4">
          <h5 className="fw-bold">{estimate?.lead_id}</h5>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: ".5rem 0",
            }}
          >
            <div>
              <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
                Created By
              </div>
              {estimate?.created_by}
            </div>
            <div>
              <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
                Order Value
              </div>
              Rs. {estimate?.total_estimate_amount}
            </div>
            <div style={{ gridColumn: "span 2" }}>
              <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
                Status
              </div>
              {
                statusOptions.find((s) => s.value === estimate?.estimate_status)
                  ?.label
              }
            </div>
          </div>
          <div className="mt-2">
            <Space>
              <Link to={`/orders/${estimate?.id}`}>
                <Button type="primary" size="small">
                  View
                </Button>
              </Link>
              <Button
                size="small"
                type="default"
                onClick={() => {
                  checkPayment(estimate?.id);
                }}
              >
                Confirm Payment
              </Button>
              <Button
                size="small"
                type="default"
                onClick={() => {
                  window.open(
                    "https://api-dev2.materialdepot.in/apiV1/estimate-pdf/" +
                      estimate?.id +
                      "/?image=true",
                    "_blank"
                  );
                }}
              >
                Download PDF
              </Button>
            </Space>
          </div>
        </div>
      ))}
      <div className="d-flex justify-content-center">
        <Pagination
          current={page}
          total={total}
          pageSize={10}
          onChange={setPage}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
}
