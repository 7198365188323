import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  MenuProps,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { Header } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { fetchBranches } from "../../../apis/branch.api";
import {
  addClientAddress,
  fetchClientAddresses,
  fetchClientDetails,
} from "../../../apis/client.api";
import { editPurchaseOrder, getPurchaseOrders } from "../../../apis/po.api";
import {
  createOrderFromPI,
  editPI,
  editProFormaInvoiceItem,
  fetchEstimateLogsByLeadId,
  fetchPIfromId,
  getEstimateTransactions,
  getPFIInvoices,
  refundOrder,
  removeInvoiceFromPI,
} from "../../../apis/pro_forma_invoice.api";
import { fetchUserDetails } from "../../../apis/user.api";
import { fetchVendors } from "../../../apis/vendor.api";
import AddClientAddressForm from "../../../components/client/add_client_address_form";
import EditClientAddressForm from "../../../components/client/edit_client_address_form";
import { HSNCodeFill } from "../../../components/po/hsn_code_fill";
import { OrderFeedback } from "../../../components/pro_forma_invoice/order_feedback";
import { PIEditAddress } from "../../../components/pro_forma_invoice/pi_edit_address";
import { PFICreateBillToAddress } from "../../../components/pro_forma_invoice/pro_forma_invoice_create/pfi_bill_to_address";
import { PFICreateShipToAddress } from "../../../components/pro_forma_invoice/pro_forma_invoice_create/pfi_ship_to_address";
import PFIBillingDetails from "../../../components/pro_forma_invoice/pro_forma_invoice_details/pfi_billing_details";
import PFIOrderDetails from "../../../components/pro_forma_invoice/pro_forma_invoice_details/pfi_order_details";
import ProFormaLogCard from "../../../components/pro_forma_invoice/pro_forma_log_card";
import TaxAmount from "../../../components/pro_forma_invoice/tax_amount";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";
import {
  getFormattedDate,
  getFormattedDateAndTime,
} from "../../../helpers/date_helper";
import { Client } from "../../../interfaces/client.interface";

export const status_type: Array<any> = [
  // {
  //   id: 1,
  //   label: "Order Raised",
  //   value: "order_raised",
  // },
  {
    id: 2,
    label: "Dispatch Pending",
    value: "dispatch_pending",
  },
  {
    id: 5,
    label: "In Warehouse",
    value: "in_store",
  },
  {
    id: 3,
    label: "Dispatched",
    value: "dispatched",
  },
  {
    id: 4,
    label: "Delivered",
    value: "delivered",
  },
];
const { Option } = Select;

const OrderDetails = () => {
  const { hasPermission } = usePermissions();

  const navigate = useNavigate();
  const { pi_id } = useParams();
  const [vendors, setVendors] = useState<Array<any>>([]);
  const [data, setData] = useState<any>({});
  const [other_vendor_select, setOtherVendorSelect] = useState(false);
  const [po_list, setPoList] = useState<Array<any>>([]);
  const [branch_data, setBranchData] = useState<any>({});
  const [po_vendors, setPOVendors] = useState<Array<any>>([]);
  const [client_data, setClientData] = useState<Client | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [invoices, setInvoices] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const [see_logs, setSeeLogs] = useState(false);
  const [logs, setLogs] = useState<Array<any>>([]);
  const [copy_clipboard_select, setCopyClipboardSelect] = useState(false);
  const [hsnMissingItems, setHsnMissingItems] = useState<any>([]);
  const [client_addresses, setClientAddresses] = useState<Array<any>>([]);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [showEditAddress, setShowEditAddress] = useState<
    "bill" | "ship" | false
  >(false);
  const [showFeedbackDrawer, setFeedbackDrawer] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [showEditPIEditAddress, setShowEditPIEditAddress] = useState<
    "shipping" | "billing" | null
  >(null);
  const [transactions, setTransactions] = useState<any[]>([]);

  const transactionColumns = [
    {
      title: "Transaction ID",
      key: "id",
      dataIndex: "payment_id",
    },
    {
      title: "Payment Method",
      key: "payment_method",
      dataIndex: "payment_method",
    },
    {
      title: "UUID",
      key: "uuid",
      dataIndex: "uuid",
    },
    {
      title: "Payment Amount",
      key: "payment_amount",
      dataIndex: "payment_amount",
      render: (data: any) => (Number(data) / 100).toFixed(2),
    },
  ];

  const [po_columns, setPoColumns] = useState<ColumnsType<any>>([
    {
      title: "Po No.",
      key: "po_number",
      dataIndex: "po_number",
      fixed: "left",
    },
    {
      title: "Vendor Name",
      key: "vendor",
      width: 170,
      render: (data: {
        vendor: {
          name:
            | string
            | number
            | boolean
            | ReactElement<any, string | JSXElementConstructor<any>>
            | Iterable<ReactNode>
            | ReactPortal
            | null
            | undefined;
        };
      }) => <span>{data?.vendor?.name}</span>,
    },
    {
      title: "Dispatch Time",
      key: "dispatch_time",
      render: (data: { dispatch_time: any }) => (
        <span>{getFormattedDateAndTime(data?.dispatch_time)}</span>
      ),
    },
    {
      title: "Creation Date",
      key: "created_at",
      render: (data: { created_at: any }) => (
        <span>{getFormattedDate(data?.created_at)}</span>
      ),
    },
    {
      title: "Created By",
      key: "created_by",
      render: (data: { created_by: any }) => (
        <span>{data?.created_by ?? "System"}</span>
      ),
    },
    {
      title: "Delivery link",
      key: "live_location_link",
      render: (data) => (
        <div style={{ width: 160 }}>
          {data?.live_location_link ? (
            <Tag
              className="cursor-pointer"
              onClick={() => {
                window.open(
                  data?.live_location_link?.includes("https://")
                    ? data?.live_location_link
                    : "https://" + data?.live_location_link,
                  "_blank"
                );
              }}
              color="blue"
            >
              Location link
            </Tag>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      key: "po_delivery_status",
      width: 200,
      render: (data: any) => (
        <>
          {data?.is_deleted ? (
            <>PO Cancelled</>
          ) : (
            <>
              <Select
                className="w-100"
                onChange={(e) => {
                  editPO(data?.id, {
                    po_delivery_status: e,
                  });
                }}
                disabled={!hasPermission("po.update")}
                placeholder="Select tax type"
                value={data?.po_delivery_status}
              >
                {status_type?.map((obj: any) => (
                  <Option key={obj?.id} value={obj?.value}>
                    {obj?.label}
                  </Option>
                ))}
              </Select>
            </>
          )}
        </>
      ),
    },
    {
      title: "Total",
      key: "total_po_raised_amount",
      render: (data: { total_po_raised_amount: any }) => (
        <>{parseFloat(data?.total_po_raised_amount ?? 0)?.toFixed(2)}</>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (data: { id: string }) => (
        <Space>
          <Link to={"/po-details/" + data?.id}>
            <Tag
              onClick={() => {}}
              color={"green"}
              style={{ cursor: "pointer" }}
            >
              See Details
            </Tag>
          </Link>
        </Space>
      ),
    },
  ]);

  const columns = [
    {
      title: "S.no",
      dataIndex: "index",
      render: (value: any, data: any, index: number) => <>{index + 1}</>,
    },
    {
      title: "BOQ Item",
      key: "boq_item",
      dataIndex: "boq_item",
      width: 200,
      render: (_: unknown, record: any) => {
        if (hasPermission("pi.replace_brand_with_private_label")) {
          return record["private_label_product_name"] ?? record["boq_item"];
        } else {
          return record["boq_item"];
        }
      },
    },
    hasPermission("pi.private_label_brand_name_column")
      ? {
          key: "private_label_product_name",
          title: "Private Label Name",
          dataIndex: "private_label_product_name",
          width: 200,
        }
      : {},
    hasPermission("pi.view_vendor_column")
      ? {
          key: "vendor",
          title: "Vendor",
          render: (data: any) => {
            const vendors = data?.vendor_data ?? [];
            return vendors.map((vendor: any) => vendor?.name).join(", ");
          },
          width: 150,
        }
      : {},
    {
      title: "MRP",
      key: "mrp",
      dataIndex: "mrp",
      width: 100,
    },
    {
      title: "Category",
      key: "category_name",
      dataIndex: "category_name",
      width: 100,
    },
    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "quantity",
      width: 50,
    },
    {
      title: "Raised Qty.",
      key: "po_raised",
      dataIndex: "po_raised",
      width: 110,
    },
    {
      title: "Refunded Qty.",
      key: "refund_quantity",
      render: (value: any, data: any, index: number) => (
        <>{Number(data?.refund_quantity)}</>
      ),
      width: 110,
    },
    {
      title: "Unit",
      key: "unit",
      dataIndex: "unit",
      width: 60,
    },
    {
      title: "Brand",
      key: "brand",
      dataIndex: "brand",
      width: 130,
      render: (_: unknown, record: any) => {
        // if (hasPermission("pi.replace_brand_with_private_label")) {
        //   return record["private_label_brand_name"] ?? record["brand"];
        // } else {
        //   return record["brand"];
        // }
        return record["brand"];
      },
    },
    hasPermission("pi.private_label_brand_name_column")
      ? {
          key: "private_label_brand_name",
          title: "Private Label Brand Name",
          dataIndex: "private_label_brand_name",
          width: 130,
        }
      : {},
    hasPermission("pi.view_vendor_column")
      ? {
          key: "vendor",
          title: "Vendor",
          render: (data: any) => {
            const vendors = data?.vendor_data ?? [];
            return vendors.map((vendor: any) => vendor?.name).join(", ");
          },
          width: 150,
        }
      : {},
    // {
    //   title: "Landing Price",
    //   key: "unit_selling_price",
    //   width: 100,
    //   render: (data: { [x: string]: string }) =>
    //     parseFloat(data["unit_selling_price"]),
    // },
    {
      title: "Selling Price",
      key: "unit_cost_price",
      width: 100,
      render: (data: { [x: string]: string }) =>
        parseFloat(data["unit_cost_price"]),
    },
    // {
    //   title: "Margin",
    //   key: "margin",
    //   width: 100,
    //   render: (data: { margin: number }) =>
    //     (data.margin / (1 + data.margin / 100)).toFixed(2),
    // },
    {
      title: "Tax",
      key: "tax",
      render: (data: { tax_value: any }) => {
        var tax = Number(data?.tax_value);
        return (
          <>
            <div>{tax}%</div>
          </>
        );
      },
    },
    {
      title: "Tax Type",
      key: "tax_type",
      render: (data: { tax_type: string }) => (
        <>
          {data?.tax_type === "igst" && "IGST"}
          {data?.tax_type === "cgst_sgst" && "CGST & SGST"}
          {data?.tax_type === "none" && "None"}
        </>
      ),
    },
    {
      title: "Dispatch Date",
      key: "dispatch_date",
      render: (data: any) => {
        const poForItem = po_list.find((po) => {
          const estimateItemIds = po?.po_items?.map(
            (item: any) => item?.estimate_item?.id
          );
          return estimateItemIds?.includes(data?.id);
        });

        if (!poForItem) return "N/A";

        return (
          <div style={{ maxWidth: 100 }}>
            {poForItem?.dispatch_time
              ? getFormattedDate(poForItem?.dispatch_time)
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Lead time",
      key: "lead_time",
      render: (data: any) => {
        return (
          <div style={{ maxWidth: 60 }}>
            {parseFloat(data["lead_time"]) > -1 ? (
              <>{data["lead_time"]} days</>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      title: "Remarks",
      key: "remarks",
      render: (data: any) => {
        return <div style={{ maxWidth: 200 }}> {data["remarks"]}</div>;
      },
    },
    {
      title: "Amount",
      key: "amount",
      render: (data: { [x: string]: number }) => {
        return data["total_price"];
      },
    },
  ] as ColumnsType<any>;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onPOCreateClick = async (modifiedItems?: any, vendor: any = null) => {
    if (!data?.bill_address) {
      message.error("Please select a billing address");
      return;
    }
    if (!data?.ship_address && !data?.is_store_pickup) {
      message.error("Please select a shipping address");
      return;
    }

    // Shipping address must have google maps link
    // if (!data?.ship_address?.google_map_address && !data?.is_store_pickup) {
    //   message.error("Please select a shipping address with google maps link");
    //   setShowEditAddress("ship");
    //   return;
    // }

    const validGoogleMapLink =
      data?.ship_address?.lat && data?.ship_address?.long;
    if (!validGoogleMapLink && !data?.is_store_pickup) {
      message.error("Please select a shipping address with google maps link");
      setShowEditAddress("ship");
      return;
    }

    if (!data?.ship_address && data?.is_store_pickup) {
      // Add new address to user with store details
      // set that address as ship address
      const payload = {
        user: data?.client,
        address:
          "Site No. 15, Bannerghatta Main Road, Sarakki Industrial layout, Jp Nagar phase 3, Bengaluru 560078",
        pincode: "560078",
        name: "Material Depot Store",
        city: "Bengaluru",
        state: "Karnataka",
        country: "India",
        google_map_address: "https://maps.app.goo.gl/w8JHLMCVvaSWCWzr8",
        address_type: "other",
        lat: "12.914199610096047",
        long: "77.59967375911852",
      };

      const address = await addClientAddress(payload, data?.client);

      await editPI(pi_id, {
        ship_address: address?.id,
        ship_address_description: `${address?.address}, ${address?.city}, ${address?.pincode}`,
      });
    }

    // Check if all the items have hsn_code
    const hsnMissingItems = (modifiedItems ?? data?.po_items)?.filter(
      (item: any) => !item.hsn_code
    );
    // Check if any item's variant_data exists and if it contains hsn_code
    const bestEffortResult = await Promise.all(
      hsnMissingItems.map(async (item: any) => {
        if (item?.variant_data) {
          if (item?.variant_data?.hsn_code) {
            if (
              Boolean(
                await editProFormaInvoiceItem(item?.id, {
                  hsn_code: item?.variant_data?.hsn_code,
                })
              )
            ) {
              return null;
            }
          } else {
            return item;
          }
        } else {
          return item;
        }
      })
    );

    if (bestEffortResult.some((item) => item)) {
      message.error("Some items are missing HSN code. Please fill them.");
      setHsnMissingItems(bestEffortResult.filter((item) => item));
      return;
    }

    if (modifiedItems) {
      navigate("/create-po", {
        state: {
          data: {
            ...data,
            address: data?.ship_address?.id,
            vendor: vendor,
            estimate_items: modifiedItems,
          },
        },
      });
    } else {
      navigate("/create-po", { state: { data: data } });
    }
  };

  const confirmAddress = () => {
    if (!data?.bill_address) {
      message.error("Please select a billing address");
      return;
    }
    if (!data?.ship_address && !data?.is_store_pickup) {
      message.error("Please select a shipping address");
      return;
    }

    // Shipping address must have google maps link
    const validGoogleMapLink =
      data?.ship_address?.lat && data?.ship_address?.long;

    if (!validGoogleMapLink && !data?.is_store_pickup) {
      message.error("Please select a shipping address with google maps link");
      setShowEditAddress("ship");
      return;
    }

    message.success("Adresses are valid. You can proceed to create PO");
  };

  const fetchAddresses = () => {
    if (!data?.client) return;
    setLoading(true);
    fetchClientAddresses(data?.client).then((res) => {
      setClientAddresses(res);
      setLoading(false);
    });
  };

  const onSelectBillingAddress = async (address: any) => {
    console.log("debug>>> selected address", address);
    setData({
      ...data,
      bill_address: address,
      bill_address_description: `${address?.address}, ${address?.city}, ${address?.pincode}`,
    });

    await editPI(pi_id, {
      bill_address: address?.id,
      bill_address_description: `${address?.address}, ${address?.city}, ${address?.pincode}`,
    });
  };

  const onSelectShippingAddress = async (address: any) => {
    console.log("debug>>> selected address", address);
    setData({
      ...data,
      ship_address: address,
      ship_address_description: `${address?.address}, ${address?.city}, ${address?.pincode}`,
    });

    await editPI(pi_id, {
      ship_address: address?.id,
      ship_address_description: `${address?.address}, ${address?.city}, ${address?.pincode}`,
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className={
            hasPermission("po.create") ? "" : "unclickable grey-color-text"
          }
          onClick={() => {
            createOrderFromPI(pi_id ?? "");
          }}
        >
          Create Order from PI
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            window.open(
              "https://api-dev2.materialdepot.in/apiV1/estimate-pdf/" +
                pi_id +
                "/?image=true",
              "_blank"
            );
          }}
        >
          Download PI pdf
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div
          onClick={() =>
            window.open(
              `https://api-dev2.materialdepot.in/apiV1/estimate-pdf/${pi_id}/?image=true&b2b=true`,
              "_blank"
            )
          }
        >
          Download B2B Invoice
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className={
            hasPermission("po.create") ? "" : "unclickable grey-color-text"
          }
          onClick={() => {
            onPOCreateClick();
          }}
        >
          Create PO
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <Popconfirm
          title="Are you sure to fully refund this order?"
          className={
            hasPermission("refund.create") && data?.refund_status != "full"
              ? ""
              : "unclickable grey-color-text"
          }
          onConfirm={() => {
            refundOrder({
              estimate: pi_id,
              is_full_refund: true,
            });
          }}
        >
          <div key="3">Fully Refund Order</div>
        </Popconfirm>
      ),
    },
    {
      key: "5",
      label: (
        <Link
          className={
            hasPermission("refund.create") && data?.refund_status != "full"
              ? ""
              : "unclickable grey-color-text"
          }
          to={"/order-refund/" + pi_id}
        >
          Refund Partially
        </Link>
      ),
    },
  ];

  const getVendors = () => {
    fetchVendors({}).then((res) => {
      setVendors(res);
    });
  };
  const editPO = (po_id: any, params: any) => {
    editPurchaseOrder(po_id, params).then((res) => {
      fetchPOs();
    });
  };
  const getDeliveryStatusLabel = (type: string) => {
    return status_type.find((item) => item.value == type)?.label;
  };

  const getPIData = () => {
    fetchPIfromId(pi_id ?? "").then((res) => {
      setData({ ...res, po_items: res?.estimate_items });
      fetchEstimateLogsByLeadId(res?.lead_id).then((res) => {
        setLogs(res);
      });
    });
  };

  const setColumnsAccordingToRole = () => {
    let role_columns: ColumnsType<any> = [
      {
        title: "S.no",
        dataIndex: "index",
        render: (value: any, data: any, index: number) => <>{index + 1}</>,
      },
      {
        title: "BOQ Item",
        key: "boq_item",
        dataIndex: "boq_item",
        width: 200,
      },
      {
        title: "MRP",
        key: "mrp",
        dataIndex: "mrp",
        width: 100,
      },
      {
        title: "Category",
        key: "category_name",
        dataIndex: "category_name",
        width: 100,
      },
      {
        title: "Quantity",
        key: "quantity",
        dataIndex: "quantity",
        width: 50,
      },
      {
        title: "Raised Qty.",
        key: "po_raised",
        dataIndex: "po_raised",
        width: 110,
      },
      {
        title: "Refunded Qty.",
        key: "refund_quantity",
        render: (value: any, data: any, index: number) => (
          <>{Number(data?.refund_quantity)}</>
        ),
        width: 110,
      },
      {
        title: "Unit",
        key: "unit",
        dataIndex: "unit",
        width: 60,
      },
      // {
      //   title: "Brand",
      //   key: "brand",
      //   dataIndex: "brand",
      //   width: 130,
      // },
      {
        title: "Tax",
        key: "tax",
        render: (data: { tax_value: any }) => {
          var tax = Number(data?.tax_value);
          return (
            <>
              <div>{tax}%</div>
            </>
          );
        },
      },
      {
        title: "Tax Type",
        key: "tax_type",
        render: (data: { tax_type: string }) => (
          <>
            {data?.tax_type === "igst" && "IGST"}
            {data?.tax_type === "cgst_sgst" && "CGST & SGST"}
            {data?.tax_type === "none" && "None"}
          </>
        ),
      },
      {
        title: "Lead time",
        key: "lead_time",
        render: (data: any) => {
          return (
            <div style={{ maxWidth: 60 }}>
              {parseFloat(data["lead_time"]) > -1 ? (
                <>{data["lead_time"]} days</>
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
      {
        title: "Remarks",
        key: "remarks",
        render: (data: any) => {
          return <div style={{ maxWidth: 200 }}> {data["remarks"]}</div>;
        },
      },
    ];

    if (hasPermission("pi.read")) {
      role_columns.push();
      role_columns.push({
        title: "Amount",
        key: "amount",
        render: (data: { [x: string]: number }) => {
          return data["total_price"];
        },
      });
      role_columns.push({
        title: "Coupon Discount",
        key: "total_discount",
        dataIndex: "total_discount",
      });
    }

    let role_columns_po: ColumnsType<any> = [
      {
        title: "Po No.",
        key: "po_number",
        dataIndex: "po_number",
        fixed: "left",
      },
      {
        title: "Dispatch Time",
        key: "dispatch_time",
        render: (data: { dispatch_time: any }) => (
          <span>{getFormattedDateAndTime(data?.dispatch_time)}</span>
        ),
      },
      {
        title: "Creation Date",
        key: "created_at",
        render: (data: { created_at: any }) => (
          <span>{getFormattedDate(data?.created_at)}</span>
        ),
      },
      {
        title: "Created By",
        key: "created_by",
        dataIndex: "created_by",
      },
      {
        title: "Delivery link",
        key: "live_location_link",
        render: (data) => (
          <div style={{ width: 160 }}>
            {data?.live_location_link ? (
              <Tag
                className="cursor-pointer"
                onClick={() => {
                  window.open(
                    data?.live_location_link?.includes("https://")
                      ? data?.live_location_link
                      : "https://" + data?.live_location_link,
                    "_blank"
                  );
                }}
                color="blue"
              >
                Location link
              </Tag>
            ) : (
              <></>
            )}
          </div>
        ),
      },
      {
        title: "Status",
        key: "po_delivery_status",
        width: 200,
        render: (data: any) => (
          <>
            {data?.is_deleted ? (
              <>PO Cancelled</>
            ) : (
              <>
                <Select
                  className="w-100"
                  onChange={(e) => {
                    editPO(data?.id, {
                      po_delivery_status: e,
                    });
                  }}
                  disabled={!hasPermission("po.update")}
                  placeholder="Select tax type"
                  value={data?.po_delivery_status}
                >
                  {status_type?.map((obj: any) => (
                    <Option key={obj?.id} value={obj?.value}>
                      {obj?.label}
                    </Option>
                  ))}
                </Select>
              </>
            )}
          </>
        ),
      },
    ];

    if (hasPermission("po.read")) {
      role_columns_po.push({
        title: "Vendor Name",
        key: "vendor",
        width: 170,
        render: (data: {
          vendor: {
            name:
              | string
              | number
              | boolean
              | ReactElement<any, string | JSXElementConstructor<any>>
              | Iterable<ReactNode>
              | ReactPortal
              | null
              | undefined;
          };
        }) => <span>{data?.vendor?.name}</span>,
      });
      role_columns_po.push({
        title: "Total",
        key: "total_po_raised_amount",
        render: (data: { total_po_raised_amount: any }) => (
          <>{parseFloat(data?.total_po_raised_amount ?? 0)?.toFixed(2)}</>
        ),
      });
      role_columns_po.push({
        title: "Actions",
        key: "action",
        render: (data: { id: string }) => (
          <Space>
            <Link to={"/po-details/" + data?.id}>
              <Tag
                onClick={() => {}}
                color={"green"}
                style={{ cursor: "pointer" }}
              >
                See Details
              </Tag>
            </Link>
          </Space>
        ),
      });
    }
    // setColumns(role_columns);
    setPoColumns(role_columns_po);
  };

  useEffect(() => {
    getPIData();
    fetchBranches().then((res) => {
      setBranchData(res);
    });
    // fetchEstimateLogs(pi_id ?? "").then((res) => {
    //   setLogs(res);
    // });
    fetchPOs();
    getVendors();
    fetchInvoicesOfPI();
    fetchUserDetails().then((res) => {
      setCurrentUser(res);
    });

    getEstimateTransactions(Number(pi_id))
      .then((response) => {
        setTransactions(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    fetchAddresses();
  }, [data?.client]);

  const fetchInvoicesOfPI = () => {
    setLoading(true);
    getPFIInvoices(pi_id).then((res) => {
      setInvoices(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    if (vendors?.length > 0 && data?.vendors?.length > 0) {
      const result = vendors.filter(({ id }) => data?.vendors.includes(id));
      setPOVendors(result);
    }
    setColumnsAccordingToRole();
  }, [vendors, data]);

  const fetchBasicDetails = (client_id: string) => {
    fetchClientDetails(client_id).then((res) => {
      setClientData(res);
    });
  };

  const fetchPOs = () => {
    getPurchaseOrders({ estimate: pi_id }).then((res) => {
      setPoList(res?.results);
    });
  };

  useEffect(() => {
    if (data?.client) {
      fetchBasicDetails(data?.client);
    }
  }, [data]);

  const confirm = async (invoice_id: any) => {
    if (!loading) {
      setLoading(true);
      await removeInvoiceFromPI(invoice_id);
      setLoading(false);
    }
  };

  const cancel = () => {};

  return (
    <section className={`lighter-grey-color-bg`}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-4 align-items-center">
            <div className="primary-color-text th-font-size-20 th-font-weight-700">
              Order Details
            </div>
            <Link
              className="text-decoration-none"
              to={"/client/" + client_data?.id}
              target="_blank"
            >
              <div className=" th-font-weight-600 clover-green-color-text">
                Client :
                <span className=" th-font-weight-400">
                  {" "}
                  {client_data?.f_name}
                </span>
              </div>
            </Link>
            {/* <div className=" th-font-weight-600">
              Quotation No. :
              <span className=" th-font-weight-400"> {data?.estimate_no}</span>
            </div> */}
            <div className=" th-font-weight-600">
              Lead ID :
              <span className=" th-font-weight-400"> {data?.lead_id}</span>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-center">
            {/* <div>
              <Tag color="green">Order Confirmed</Tag>
            </div> */}
            <Button onClick={confirmAddress}>Confirm Address</Button>
            <Dropdown menu={{ items }} placement="bottomRight">
              <Button
                type="primary"
                className="th-font-weight-700 d-flex align-items-center"
              >
                Actions
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
      </Header>
      <div>
        <div className="m-4 p-4 px-5 white-color-bg">
          <div className="d-flex justify-content-between">
            <img
              alt="logo"
              src="/main_logo.svg"
              style={{ height: "80px" }}
              className="mt-2"
            />
            <div className="d-flex align-items-center">
              {["delivered", "partly_delivered"].includes(
                data?.estimate_status
              ) &&
                hasPermission("pi.feedback") && (
                  <Button
                    onClick={() => {
                      setFeedbackDrawer(true);
                    }}
                    className="me-2"
                  >
                    Feedback
                  </Button>
                )}
              <Button
                onClick={() =>
                  window.open(
                    `https://materialdepot.in/public-orders/${data?.lead_id}`
                  )
                }
                className="me-2"
              >
                Open Tracking Link
              </Button>
              <Button onClick={() => setSeeLogs(true)}>See logs</Button>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-xl-6 col-12">
              <PFIBillingDetails
                name="MDEPOT RETAIL TECHNOLOGIES PRIVATE LIMITED"
                address="Site No. 15, Bannerghatta Main Road, Sarakki Industrial layout, Jp Nagar phase 3, Bengaluru 560076"
                gst_no="29AARCM7928H1ZO"
              />
            </div>
            <div className="col-xl-6 col-12">
              <PFIOrderDetails
                created_by={data?.created_by}
                assigned_to={data?.assigned_to?.f_name}
                estimate_data={data}
                client={client_data?.f_name ?? ""}
                lead_id={data?.lead_id}
                quotation_no={data?.estimate_no}
                status={data?.estimate_status}
                stage={data?.stage}
                bank_details={{
                  bank_account_number: branch_data?.bank_account_number,
                  bank_ifsc: branch_data?.bank_ifsc,
                  branch_upi: branch_data?.branch_upi,
                }}
                setDeliveryAddressDrawerVisible={() => {}}
                getPIData={getPIData}
              />
            </div>
          </div>

          <Divider />
          <div className=" row my-4 text-wrap">
            {data?.bill_address ? (
              <div className="col-xl-6 col-12">
                <div className=" p-3 py-2 row mb-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
                      Bill To
                    </div>
                    <Space className="">
                      {!po_list?.some(
                        (po) =>
                          !["dispatch_pending"].includes(po?.po_delivery_status)
                      ) && (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => {
                            setShowEditPIEditAddress("billing");
                          }}
                        >
                          Edit address
                        </Button>
                      )}
                    </Space>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Name </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.name}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Address </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.address}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Pincode </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.pincode}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact person{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.contact_person_name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact number
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.contact_person_contact}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 row">
                    <div className="col-4">GST No.</div>
                    <div className="col-8 d-flex">
                      <div>:</div>
                      <div className="w-100 px-2">
                        {data?.bill_address?.gst_no ?? client_data?.gst_number}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-xl-6 col-12">
                <div className="d-flex justify-content-end gap-1">
                  <Button
                    size="small"
                    onClick={() => {
                      setShowAddAddress(true);
                    }}
                  >
                    Add Client Address
                  </Button>
                </div>
                <PFICreateBillToAddress
                  data={data}
                  setData={setData}
                  removeBillingAddress={() => {}}
                  client_addresses={client_addresses}
                  onSelectAddress={onSelectBillingAddress}
                  noRemove
                />
              </div>
            )}
            {data?.ship_address && !data?.is_store_pickup ? (
              <div className="col-xl-6 col-12">
                <div className="p-3 py-2 row mb-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
                      Ship To
                    </div>
                    <Space className="">
                      {!po_list?.some(
                        (po) =>
                          !["dispatch_pending"].includes(po?.po_delivery_status)
                      ) && (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => {
                            setShowEditPIEditAddress("shipping");
                          }}
                        >
                          Edit address
                        </Button>
                      )}
                    </Space>
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Address Label{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.name}</div>
                      </div>
                    </div>
                  </div>

                  {data?.ship_address?.flat_no && (
                    <div className="row">
                      <div className="col-4 th-font-weight-500">Flat No.</div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>{data?.ship_address?.flat_no}</div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Address </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.address}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Pincode </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.pincode}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact person{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.contact_person_name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact number
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.contact_person_contact}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Selected address
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>
                          {data?.ship_address?.address},{" "}
                          {data?.ship_address?.city}{" "}
                          {data?.ship_address?.pincode}
                        </div>
                      </div>
                    </div>
                  </div>

                  {data?.ship_address?.client_alternative_data && (
                    <>
                      <div className="row">
                        <div className="col-4 th-font-weight-500">
                          Alternative Contact Name
                        </div>
                        <div className="col-7">
                          <div className="d-flex">
                            <div className="pe-1">:</div>
                            <div>
                              {
                                data?.ship_address?.client_alternative_data
                                  ?.contact_person_name
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4 th-font-weight-500">
                          Alternative Contact Contact
                        </div>
                        <div className="col-7">
                          <div className="d-flex">
                            <div className="pe-1">:</div>
                            <div>
                              {
                                data?.ship_address?.client_alternative_data
                                  ?.contact_person_contact
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {data?.ship_address?.google_map_address && (
                    <div className="row">
                      <div className="col-4 th-font-weight-500">
                        Google Map Address
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>
                            <div
                              className="text-decoration-underline blue-color-text"
                              onClick={() => {
                                window.open(
                                  `https://maps.google.com/?q=${data?.ship_address?.lat},${data?.ship_address?.long}`,
                                  "_blank"
                                );
                              }}
                            >
                              {`https://maps.google.com/?q=${data?.ship_address?.lat},${data?.ship_address?.long}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              !data?.is_store_pickup && (
                <div className="col-xl-6 col-12">
                  <PFICreateShipToAddress
                    data={data}
                    setData={setData}
                    removeShippingAddress={() => {}}
                    client_addresses={client_addresses}
                    onSelectAddress={onSelectShippingAddress}
                    noRemove
                  />
                </div>
              )
            )}
            {data?.is_store_pickup && (
              <>
                <div className="col-xl-6 col-12">
                  <div className="p-3 py-2 row mb-2">
                    <div className=" th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
                      <Tag color="blue" className="th-font-size-16">
                        Store Pickup
                      </Tag>
                    </div>
                    <div className="row">
                      <div className="col-4 th-font-weight-500">
                        Address Label{" "}
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>MDEPOT RETAIL TECHNOLOGIES PRIVATE LIMITED</div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4 th-font-weight-500">Address </div>
                      <div className="col-7">
                        <div className="d-flex overflow-wrap">
                          <div className="pe-1">:</div>
                          <div>
                            Site No. 15, Bannerghatta Main Road, Sarakki
                            Industrial layout, Jp Nagar phase 3, Bengaluru
                            560076
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4 th-font-weight-500">Pincode </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>560102</div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4 th-font-weight-500">
                        Google Map Address
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>
                            <div
                              className="text-decoration-underline blue-color-text"
                              onClick={() => {
                                window.open(
                                  "https://maps.app.goo.gl/w8JHLMCVvaSWCWzr8",
                                  "_blank"
                                );
                              }}
                            >
                              https://maps.app.goo.gl/w8JHLMCVvaSWCWzr8
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            <>
              <div className="py-1">
                {hasPermission("po.create") && (
                  <div className="th-font-size-16 th-font-weight-600">
                    {other_vendor_select ? (
                      <> Select Items to raise PO</>
                    ) : (
                      <> Select Vendor to raise PO</>
                    )}
                  </div>
                )}

                {other_vendor_select ? (
                  <div className="d-flex gap-2 py-3">
                    <Button
                      disabled={!hasSelected}
                      onClick={() => {
                        let items = data?.estimate_items?.filter(
                          (item: any) => {
                            return selectedRowKeys?.includes(item?.id);
                          }
                        );

                        items = items?.map((item: any) => {
                          return {
                            ...item,
                            quantity: (
                              Number(item?.quantity) - Number(item?.po_raised)
                            ).toFixed(2),
                          };
                        });

                        // navigate("/create-po", {
                        //   state: {
                        //     data: {
                        //       ...data,
                        //       address: data?.ship_address?.id,
                        //       vendor: null,
                        //       estimate_items: items,
                        //     },
                        //   },
                        // });
                        onPOCreateClick(items);
                      }}
                      type="primary"
                    >
                      Raise PO
                    </Button>
                    <Button
                      type="default"
                      onClick={() => {
                        setOtherVendorSelect(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div
                    className="d-flex gap-3 py-3"
                    style={{ flexWrap: "wrap" }}
                  >
                    {po_vendors?.length > 0 && (
                      <>
                        {po_vendors?.map((vendor: any) => {
                          return (
                            // <Link
                            //   to="/create-po"
                            //   state={{
                            //     data: {
                            //       ...data,
                            //       vendor: vendor,
                            //       address: data?.ship_address?.id,

                            //       estimate_items: data?.estimate_items?.filter(
                            //         (item: any) => {
                            //           return item.vendor?.includes(vendor?.id);
                            //         }
                            //       ),
                            //     },
                            //   }}
                            // >
                            <Button
                              onClick={() => {
                                onPOCreateClick(
                                  data?.estimate_items?.filter((item: any) =>
                                    item.vendor?.includes(vendor?.id)
                                  ),
                                  vendor
                                );
                              }}
                              type="primary"
                            >
                              {vendor?.name}
                            </Button>
                            // </Link>
                          );
                        })}
                      </>
                    )}

                    {/* <Link
                    to="/create-po"
                    state={{
                      data: {
                        ...data,
                        address: data?.ship_address?.id,
                        vendor: null,
                        estimate_items: data?.estimate_items?.filter(
                          (item: any) => {
                            return item.vendor?.length == 0 || !item.vendor;
                          }
                        ),
                      },
                    }}
                  > */}
                    <Button
                      onClick={() => {
                        setOtherVendorSelect(true);
                      }}
                      type="primary"
                    >
                      Others
                    </Button>
                    {/* </Link> */}
                  </div>
                )}

                {!copy_clipboard_select && (
                  <Button
                    onClick={() => {
                      setCopyClipboardSelect(true);
                    }}
                    className="mb-3"
                    type="primary"
                  >
                    Select items to copy
                  </Button>
                )}

                {copy_clipboard_select ? (
                  <div className="d-flex gap-2 py-3">
                    <Button
                      disabled={!hasSelected}
                      onClick={() => {
                        let items = data?.estimate_items?.filter(
                          (item: any) => {
                            return selectedRowKeys?.includes(item?.id);
                          }
                        );
                        let copy_text = "Lead ID:" + data?.lead_id + "\n\n";
                        items?.map((item: any) => {
                          copy_text +=
                            item?.private_label_product_name +
                            " --" +
                            (item?.quantity ??
                              0 - item?.po_raised ??
                              0 - item?.refund_quantity ??
                              0) +
                            " " +
                            item?.unit +
                            ", Brand: " +
                            item?.brand +
                            "\n";
                        });
                        navigator.clipboard.writeText(copy_text);
                        message.success("Copied to clipboard");
                        setCopyClipboardSelect(false);
                      }}
                      type="primary"
                    >
                      Copy to Clipboard
                    </Button>
                    <Button
                      type="default"
                      onClick={() => {
                        setCopyClipboardSelect(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
            {other_vendor_select ? (
              <Table
                rowSelection={
                  copy_clipboard_select || other_vendor_select
                    ? rowSelection
                    : undefined
                }
                columns={columns}
                dataSource={data?.estimate_items}
                scroll={{ x: "max-content" }}
                rowKey={(record) => record.id}
                onRow={(record, index) => ({
                  style: {
                    background:
                      Number(record?.quantity) === Number(record?.po_raised)
                        ? "#84FACF"
                        : Number(record?.quantity) > Number(record?.po_raised)
                        ? "#FFFFC0"
                        : "#FFB4A3",
                  },
                })}
              />
            ) : (
              <Table
                rowSelection={
                  copy_clipboard_select || other_vendor_select
                    ? rowSelection
                    : undefined
                }
                columns={columns}
                dataSource={data?.estimate_items}
                scroll={{ x: "max-content" }}
                rowKey={(record) => record.id}
                onRow={(record, index) => ({
                  style: {
                    background:
                      Number(record?.quantity) === Number(record?.po_raised)
                        ? "#84FACF"
                        : Number(record?.quantity) > Number(record?.po_raised)
                        ? "#FFFFC0"
                        : "#FFB4A3",
                  },
                })}
              />
            )}
          </div>
          <div className="row">
            <div className="col-xl-6 col-12">
              <div>
                <h6 className="th-font-weight-600">Payment Terms</h6>
                <div>
                  {data?.estimate_payment_terms?.map(
                    (each: any, ind: number) => (
                      <div key={ind}>
                        {each?.term}:{each?.percentage}%
                      </div>
                    )
                  )}
                </div>
              </div>
              <Divider />
              <div>
                <div>
                  <h6 className="th-font-weight-600">Terms and Conditions</h6>
                  <div>
                    <div className="mb-2">
                      {data?.terms_and_condition?.description}
                    </div>
                  </div>
                </div>
              </div>
              <Divider />

              {/* <div>
                <div className="">
                  <div>
                    <h6 className="th-font-weight-600">Documents</h6>{" "}
                    {"(Total files size should be less than 25MB)"}
                  </div>
                  {data?.documents?.map((document: any, index: any) => {
                    return (
                      <div style={{ padding: 8 }}>
                        <div
                          style={{
                            border: "1px #D1D0CE solid",
                            padding: 4,
                            cursor: "pointer",
                            fontWeight: 500,
                          }}
                          onClick={() => {
                            // openInNewTab(document?.file);
                          }}
                        >
                          {document?.file_name ??
                            document?.file?.replace(
                              "https://mysite-powo-pdfs.s3.ap-south-1.amazonaws.com/media/porject_files/",
                              ""
                            )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div> */}
            </div>
            <div className="col-xl-6 col-12">
              <div>
                <div className="d-flex justify-content-between">
                  {/* <div className="">Taxable Amount</div> */}
                  <div className="">
                    {/* ₹ {Number(getTotalTaxableAmount()).toFixed(2)} */}
                  </div>
                </div>
                <TaxAmount items={data?.estimate_items ?? []} />
              </div>
              <Divider />
              <div>
                {data?.estimate_additional_charges?.map(
                  (each: any, ind: number) => (
                    <>
                      <div className="d-flex justify-content-between" key={ind}>
                        <div className="">
                          {each?.charge_type}
                          {each?.charge_type
                            ?.toLowerCase()
                            ?.includes("discount")
                            ? " (as per item)"
                            : ` (@${each?.tax_value + "%"} ${each?.tax_type})`}
                        </div>
                        <div className="">
                          {Number(
                            Number(each?.amount) +
                              Number(each?.amount) *
                                Number(each?.tax_value) *
                                0.01
                          ).toFixed(2)}
                        </div>
                      </div>
                      {each.remark && each.remark != "" && (
                        <div>Remark: {each.remark}</div>
                      )}
                    </>
                  )
                )}
                {parseFloat(data?.coupon_discount_amount ?? "0") > 0 && (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="">Coupon Discount</div>
                      <div className="">
                        ₹ {Number(data?.coupon_discount_amount).toFixed(2)}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="th-font-size-12">Coupon Code</div>
                      <div className="th-font-size-12">
                        {data?.coupon_data?.coupon_code}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Divider />
              <div>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h6>Total Amount</h6>
                  </div>
                  <div className="">
                    {Number(data?.total_estimate_amount)}
                    (Rounded off)
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="th-font-size-18 th-font-weight-500">Invoices</div>
            <div className="d-flex gap-2 py-4 flex-wrap">
              {invoices?.map((invoice) => {
                return (
                  <div
                    onClick={() => {
                      window.open(
                        invoice?.invoice_file ?? invoice?.invoice_file_link,
                        "_blank"
                      );
                    }}
                    className="p-3 px-4 border rounded-3 cursor-pointer"
                  >
                    <div className="th-font-size-16">
                      Invoice number : {invoice?.invoice_number}
                    </div>
                    <div className="th-font-size-16">
                      Invoice amount : {invoice?.invoice_value}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this invoice?"
                        onConfirm={() => {
                          confirm(invoice?.id);
                        }}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          className="d-flex align-items-center"
                          danger
                          loading={loading}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                      <Button
                        className=""
                        loading={loading}
                        onClick={() => {
                          console.log("debug>>>>", invoice);
                          window.open(
                            String(
                              invoice?.invoice_file ??
                                invoice?.invoice_file_link
                            ).replaceAll(
                              "mysite-powo-pdfs",
                              "materialdepotimages"
                            ),
                            "_blank"
                          );
                        }}
                      >
                        Open
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {data?.payment_amount &&
            data?.payment_type &&
            (hasPermission("order.payment_info") ||
              currentUser?.id === "31fd5d70-9dd0-4b19-a49a-dafd7da488d4") && (
              <>
                <Divider />
                <div>
                  <div className="th-font-size-18 th-font-weight-500">
                    Payment Details
                  </div>
                  <div className="d-flex gap-2 py-4">
                    <div className="p-3 px-4 border rounded-3">
                      <div className="th-font-size-14">
                        Payment amount : {data?.payment_amount}
                      </div>
                      <div className="th-font-size-14">
                        Payment type : {data?.payment_type}
                      </div>
                      <div className="th-font-size-14">
                        Payment Reference : {data?.payment_reference_id}
                      </div>
                      <div className="th-font-size-14">
                        Payment Reference Type : {data?.payment_reference_type}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
      </div>

      <div className="m-4 p-4 px-5 white-color-bg">
        <div className="primary-color-text th-font-size-18 th-font-weight-700">
          POs Raised
        </div>
        <div className="py-3">
          <Table
            dataSource={po_list}
            columns={po_columns}
            rowKey={(record) => record.id}
            scroll={{
              x: "100%",
            }}
          />
        </div>
      </div>

      {transactions?.length > 0 && (
        <div className="m-4 p-4 px-5 white-color-bg">
          <div className="primary-color-text th-font-size-18 th-font-weight-700">
            Transactions Recorded
          </div>
          <div className="py-3">
            <Table
              dataSource={transactions}
              columns={transactionColumns}
              rowKey={(record) => record.id}
              scroll={{
                x: "100%",
              }}
            />
          </div>
        </div>
      )}

      <Drawer
        title="Enter HSN Code"
        placement="right"
        onClose={() => {
          setHsnMissingItems([]);
          getPIData();
        }}
        open={hsnMissingItems.length > 0}
      >
        <HSNCodeFill
          items={hsnMissingItems}
          onFinish={() => {
            setHsnMissingItems([]);
            navigate("/create-po", { state: { data: data } });
          }}
        />
      </Drawer>

      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={() => {
          setSeeLogs(false);
        }}
        open={see_logs}
      >
        {logs?.map((log) => {
          return <ProFormaLogCard log={log} />;
        })}
      </Drawer>

      <Drawer
        open={showAddAddress}
        title="Add Client Address"
        placement="right"
        onClose={() => {
          setShowAddAddress(false);
        }}
      >
        <AddClientAddressForm
          user_id={data?.client}
          onClientAddAddressDrawerClose={() => {
            setShowAddAddress(false);
          }}
          fetchAddresses={fetchAddresses}
        />
      </Drawer>
      <Drawer
        open={Boolean(showEditAddress)}
        title="Edit Client Address"
        placement="right"
        onClose={() => {
          setShowEditAddress(false);
        }}
      >
        <EditClientAddressForm
          address={(() => {
            if (showEditAddress === "ship") {
              return data?.ship_address;
            }
            if (showEditAddress === "bill") {
              return data?.bill_address;
            }

            return {};
          })()}
          onClientEditAddressClose={() => {
            setShowEditAddress(false);
          }}
          fetchAddresses={() => {
            fetchAddresses();
            getPIData();
          }}
        />
      </Drawer>
      <Drawer
        open={showFeedbackDrawer}
        title="Feedback"
        placement="right"
        onClose={() => {
          setFeedbackDrawer(false);
        }}
      >
        <OrderFeedback
          estimateId={data?.id}
          shown={showFeedbackDrawer}
          onClose={() => {
            setFeedbackDrawer(false);
          }}
        />
      </Drawer>
      <Drawer
        open={Boolean(showEditPIEditAddress)}
        onClose={() => {
          setShowEditPIEditAddress(null);
        }}
        title="Edit Address"
      >
        {showEditPIEditAddress && (
          <PIEditAddress
            estimate={data}
            type={showEditPIEditAddress}
            refresh={getPIData}
            onClose={() => setShowEditPIEditAddress(null)}
          />
        )}
      </Drawer>
    </section>
  );
};

export default OrderDetails;
