import { Button, Drawer, Form, Input, InputNumber, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { IProcurementItem } from "../../../apis/procurement.api";
import { getVendor } from "../../../apis/vendor.api";
import { VendorWarehouseForm } from "../../../pages/vendors/form/warehouse";

export interface ProcurementItemFormProps {
  initData?: Partial<IProcurementItem>;
  onSubmit: (values: Record<string, string | number>) => void;
  vendors: any[];
  useQuantityField?: boolean;
  originalQuantity?: number;
}

enum RemarkType {
  DISCONTINUED = "Discontinued",
  DISTINCT_LEAD_TIMES = "Distinct Lead Times",
  OTHERS = "Others",
}

export function ProcurementItemForm({
  initData = {},
  onSubmit,
  vendors,
  useQuantityField,
  originalQuantity,
}: ProcurementItemFormProps) {
  const [form] = Form.useForm();
  const [selectedVendor, setSelectedVendor] = useState<number | undefined>(
    initData?.selected_vendor_id ?? initData?.vendor?.[0]?.id
  );
  const [showWarehouseDrawer, setShowWarehouseDrawer] = useState(false);
  const [remarkType, setRemarkType] = useState<RemarkType>(RemarkType.OTHERS);
  const [selectedVendorDetails, setSelectedVendorDetails] = useState<
    any | null
  >(null);

  const remarkHelperField = () => {
    const quantity = form.getFieldValue("quantity");
    const restQuantity = Number(originalQuantity) - Number(quantity ?? 0);
    if (remarkType === RemarkType.DISCONTINUED) {
      form.setFieldValue(
        "remarks",
        `Remaining ${restQuantity} items are discontinued`
      );
      return null;
    }
    if (remarkType === RemarkType.DISTINCT_LEAD_TIMES) {
      return (
        <InputNumber
          placeholder="Lead Time for rest"
          size="small"
          style={{ width: 100 }}
          onChange={(e) => {
            form.setFieldValue(
              "remarks",
              `Remaining ${restQuantity} items have lead time of ${e} days`
            );
          }}
        />
      );
    }
    if (remarkType === RemarkType.OTHERS) {
      return null;
    }

    return null;
  };

  useEffect(() => {
    if (!selectedVendor) {
      setSelectedVendorDetails(null);
      return;
    }

    getVendor(selectedVendor).then((data) => {
      setSelectedVendorDetails(data);
      setTimeout(() => {
        if (data?.warehouses?.length === 1) {
          form.setFieldsValue({
            selected_vendor_ware_house: data?.warehouses[0]?.id,
          });
        }
      }, 100);
    });
  }, [form, selectedVendor]);

  useEffect(() => {
    if (form && useQuantityField) {
      form.setFieldValue("quantity", initData?.quantity);
    }
  }, [form, useQuantityField, initData]);

  return (
    <>
      <Form
        onFinish={onSubmit}
        initialValues={{
          lead_time: initData?.lead_time,
          remarks: initData?.remarks,
          total_stock: initData?.total_stock ?? 0,
          selected_vendor: selectedVendor,
          selected_vendor_ware_house: initData?.selected_vendor_ware_house_id,
        }}
        key={initData?.id}
        layout="inline"
        form={form}
      >
        <Space className="my-1">
          <div>
            <div style={{ fontSize: 9, marginBottom: 1 }}>Vendor</div>
            <Form.Item
              name="selected_vendor"
              rules={[{ required: true, message: "Please select vendor" }]}
            >
              <Select
                placeholder="Vendor"
                style={{ minWidth: 175, maxWidth: 175 }}
                filterOption={(input, option) =>
                  (String(option?.label) ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={vendors.map((vendor: any) => ({
                  label: `${vendor?.name}`,
                  value: vendor?.id,
                }))}
                showSearch
                onChange={(value) => setSelectedVendor(value)}
                size="small"
              />
            </Form.Item>
          </div>
          {selectedVendorDetails &&
          selectedVendorDetails?.warehouses?.length > 0 ? (
            <div>
              <div style={{ fontSize: 9, marginBottom: 1 }}>Warehouse</div>
              <Form.Item
                name="selected_vendor_ware_house"
                rules={[{ required: true, message: "Please select warehouse" }]}
              >
                <Select
                  placeholder="Vendor Warehouse"
                  style={{ minWidth: 175, maxWidth: 175 }}
                  filterOption={(input, option) =>
                    (String(option?.label) ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={(selectedVendorDetails?.warehouses ?? []).map(
                    (warehouse: any) => ({
                      label: `${warehouse?.name}`,
                      value: warehouse?.id,
                    })
                  )}
                  showSearch
                  size="small"
                />
              </Form.Item>
            </div>
          ) : (
            <Button
              type="dashed"
              size="small"
              onClick={() => setShowWarehouseDrawer(true)}
            >
              Add Warehouse
            </Button>
          )}
          {/* {selectedVendor &&
          selectedVendorDetails &&
          selectedVendorDetails?.warehouse?.length === 0 && (
            <Button size="small">Add Warehouse</Button>
          )} */}
          {useQuantityField && (
            <div>
              <div style={{ fontSize: 9, marginBottom: 1 }}>Avl. Quantity</div>
              <Form.Item
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: "Please enter quantity",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Quantity"
                  size="small"
                  onChange={(e) => {
                    const val = Number(e);

                    if (remarkType === RemarkType.OTHERS) return;
                    if (
                      remarkType === RemarkType.DISCONTINUED &&
                      originalQuantity
                    ) {
                      form.setFieldValue(
                        "remarks",
                        `Remaining ${
                          Number(originalQuantity) - val
                        } items are discontinued`
                      );
                    }
                    if (
                      remarkType === RemarkType.DISTINCT_LEAD_TIMES &&
                      originalQuantity
                    ) {
                      form.setFieldValue(
                        "remarks",
                        `Remaining ${
                          Number(originalQuantity) - val
                        } items have lead time of ${form.getFieldValue(
                          "remarks"
                        )} days`
                      );
                    }
                  }}
                />
              </Form.Item>
            </div>
          )}
          <div>
            <div style={{ fontSize: 9, marginBottom: 1 }}>Lead Time</div>
            <Form.Item
              name="lead_time"
              rules={[
                {
                  required: true,
                  message: "Please enter quantity",
                },
              ]}
            >
              <InputNumber placeholder="Lead Time" size="small" />
            </Form.Item>
          </div>
          <div className="me-2">
            <div style={{ fontSize: 9, marginBottom: 1 }}>Remarks</div>
            <div className="d-flex gap-1 align-items-center">
              <Select
                style={{ minWidth: 100, marginBottom: 0 }}
                value={remarkType}
                onChange={(value) => {
                  setRemarkType(value);
                  if (value === RemarkType.OTHERS) {
                    form.setFieldValue("remarks", "");
                  }
                }}
                size="small"
                options={
                  Object.values(RemarkType).map((value) => ({
                    label: value,
                    value,
                  })) as any
                }
              />
              {remarkHelperField()}
              <Form.Item
                name="remarks"
                style={{
                  minWidth: 150,
                  display: remarkType !== RemarkType.OTHERS ? "none" : "block",
                }}
              >
                <Input placeholder="Remarks" size="small" />
              </Form.Item>
            </div>
          </div>
          {/* <Form.Item name="total_stock">
          <Input placeholder="Total Stock" />
        </Form.Item> */}
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {initData?.lead_time ? "Edit" : "Submit"}
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <Drawer
        open={showWarehouseDrawer}
        title="Add Vendor Warehouse"
        onClose={() => {
          setShowWarehouseDrawer(false);
          // Re fetch vendor details
          if (selectedVendor) {
            getVendor(selectedVendor).then((data) => {
              setSelectedVendorDetails(data);
            });
          }
        }}
      >
        <VendorWarehouseForm
          vendor={selectedVendorDetails}
          onClose={() => {
            setShowWarehouseDrawer(false);
            // Re fetch vendor details
            if (selectedVendor) {
              getVendor(selectedVendor).then((data) => {
                setSelectedVendorDetails(data);
              });
            }
          }}
        />
      </Drawer>
    </>
  );
}
