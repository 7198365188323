import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Drawer,
  Input,
  Modal,
  Pagination,
  Radio,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchBranchMembers } from "../../../apis/branch.api";
import {
  fetchPIRevisions,
  fetchProFormaInvoices,
} from "../../../apis/pro_forma_invoice.api";
import { getFormattedDate } from "../../../helpers/date_helper";
import { getPIOrderStatusLabel } from "../../../helpers/misc_helper";

import moment from "moment";
import { fetchBranches } from "../../../apis/branch.api";
import { fetchClientDetails } from "../../../apis/client.api";
import { fetchPIfromId } from "../../../apis/pro_forma_invoice.api";
import ProformaInvoicePreview from "../../../components/pro_forma_invoice/pro_forma_invoice_modal";
import { Client } from "../../../interfaces/client.interface";

interface DataType {
  id: number;
  created_by: string;
  assigned_to: null | string;
  organisation: number;
  vendor: string | null;
  terms_and_condition: number;
  total_estimate_amount: number;
  created_at: string;
  modified_at: string;
  estimate_payment_terms: Array<number>;
  documents: Array<any>;
  terms_and_conditions_description: string;
  is_deleted: false;
  deleted_by: string | null;
  gst: number;
  project: any;
  ship_address: string | null;
  ship_address_description: string | null;
  estimate_items: Array<number>;
  estimate_additional_charges: Array<number>;
  lead_id: string;
  payment_description: string | null;
  gst_description: string | null;
  bill_address: string | null;
  bill_address_description: string | null;
  is_draft: boolean;
  is_accepted: boolean;
  is_revised: boolean;
  status: string;
  estimate_no: string | null;
  estimate_status: string | null;
  lost_reason: string | string | null;
  image: boolean;
}

interface FilterType {
  stage?: string | undefined;
  created_at__gte?: string | undefined;
  created_at__lte?: string | undefined;
  created_by?: string | undefined;
  assigned_to?: string | undefined;
}

const { RangePicker } = DatePicker;

const orderStagesOptions = [
  { id: "4", value: "order_confirmed", label: "Order Confirmed" },
  { id: "5", value: "under_dispatched", label: "Under Dispatched" },
  // { id: "6", value: "order_lost", label: "Order Lost" }, --removed there was error fetching this stage
  { id: "7", value: "delivered", label: "Delivered" },
];

const OrderList = () => {
  const order_pending_statuses = [
    "draft",
    "payment_pending",
    "approval_pending",
    "quote_approval_pending",
    "availability_check",
  ];
  const columns: ColumnsType<DataType> = [
    {
      title: "Lead ID",
      key: "lead_id",
      dataIndex: "lead_id",
      width: 160,
    },
    {
      title: "Source",
      key: "order_source",
      render: (data) => <>{data?.order_source}</>,
      width: 100,
    },
    // {
    //   title: "Pro Forma Invoice No.",
    //   key: "estimate_no",
    //   dataIndex: "estimate_no",
    // },
    {
      title: "Client",
      key: "client_data",
      render: (data) => <>{data?.client?.f_name}</>,
    },
    {
      title: "Creation date",
      key: "creation_date",
      render: (data) => <>{getFormattedDate(data?.created_at)}</>,
    },
    {
      title: "Created by",
      key: "created_by",
      dataIndex: "created_by",
    },
    {
      title: "Assigned to",
      key: "assigned_to",
      render: (data) => <>{data?.assigned_to?.f_name}</>,
    },
    {
      title: "Total item quantity",
      key: "estimate_item_count",
      render: (data) => (
        <div
          style={{
            width: 80,
          }}
        >
          {data?.item_count?.estimate_item_count ?? ""}
        </div>
      ),
    },
    {
      title: "Po raised item quantity",
      key: "po_count",
      render: (data) => (
        <div
          style={{
            width: 150,
          }}
        >
          {data?.item_count?.po_item_count ?? ""}
        </div>
      ),
    },
    {
      title: "Status",
      key: "estimate_status",
      width: 200,
      render: (data) => (
        <>
          {getPIOrderStatusLabel(data?.estimate_status)}{" "}
          {data?.is_store_pickup && (
            <Tag color="green" className="mt-1">
              Store Pickup
            </Tag>
          )}
          {data?.is_outside_delivery && (
            <Tag color="orange" className="mt-1">
              Outstation Delivery
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Total",
      key: "total_estimate_amount",
      render: (data) => (
        <>{parseFloat(data?.total_estimate_amount ?? 0)?.toFixed(2)}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="d-flex ">
          {!order_pending_statuses?.includes(record?.estimate_status ?? "") ? (
            <Link className="" to={"/order-details/" + record?.id}>
              <Space size="middle">
                <Button size="small" type="primary" onClick={() => {}}>
                  Details
                </Button>
              </Space>
            </Link>
          ) : (
            <Link className="" to={"/pro-forma-invoice-details/" + record?.id}>
              <Space size="middle">
                <Button size="small" type="primary" onClick={() => {}}>
                  Details
                </Button>
              </Space>
            </Link>
          )}

          <DownloadOutlined
            className="px-2"
            style={{ fontSize: 20 }}
            onClick={() => {
              // setModal2Visible(true);
              // setPoDetails(data);
              // history("/app/project/estimate-details/" + data?.id);
              window.open(
                "https://api-dev2.materialdepot.in/apiV1/estimate-pdf/" +
                  record?.id +
                  "/?image=true",
                "_blank"
              );
            }}
          />
          {/* <EyeOutlined
            className=""
            style={{ fontSize: 20 }}
            onClick={() => {
              setPIId(record?.id.toString());
              setBasicModal(!basicModal);
            }}
          /> */}
        </div>
      ),
    },
    // {
    //   title: "Revisions",
    //   key: "revisions",
    //   render: (data) => {
    //     return (
    //       <Button
    //         size="small"
    //         className="fw-bold"
    //         onClick={() => {
    //           getPIRevisions(data?.estimate_no);
    //         }}
    //         style={{ cursor: "pointer" }}
    //       >
    //         Revisions
    //       </Button>
    //     );
    //   },
    // },
  ];
  const [stage__in, setStage__in] = useState(
    "order_placed,order_confirmed,order_lost,partly_shipped,shipped,partly_delivered,delivered,refunded"
  );
  const [status__in, setStatus__in] = useState(
    "availability_check,order_placed,order_confirmed,partly_shipped,shipped,partly_delivered,delivered,refunded,order_cancelled"
  );
  const [do_search, setDoSearch] = useState(false);

  const sortOptions = [
    { id: "1", value: "created_at", label: "Sort by Date Ascending" },
    { id: "2", value: "-created_at", label: "Sort by Date Descending" },
    {
      id: "3",
      value: "total_estimate_amount",
      label: "Sort by Total Ascending",
    },
    {
      id: "4",
      value: "-total_estimate_amount",
      label: "Sort by Total Descending",
    },
  ];

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [client_search, setClientSearch] = useState("");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Array<any>>([]);
  const [total_invoices, setTotalInvoices] = useState<number>(0);
  const [estimate_revision_drawer_visible, setEstimateRevisionDrawerVisible] =
    useState(false);
  const [revision_data, setRevisionData] = useState<Array<any>>([]);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [members, setMembers] = useState([]);

  const [filter, setFilter] = useState<FilterType>();

  const [basicModal, setBasicModal] = useState(false);
  const [pIId, setPIId] = useState("");
  const [data1, setData1] = useState<any>({});
  const [branch_data, setBranchData] = useState<any>({});
  const [client_data, setClientData] = useState<Client | null>(null);
  const [sortVal, setSortVal] = useState<string>();

  const toggleShow = () => setBasicModal(!basicModal);

  const fetchBasicDetails = (client_id: string) => {
    fetchClientDetails(client_id).then((res) => {
      setClientData(res);
    });
  };

  useEffect(() => {
    fetchPIfromId(pIId).then((res) => {
      setData1({ ...res, po_items: res?.estimate_items });
    });
    fetchBranches().then((res) => {
      setBranchData(res);
    });
  }, [pIId]);

  useEffect(() => {
    if (data1?.client) {
      fetchBasicDetails(data1?.client);
    }
  }, [data1]);

  const handleButtonClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleReset = () => {
    setFilter(undefined);
  };

  const getProFormaInvoices = (params: any) => {
    setLoading(true);
    setPage(params?.page);
    fetchProFormaInvoices({
      page: params?.page ?? 1,
      ...params,
    }).then((res) => {
      setData(res?.results);
      setLoading(false);
      setTotalInvoices(res?.count);
    });
  };

  const getPIRevisions = (estimate_no: string) => {
    setEstimateRevisionDrawerVisible(true);
    fetchPIRevisions(estimate_no).then((res) => {
      setRevisionData(res);
    });
  };

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      setMembers(res);
    });
  }, []);

  useEffect(() => {
    getProFormaInvoices({
      page: 1,
      ...filter,
      search,
      ordering: sortVal,
      client_search,
      estimate_status__in: status__in,
    });
    // if (!filter?.stage) {
    //   getProFormaInvoices({
    //     page: 1,
    //     estimate_status__in: status__in,
    //     ...filter,
    //     search,
    //     ordering: sortVal,
    //     client_search,
    //   });
    // } else {
    // }
  }, [filter, sortVal, status__in, do_search]);

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div>
        <div className="d-flex justify-content-between">
          <h3>Orders</h3>
          <div className="d-flex gap-2">
            {/* <div>
              {" "}
              <Select
                placeholder="Sort by"
                className="mt-0"
                style={{ minWidth: "200px" }}
                showArrow={true}
                value={sortVal}
                onChange={setSortVal}
                options={sortOptions.map((item: any) => ({
                  value: item?.value,
                  label: item?.label,
                }))}
              ></Select>
            </div> */}
            {dropdownVisible && <Button onClick={handleReset}>Reset</Button>}
            <Button onClick={handleButtonClick} className="pt-0">
              <FilterOutlined style={{ fontSize: 16 }} />
            </Button>
            <Input.Search
              type="text"
              style={{ maxWidth: 180 }}
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onSearch={(value) => {
                setFilter(undefined);
                setSortVal(undefined);
                setStatus__in(
                  "availability_check,order_placed,order_confirmed,partly_shipped,shipped,partly_delivered,delivered,refunded,order_cancelled"
                );
                setDoSearch(!do_search);
                // getProFormaInvoices({
                //   page: 1,
                //   estimate_status__in: status__in,
                //   search,
                //   client_search,
                // });
              }}
            />
            {/* <Input.Search
              type="text"
              style={{ maxWidth: 180 }}
              placeholder="Search Client"
              value={client_search}
              onChange={(e) => {
                setClientSearch(e.target.value);
              }}
              onSearch={(value) => {
                setFilter(undefined);
                setSortVal(undefined);
                getProFormaInvoices({
                  page: 1,
                  estimate_status__in: status__in,

                  search,
                  client_search,
                });
              }}
            /> */}
          </div>
        </div>

        {dropdownVisible && (
          <div className="row">
            <div className="d-flex flex-row justify-content-between">
              <div className="mr-2 col ">
                <RangePicker
                  style={{ width: "95%" }}
                  onChange={(event) => {
                    if (event) {
                      setFilter({
                        ...filter,
                        created_at__lte: event[1]
                          ?.add(1, "day")
                          ?.format("YYYY-MM-DD"),
                        created_at__gte: event[0]?.format("YYYY-MM-DD"),
                      });
                    } else {
                      setFilter({
                        ...filter,
                        created_at__gte: undefined,
                        created_at__lte: undefined,
                      });
                    }
                  }}
                />
                <div className="th-font-size-12 px-1">Choose Date</div>
              </div>
              <div className="mr-2 col">
                <Select
                  placeholder="Select Created by"
                  style={{ width: "95%" }}
                  value={filter?.created_by}
                  onChange={(e) => {
                    setFilter({ ...filter, created_by: e });
                  }}
                  options={members.map((item: any) => ({
                    value: item?.user?.id,
                    label: item?.user?.f_name,
                  }))}
                ></Select>
                <div className="th-font-size-12 px-1">Created by</div>
              </div>
              <div className="mr-2 col">
                <Select
                  placeholder="Select Assigned To"
                  style={{ width: "95%" }}
                  value={filter?.assigned_to}
                  onChange={(e) => {
                    setFilter({ ...filter, assigned_to: e });
                  }}
                  options={members.map((item: any) => ({
                    value: item?.user?.id,
                    label: item?.user?.f_name,
                  }))}
                ></Select>
                <div className="th-font-size-12 px-1">Assigned To</div>
              </div>
              <div className="mr-2 col">
                <Select
                  placeholder="Select Stage"
                  style={{ width: "95%" }}
                  value={filter?.stage}
                  onChange={(e) => {
                    setFilter({ ...filter, stage: e });
                  }}
                  options={orderStagesOptions.map((item) => ({
                    value: item.value,
                    label: item.label,
                  }))}
                ></Select>
                <div className="th-font-size-12 px-1">Stage</div>
              </div>
            </div>
          </div>
        )}
        <div>
          <Radio.Group
            value={status__in}
            onChange={(e) => {
              setStatus__in(e.target.value);
            }}
            style={{ marginBottom: 16 }}
          >
            <Radio.Button value="availability_check">
              Request Delivery Timeline
            </Radio.Button>
            <Radio.Button value="order_placed">
              Order Processing Pending
            </Radio.Button>
            <Radio.Button value="order_confirmed,partly_shipped,shipped,partly_delivered,delivered,refunded">
              Processed Orders
            </Radio.Button>
            <Radio.Button value="order_cancelled">
              Cancelled Orders
            </Radio.Button>
            <Radio.Button value="availability_check,order_placed,order_confirmed,partly_shipped,shipped,partly_delivered,delivered,refunded,order_cancelled">
              All Orders
            </Radio.Button>
          </Radio.Group>
        </div>
        {/* <div>{dropdownVisible && <FilterButton />}</div> */}
        <div className="my-2">
          <Table
            columns={columns}
            dataSource={data}
            rowKey={"id"}
            loading={loading}
            pagination={false}
            scroll={{
              x: "100%",
            }}
            onRow={(record, index) => ({
              style: {
                background:
                  record?.stage == "order_confirmed" &&
                  moment(new Date())
                    .add(
                      new Date(record?.order_placed_time) <
                        new Date("2024-03-22")
                        ? 5.5
                        : 0,
                      "hours"
                    )
                    .diff(record?.order_placed_time, "minutes") > 60
                    ? "#FFB4A3"
                    : record?.stage == "order_confirmed" &&
                      moment(new Date())
                        .add(
                          new Date(record?.order_placed_time) <
                            new Date("2024-03-22")
                            ? 5.5
                            : 0,
                          "hours"
                        )
                        .diff(record?.order_placed_time, "minutes") > 20
                    ? "#FFFFC0"
                    : "",
                // Number(record?.quantity) === Number(record?.po_raised)
                //   ? "#84FACF"
                //   : Number(record?.quantity) > Number(record?.po_raised)
                //   ? "#FFFFC0"
                //   : "#FFB4A3",
              },
            })}
          />
          <Pagination
            current={page}
            total={total_invoices ?? 1}
            showSizeChanger={false}
            pageSize={10}
            className="mt-3"
            onChange={(p) => {
              if (!filter?.stage) {
                getProFormaInvoices({
                  page: p,
                  estimate_status__in: status__in,
                  ...filter,
                  search,
                  client_search,
                  ordering: sortVal,
                });
              } else {
                getProFormaInvoices({
                  page: p,
                  ...filter,
                  search,
                  client_search,
                  ordering: sortVal,
                });
              }
            }}
          />

          <Modal
            open={basicModal}
            width={1000}
            footer={null}
            onOk={() => setBasicModal(false)}
            onCancel={() => setBasicModal(false)}
          >
            <ProformaInvoicePreview
              id={pIId}
              data={data1}
              client_data={client_data}
            />
          </Modal>
        </div>
      </div>

      <Drawer
        title={"Order details"}
        width={800}
        onClose={() => {
          setEstimateRevisionDrawerVisible(false);
        }}
        open={estimate_revision_drawer_visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          ></div>
        }
      >
        {!revision_data && <div className="mt-2">Loading...</div>}
        {revision_data?.map((data, index) => {
          return (
            <div key={(index + Math.random())?.toString()}>
              <div>
                <div className="row">
                  <div className="col" style={{ margin: "auto" }}>
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#928d8d",
                          fontWeight: "500",
                        }}
                      >
                        Lead Id{" "}
                      </div>
                      <div className="fw-bold" style={{ fontSize: "22px" }}>
                        {data?.lead_id}
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#928d8d",
                        fontWeight: "500",
                      }}
                    >
                      Created by{" "}
                      <span className="fw-bold" style={{ color: "#000" }}>
                        {" "}
                        {data?.revised_by__name}{" "}
                      </span>
                    </div>{" "}
                    <div
                      style={{
                        fontSize: "14px",
                        color: "#928d8d",
                        fontWeight: "500",
                      }}
                    >
                      Created at:
                      <span className="fw-bold" style={{ color: "#000" }}>
                        {getFormattedDate(data?.created_at)}
                      </span>
                    </div>{" "}
                    <br />
                    <Link
                      className="text-decoration-none"
                      to={"/order-details/" + data?.id}
                      target="_blank"
                    >
                      <Button
                        onClick={(e) => {}}
                        type="primary"
                        className="btn d-flex align-items-center justify-content-center border go-to-po-btn"
                      >
                        <div
                          style={{
                            marginLeft: "4px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                          }}
                        >
                          See Estimate
                        </div>
                      </Button>
                    </Link>
                  </div>
                  <div className="col">
                    <div style={{ height: 42 }}></div>
                    <div className="px-2">
                      <div className="" style={{ color: "#928d8d" }}>
                        Pro Forma Invoice No:{" "}
                      </div>
                      <div className="text-right">
                        {data?.estimate_no}{" "}
                        {data?.revision && `(R${data?.revision})`}
                      </div>
                    </div>
                    <div className="px-2">
                      <div className="" style={{ color: "#928d8d" }}>
                        Status:{" "}
                      </div>
                      <div className="text-right">
                        <Tag
                          style={{ textTransform: "capitalize" }}
                          color={data?.status == "draft" ? "gold" : "green"}
                        >
                          {data.status ?? "Raised"}
                        </Tag>
                      </div>
                    </div>
                    <div className="px-2">
                      <div className="" style={{ color: "#928d8d" }}>
                        Total:{" "}
                      </div>
                      <div className="text-right">
                        {Number(data?.total_estimate_amount)?.toFixed(2)}
                      </div>
                    </div>

                    <div
                      style={{
                        background: "#f2f2f2",
                        padding: "2px 10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="" style={{ fontSize: "12px" }}>
                        Inclusive of all taxes
                      </div>
                      <div
                        className="text-right fw-bold"
                        style={{ fontSize: "25px" }}
                      >
                        {"₹"}
                        {Number(data?.total_estimate_amount)?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Drawer>
    </div>
  );
};

export default OrderList;
