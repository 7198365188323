export const getTypeOfOrder = (type: string) => {
  if (type == "po") {
    return "PO";
  }
  if (type == "internal") {
    return "Internal";
  }
  if (type == "estimate") {
    return "Estimate";
  }
  if (type == "WO") {
    return "WO";
  } else {
    return "PO";
  }
};
export const setUserPermissionInLocalStorage = (permission_id: Number) => {};

export const getPIStatusLabel = (type: string) => {
  if (type == "draft") {
    return "Draft";
  } else if (type == "pricing_and_availability_check") {
    return "Pricing and Availability Check";
  } else if (type == "availability_check") {
    return "Request Delivery Timeline";
  } else if (type == "quote_approval_pending") {
    return "Quote Approval pending";
  } else if (type == "quotation_approved") {
    return "Quotation Approved - Initiate Delivery";
  } else if (type == "order_lost") {
    return "Order Lost";
  } else if (type == "dispatched") {
    return "Dispatched";
  } else if (type == "delivered") {
    return "Delivered";
  } else if (type == "refunded") {
    return "Refunded";
  } else if (type == "order_cancelled") {
    return "Cancelled";
  } else {
    return type;
  }
};
export const getPIOrderStatusLabel = (type: string) => {
  if (type == "draft") {
    return "Draft";
  } else if (type == "approval_pending") {
    return "Approval Pending";
  } else if (type == "order_placed") {
    return "Order Placed";
  } else if (type == "quote_approval_pending") {
    return "Quote Approval pending";
  } else if (type == "order_confirmed") {
    return "Order Confirmed";
  } else if (type == "order_lost") {
    return "Order Lost";
  } else if (type == "partly_shipped") {
    return "Partly Shipped";
  } else if (type == "shipped") {
    return "Shipped";
  } else if (type == "partly_delivered") {
    return "Partly Delivered";
  } else if (type == "delivered") {
    return "Delivered";
  } else if (type == "refunded") {
    return "Refunded";
  } else if (type == "availability_check") {
    return "Request Delivery Timeline";
  } else if (type == "order_cancelled") {
    return "Cancelled";
  } else {
    return "";
  }
};

export const getOrderStageLabel = (type: string) => {
  "approval_pending,draft,availability_check,order_confirmed,under_dispatched,delivered";
  if (type == "draft") {
    return "Draft";
  } else if (type == "approval_pending") {
    return "Approval Pending";
  } else if (type == "availability_check") {
    return "Request Delivery Timeline";
  } else if (type == "order_confirmed") {
    return "Order confirmed";
  } else if (type == "under_dispatched") {
    return "Under dispatched";
  } else if (type == "order_lost") {
    return "Order Lost";
  } else if (type == "delivered") {
    return "Delivered";
  } else {
    return "";
  }
};

export const getPODeliveryStatusLabel = (type: string) => {
  if (type == "order_raised") {
    return "Order Raised";
  } else if (type == "dispatch_pending") {
    return "Dispatch Pending";
  } else if (type == "in_store") {
    return "In Warehouse";
  } else if (type == "dispatched") {
    return "Dispatched";
  } else if (type == "delivered") {
    return "Delivered";
  } else {
    return "";
  }
};
