export function getImageUrl(path: string, width: string) {
  var img_url: string = path?.replace(
    "materialdepotimages.s3.ap-south-1.amazonaws.com",
    "dpy2z8n9cxui1.cloudfront.net"
    // `materialdepotimages-compressed-${percent}-percent`
  );

  img_url = img_url?.replaceAll(
    "material-depot-content-files.s3.ap-south-1.amazonaws.com",
    "d3faqy0icgqzj8.cloudfront.net"
  );

  if (img_url) {
    if (img_url[0] === "/") {
      img_url = "https://dpy2z8n9cxui1.cloudfront.net" + img_url;
    }
  }

  img_url = img_url?.replaceAll("+", "%20");
  if (!img_url?.startsWith("https")) {
    img_url = "https://dpy2z8n9cxui1.cloudfront.net" + img_url;
  }
  if (width !== "") {
    img_url += "?width=" + width;
  }

  return img_url;
}

export const reducedImageUrl = (
  percent: number,
  imgUrl: string,
  isMobile: boolean | null | undefined
) => {
  if (percent === 100 || !imgUrl) {
    return imgUrl;
  }
  var newUrl = imgUrl.replace(
    "materialdepotimages.s3.ap-south-1.amazonaws.com",
    "dpy2z8n9cxui1.cloudfront.net"
    // `materialdepotimages-compressed-${percent}-percent`
  );
  newUrl = newUrl.replaceAll(
    "material-depot-content-files.s3.ap-south-1.amazonaws.com",
    "d3faqy0icgqzj8.cloudfront.net"
  );
  if (newUrl[0] == "/") {
    newUrl = "https://dpy2z8n9cxui1.cloudfront.net" + newUrl;
  }

  newUrl = newUrl.replaceAll("+", "%20");

  if (isMobile == undefined || isMobile == null || !isMobile) {
    if (window.innerWidth < 768) {
      if (percent == 20) {
        newUrl += "?width=100";
      } else if (percent == 40) {
        newUrl += "?width=200";
      } else if (percent == 60) {
        newUrl += "?width=360";
      } else if (percent == 80) {
        newUrl += "?width=460";
      }
    } else {
      if (percent == 20) {
        newUrl += "?width=100";
      } else if (percent == 40) {
        newUrl += "?width=300";
      } else if (percent == 60) {
        newUrl += "?width=800";
      } else if (percent == 80) {
        newUrl += "?width=1200";
      }
    }
  } else {
    if (isMobile == true) {
      if (percent == 20) {
        newUrl += "?width=40";
      } else if (percent == 40) {
        newUrl += "?width=80";
      } else if (percent == 60) {
        newUrl += "?width=160";
      } else if (percent == 80) {
        newUrl += "?width=360";
      }
    } else {
      if (percent == 20) {
        newUrl += "?width=100";
      } else if (percent == 40) {
        newUrl += "?width=300";
      } else if (percent == 60) {
        newUrl += "?width=800";
      } else if (percent == 80) {
        newUrl += "?width=1200";
      }
    }
  }

  return newUrl;
};
export const getImage = (
  page: string,
  section: string,
  imgUrl: string,
  isMobile: any
) => {
  const path = imgUrl;
  const width = "";
  if (path?.includes("webp")) {
    var webp_url: string = path?.replace(
      "materialdepotimages.s3.ap-south-1.amazonaws.com",
      "pub-132f3882c2074e84999a9ab982950552.r2.dev"
      // `materialdepotimages-compressed-${percent}-percent`
    );

    webp_url = webp_url?.replace(
      "materialdepotimages.s3.amazonaws.com",
      "pub-132f3882c2074e84999a9ab982950552.r2.dev"
    );
    webp_url = webp_url?.replaceAll(
      "material-depot-content-files.s3.ap-south-1.amazonaws.com",
      "pub-572d4732b7644138867808415beb5a4f.r2.dev"
    );

    return webp_url;
  }

  var img_url: string = path?.replace(
    "materialdepotimages.s3.ap-south-1.amazonaws.com",
    "materialdepot-images-hbh2cjbvbtfmanhx.z02.azurefd.net"
    // `materialdepotimages-compressed-${percent}-percent`
  );

  img_url = img_url?.replaceAll(
    "pub-132f3882c2074e84999a9ab982950552.r2.dev",
    "materialdepot-images-hbh2cjbvbtfmanhx.z02.azurefd.net"
  );

  img_url = img_url?.replaceAll(
    "materialdepotimages.s3.amazonaws.com",
    "materialdepot-images-hbh2cjbvbtfmanhx.z02.azurefd.net"
  );

  img_url = img_url?.replaceAll(
    "material-depot-content-files.s3.ap-south-1.amazonaws.com",
    "materialdepot-content-files-endpoint-e8cnf0c2gxfhe5fb.z02.azurefd.net"
  );
  img_url = img_url?.replaceAll(
    "d3faqy0icgqzj8.cloudfront.net",
    "materialdepot-content-files-endpoint-e8cnf0c2gxfhe5fb.z02.azurefd.net"
  );

  if (
    img_url?.includes(".png") &&
    img_url?.includes("materialdepot-images-hbh2cjbvbtfmanhx.z02.azurefd.net")
  ) {
    img_url = img_url?.replaceAll(
      "materialdepot-images-hbh2cjbvbtfmanhx.z02.azurefd.net",
      "materialdepotimages.materialdepot.in"
    );
  }

  if (
    img_url?.includes(".mp4") ||
    img_url?.includes(".mov") ||
    img_url?.includes(".gif") ||
    img_url?.includes(".svg")
  ) {
    img_url = img_url?.replaceAll(
      "materialdepot-images-hbh2cjbvbtfmanhx.z02.azurefd.net",
      "pub-132f3882c2074e84999a9ab982950552.r2.dev"
    );

    img_url = img_url?.replaceAll(
      "materialdepot-content-files-endpoint-e8cnf0c2gxfhe5fb.z02.azurefd.net",
      "material-depot-content-files-noresize-endpoint-bsbkh4asdwecc9dp.z02.azurefd.net"
    );

    img_url = img_url?.replaceAll(
      "dpy2z8n9cxui1.cloudfront.net",
      "d2cwt1uuomj2h5.cloudfront.net"
    );
  }

  if (
    img_url?.includes(".png") &&
    img_url?.includes(
      "materialdepot-content-files-endpoint-e8cnf0c2gxfhe5fb.z02.azurefd.net"
    )
  ) {
    img_url = img_url?.replaceAll(
      "materialdepot-content-files-endpoint-e8cnf0c2gxfhe5fb.z02.azurefd.net",
      "material-depot-content-files-noresize-endpoint-bsbkh4asdwecc9dp.z02.azurefd.net"
    );
  }

  if (img_url) {
    if (img_url[0] === "/") {
      img_url =
        "https://materialdepot-images-hbh2cjbvbtfmanhx.z02.azurefd.net" +
        img_url;
    }
  }

  img_url = img_url?.replaceAll("+", "%20");
  if (!img_url?.startsWith("https")) {
    img_url =
      "https://materialdepot-images-hbh2cjbvbtfmanhx.z02.azurefd.net" + img_url;
  }
  if (width !== "") {
    img_url += "?width=" + width;
  }

  // img_url = img_url?.replaceAll(
  //   "materialdepot-images-hbh2cjbvbtfmanhx.z02.azurefd.net",
  //   "pub-132f3882c2074e84999a9ab982950552.r2.dev"
  // );

  return img_url;
};
