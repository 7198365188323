import { Button, Form, Input, InputNumber, message, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { fetchBranchMembers } from "../../../apis/branch.api";
import {
  checkAndConfirmOrderPayment,
  createEstimateTransaction,
  fetchPIfromId,
  getEstimateTransactions,
} from "../../../apis/pro_forma_invoice.api";

export interface OrderPlacedDetailsDrawerProps {
  onFinish: (values: any) => void;
  onCancel: () => void;
  estimateId: number;
}

export const PaymentTypes = (pendingAmount: number) => [
  { value: "cash", label: "Cash", referenceTypes: ["others"] },
  {
    value: "neft_rtgs_imps",
    label: "NEFT / RTGS / IMPS",
    referenceTypes: ["utr"],
  },
  { value: "upi", label: "UPI", referenceTypes: ["upi_transaction_id"] },
  { value: "razorpay", label: "Razorpay", referenceTypes: ["others"] },
  { value: "pos", label: "POS", referenceTypes: ["others"] },
  {
    value: "waive-off",
    label: "Waive Off",
    referenceTypes: ["others"],
    disabled: pendingAmount > 1000,
  },
];

export const PaymentReferenceTypes = [
  { value: "utr", label: "UTR" },
  { value: "upi_transaction_id", label: "UPI Transaction ID" },
  { value: "others", label: "Others" },
];

const MODE_VALUE_TO_LABEL = {
  upi: "UPI",
  credit_card: "Credit Card",
  debit_card: "Debit Card",
  bank_emi: "Bank EMI",
  brand_emi: "Brand EMI",
};

export function OrderPlacedDetailsDrawer({
  onFinish,
  onCancel,
  estimateId,
}: OrderPlacedDetailsDrawerProps) {
  const [form] = Form.useForm();
  const [paymentType, setPaymentType] = useState<string | undefined>(undefined);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [estimateDetails, setEstimateDetails] = useState<any>(null);
  const [posPaymentModes, setPosPaymentModes] = useState<
    Record<string, number>
  >({
    upi: 0,
    credit_card: 0,
    debit_card: 0,
    bank_emi: 0,
    brand_emi: 0,
  });
  const [branchMembers, setBranchMembers] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any) => {
    let reference = values?.payment_reference_id;
    if (["cash", "waive-off"].includes(values?.payment_type)) {
      reference = values?.collector;
    }
    const transaction = await createEstimateTransaction({
      estimate_id: estimateId,
      payment_method: values?.payment_type,
      payment_amount: values?.payment_amount,
      payment_reference: reference,
    });

    form.resetFields();
    setTransactions((prev) => [...prev, transaction]);
    setPaymentType(undefined);
    setPosPaymentModes({
      upi: 0,
      credit_card: 0,
      debit_card: 0,
      bank_emi: 0,
      brand_emi: 0,
    });

    onFinish(values);
  };

  const totalPaid = useMemo(() => {
    return (
      transactions
        ?.filter((transaction) => transaction?.status !== "failed")
        .reduce((acc, transaction) => {
          return acc + Number(transaction.payment_amount ?? 0);
        }, 0) / 100
    );
  }, [transactions]);

  useEffect(() => {
    checkAndConfirmOrderPayment(estimateId as any, false).then((res) => {
      if (res) {
        message.success(
          "A payment has already been received for this order, please verify the pending amount."
        );
      }

      getEstimateTransactions(estimateId)
        .then((response) => {
          setTransactions(response);
        })
        .catch((error) => {
          console.error(error);
        });
    });

    fetchPIfromId(String(estimateId))
      .then((response) => {
        setEstimateDetails(response);
      })
      .catch((error) => {
        console.error(error);
      });

    fetchBranchMembers()
      .then((res) => {
        setBranchMembers(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [estimateId]);

  const onValuesChange = (changedValues: any, allValues: any) => {
    // if (changedValues.payment_type) {
    //   setPaymentType(changedValues.payment_type);
    // }

    if (changedValues.payment_type) {
      form.setFieldsValue({
        payment_reference_id: "",
      });
      setPaymentType(changedValues.payment_type);
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={onSubmit}
      onValuesChange={onValuesChange}
      form={form}
    >
      <Form.Item name="payment_type" label="Payment Type" required>
        <Select
          placeholder="Select Payment Type"
          options={PaymentTypes(
            Number(estimateDetails?.total_estimate_amount) - Number(totalPaid)
          )}
        />
      </Form.Item>
      {/* <Form.Item
        name="payment_reference_type"
        label="Payment Reference Type"
        required
      >
        <Select
          placeholder="Select Payment Reference Type"
          options={PaymentReferenceTypes.filter((type) => {
            const selectedPaymentType = PaymentTypes.find(
              (type) => type.value === paymentType
            );
            return selectedPaymentType?.referenceTypes?.includes(type.value);
          })}
        />
      </Form.Item> */}
      {paymentType &&
        !["cash", "waive-off", "pos"].includes(paymentType as string) && (
          <Form.Item
            name="payment_reference_id"
            label="Payment Reference ID"
            required
          >
            <Input placeholder="Enter Payment Reference ID" />
          </Form.Item>
        )}
      {paymentType === "pos" && (
        <div>
          <div className="fw-bold text-center mb-3">Payment Modes</div>
          {Object.keys(posPaymentModes).map((mode) => (
            <div>
              <div className="d-flex gap-2 mx-4 mb-2" key={mode}>
                <Button
                  size="small"
                  onClick={() => {
                    setPosPaymentModes((prev) => ({
                      ...prev,
                      [mode]: prev[mode] - 1,
                    }));
                  }}
                  disabled={posPaymentModes[mode] === 0}
                >
                  -
                </Button>
                <div className="flex-grow-1 text-center">
                  {
                    MODE_VALUE_TO_LABEL[
                      mode as keyof typeof MODE_VALUE_TO_LABEL
                    ]
                  }{" "}
                  ({posPaymentModes[mode]})
                </div>
                <Button
                  size="small"
                  onClick={() => {
                    setPosPaymentModes((prev) => ({
                      ...prev,
                      [mode]: prev[mode] + 1,
                    }));
                  }}
                >
                  +
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
      {["cash", "waive-off"].includes(paymentType as string) && (
        <Form.Item
          name="collector"
          label={paymentType === "cash" ? "Collected By" : "Waived Off By"}
          rules={[
            {
              required: true,
              message: "Please enter the name of the collector",
            },
          ]}
        >
          <Select
            placeholder="Select Collector"
            options={branchMembers
              ?.filter((member) => [1, 3, 4].includes(member?.user_permission))
              ?.map((member) => ({
                value: member.user?.id,
                label: member.user?.f_name,
              }))}
            showSearch
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            optionFilterProp="label"
            className="w-100"
          ></Select>
        </Form.Item>
      )}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <div className="fw-bold">Amount Pending</div>
          <div>
            ₹{" "}
            {(
              Number(estimateDetails?.total_estimate_amount) - Number(totalPaid)
            ).toFixed(2)}
          </div>
        </div>
        <div>
          <div className="fw-bold">Total Paid</div>
          <div>₹ {totalPaid}</div>
        </div>
      </div>
      <Form.Item
        label="Amount"
        name="payment_amount"
        required
        rules={[
          {
            type: "regexp",
            pattern: /^[0-9]+$/,
          },
          // Cannot be more than the amount pending
          {
            validator: async (_, value) => {
              if (paymentType === "waive-off") {
                // Max amount is 1000 for waive-off
                if (Number(value) > 1000) {
                  return Promise.reject(
                    new Error("Amount cannot be more than ₹1000")
                  );
                }
                return Promise.resolve();
              }
              // if (
              //   Number(value) >
              //   Number(estimateDetails?.total_estimate_amount) -
              //     Number(totalPaid)
              // ) {
              //   return Promise.reject(
              //     new Error("Amount cannot be more than the amount pending")
              //   );
              // }
              return Promise.resolve();
            },
          },
        ]}
      >
        <InputNumber className="w-100" placeholder="Enter Amount" />
      </Form.Item>
      <Form.Item className="d-flex justify-content-end">
        <Space>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}
