import { Button, Input, Pagination, Radio, Select, message } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetchBranchMembers } from "../../apis/branch.api";
import { editProFormaInvoiceItem } from "../../apis/pro_forma_invoice.api";
import {
  IProcurementItem,
  fetchProcurementDashboardData,
} from "../../apis/procurement.api";
import { fetchVendors } from "../../apis/vendor.api";
import { ProcurementItemForm } from "../../components/procurement/form";
import { PendingProcurement } from "../../components/procurement/pending-procurement";
import { usePermissions } from "../../custom_hooks/permission/permission_hook";
import { getFormattedDateAndTime } from "../../helpers/date_helper";

const { Option } = Select;

export default function ProcurementManager() {
  const [data, setData] = useState<Array<any>>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState<Array<any>>([]);
  const [filtered_members, setFilteredMembers] = useState<Array<any>>([]);
  const [page, setPage] = useState(1);
  const [vendors, setVendors] = useState<Array<any>>([]);

  const [searchLeadId, setSearchLeadId] = useState("");
  const [selected_member, setSelectedMember] = useState<any>(null);
  const [user, setUser] = useState<any>({});
  const [total_items, setTotalItems] = useState<number>(0);
  const [pageType, setPageType] = useState<"pre-placement" | "post-placement">(
    "post-placement"
  );

  const { hasPermission } = usePermissions();

  const getVendors = async () => {
    const vendors = await fetchVendors({});

    setVendors(vendors);
  };

  useEffect(() => {
    getVendors();
  }, []);

  const getData = async (id?: string, search?: string, page?: number) => {
    setLoading(true);
    const response = await fetchProcurementDashboardData(
      id,
      search,
      page ?? page
    );

    setLoading(false);
    if (response !== null) {
      setData(response?.data);
      setTotalItems(response?.total);
    } else {
      setError(true);
    }
  };

  const columns: ColumnsType<IProcurementItem> = [
    {
      key: "lead_id",
      // dataIndex: "lead_id",
      title: "Lead ID",
      width: 150,
      render: (data) => (
        <div style={{ minWidth: 230 }} className="d-flex align-items-center">
          {" "}
          {data.lead_id}
          <Button
            className="ms-2"
            onClick={async () => {
              try {
                // Copy text to clipboard
                var product_name = data?.boq_item;
                var quantity = data?.quantity ?? "";
                var price_unit = data?.unit ?? "";
                var brand = data?.brand ?? "";

                await navigator.clipboard.writeText(
                  product_name +
                    " \nQuantity:" +
                    quantity +
                    " " +
                    price_unit +
                    " \nBrand - " +
                    brand
                );

                message.success("Item copied");
              } catch (err) {
                console.error("Failed to copy text:", err);
              }
            }}
            type="default"
          >
            Copy Item
          </Button>
        </div>
      ),
    },
    {
      title: "Created at",
      key: "created_at",
      render: (data) => (
        <div style={{ width: 160 }}>
          {getFormattedDateAndTime(data?.created_at)}
        </div>
      ),
    },
    {
      key: "boq_item",
      dataIndex: "boq_item",
      title: "Item Name",
      width: 300,
      render: (data) => <div style={{ minWidth: 300 }}>{data}</div>,
    },
    hasPermission("pi.private_label_brand_name_column")
      ? {
          key: "private_label_product_name",
          title: "Private Label Name",
          dataIndex: "private_label_product_name",
          render: (data) => <div style={{ minWidth: 300 }}>{data}</div>,
        }
      : {},
    {
      key: "brand",
      dataIndex: "brand",
      title: "Brand",
      width: 120,
      render: (data) => {
        return <div style={{ minWidth: 120 }}>{data}</div>;
      },
    },
    {
      key: "category_name",
      dataIndex: "category_name",
      title: "Category",
      width: 250,
    },
    {
      key: "quantity",
      dataIndex: "quantity",
      title: "Quantity",
      width: 150,
    },
    {
      key: "unit",
      dataIndex: "unit",
      title: "Unit",
      width: 150,
    },
    // {
    //   key: "unit_cost_price",
    //   dataIndex: "unit_cost_price",
    //   title: "Unit Cost Price",
    //   width: 150,
    // },
    // {
    //   key: "mrp",
    //   dataIndex: "mrp",
    //   title: "MRP",
    //   width: 150,
    // },
    // {
    //   key: "landing_price",
    //   dataIndex: "landing_price",
    //   title: "Landing Price",
    //   width: 150,
    // },
    // {
    //   key: "margin",
    //   dataIndex: "margin",
    //   title: "Margin",
    //   width: 150,
    // },
    // {
    //   key: "tax",
    //   title: "Tax",
    //   render: (_, record) => `${record.tax_type} (${record.tax_value}%)`,
    // },
    {
      key: "vendor",
      title: "Vendor",
      width: 150,
      render: (data: any) => {
        return (
          <div style={{ minWidth: 150 }}>
            {data?.vendor
              ?.filter((vendor: any) => vendor?.vendor_code)
              ?.map((vendor: any) => `${vendor?.vendor_code} - ${vendor?.name}`)
              .join(", ") ?? ""}
          </div>
        );
      },
    },
    {
      key: "edit",
      title: "Edit",
      render: (_, record) => {
        const onSubmit = async (values: Record<string, string | number>) => {
          try {
            setLoading(true);
            if (
              (values.total_stock === "" ||
                values.total_stock === "0" ||
                values.total_stock === 0) &&
              (record.total_stock === null || record.total_stock === 0)
            ) {
              delete values.total_stock;
            }

            const response = await editProFormaInvoiceItem(record.id, values);
            if (response !== null) {
              // Show success message
              message.success("Saved details.");
              getData(selected_member, searchLeadId);
            }
          } catch (error) {
          } finally {
            setLoading(false);
          }
        };
        return (
          <div style={{ minWidth: 600, maxWidth: 1200 }}>
            {vendors?.length > 0 && (
              <ProcurementItemForm
                onSubmit={onSubmit}
                initData={record}
                vendors={vendors}
                key={record?.id}
              />
            )}
          </div>
        );
      },
      width: 470,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {}, [data, members, filtered_members]);

  useEffect(() => {
    if (selected_member) {
      getData(selected_member, searchLeadId);
    }
  }, [selected_member]);

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      const list: Array<any> = [];
      res?.map((member: any) => {
        list.push(member?.user);
      });
      setMembers(list);
      setFilteredMembers(list);
    });
  }, []);

  const searchWithLeadId = () => {
    getData(selected_member, searchLeadId, 1);
    setPage(1);
  };

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg">
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="pb-2">Procurement Manager</h3>
        <div>
          <Radio.Group
            optionType="button"
            value={pageType}
            onChange={(e) => {
              setPageType(e.target.value);
            }}
          >
            <Radio value="pre-placement">Pre-Placement</Radio>
            <Radio value="post-placement">Post-Placement</Radio>
          </Radio.Group>
        </div>
      </div>
      {pageType === "pre-placement" && (
        <>
          <div className="d-flex justify-content-end mb-4">
            <Select
              style={{ width: 200 }}
              key="prop"
              onChange={(e) => {
                setSelectedMember(e);
              }}
              showSearch
              onSearch={(e) => {
                let filtered = members?.filter((member) => {
                  return member?.f_name?.toLowerCase()?.includes(e);
                });
                console.log("filtered", filtered, e);
                setFilteredMembers([...filtered]);
              }}
              filterOption={false}
              value={selected_member}
              allowClear
            >
              {filtered_members?.map((each) => {
                return (
                  <Option key={each.id} value={each.id}>
                    {each?.f_name}
                  </Option>
                );
              })}
            </Select>
            <Input
              value={searchLeadId}
              onChange={(ev) => setSearchLeadId(ev.target.value)}
              onPressEnter={searchWithLeadId}
              style={{ maxWidth: 400, marginLeft: 8, marginRight: 4 }}
              placeholder="Search with Lead Id"
            />
            <Button
              onClick={() => {
                searchWithLeadId();
              }}
            >
              Search
            </Button>
          </div>
          <Table
            dataSource={data}
            loading={loading}
            pagination={false}
            columns={columns}
            key={"id"}
            scroll={{ x: "100%" }}
            onRow={(record, index) => ({
              style: {
                background:
                  isNaN(record?.lead_time) &&
                  moment(new Date())
                    .add(
                      new Date(record?.order_placed_time) <
                        new Date("2024-03-22")
                        ? 5.5
                        : 0,
                      "hours"
                    )
                    .diff(record?.created_at, "minutes") > 60
                    ? "#FFB4A3"
                    : isNaN(record?.lead_time) &&
                      moment(new Date())
                        .add(
                          new Date(record?.order_placed_time) <
                            new Date("2024-03-22")
                            ? 5.5
                            : 0,
                          "hours"
                        )
                        .diff(record?.created_at, "minutes") > 20
                    ? "#FFFFC0"
                    : "",
                // Number(record?.quantity) === Number(record?.po_raised)
                //   ? "#84FACF"
                //   : Number(record?.quantity) > Number(record?.po_raised)
                //   ? "#FFFFC0"
                //   : "#FFB4A3",
              },
            })}
          />
          <Pagination
            current={page}
            total={total_items ?? 1}
            showSizeChanger={false}
            pageSize={100}
            className="mt-3"
            onChange={(p) => {
              setPage(p);
              getData(selected_member, searchLeadId, p);
            }}
          />
        </>
      )}
      {pageType === "post-placement" && (
        <>
          <PendingProcurement />
        </>
      )}
    </div>
  );
}
