import { Button, Table } from "antd";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";

export interface AddressListProps {
  address_list: Array<any>;
  fetchAddresses: Function;
  showClientAddAddressDrawer: Function;
  showClientEditAddressDrawer: Function;
}

const AddressList = ({
  address_list,
  showClientAddAddressDrawer,
  showClientEditAddressDrawer,
}: AddressListProps) => {
  const { hasPermission } = usePermissions();

  const columns = [
    {
      title: "Address Label",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Address",
      key: "address",
      dataIndex: "address",
    },
    {
      title: "City",
      key: "city",
      dataIndex: "city",
    },
    {
      title: "State",
      key: "state",
      dataIndex: "state",
    },
    {
      title: "Country",
      key: "country",
      dataIndex: "country",
    },
    {
      title: "Pincode",
      key: "pincode",
      dataIndex: "pincode",
    },
    {
      title: "Lat",
      key: "lat",
      dataIndex: "lat",
      render: (data: any) => Number(data)?.toFixed(4),
    },
    {
      title: "Long",
      key: "long",
      dataIndex: "long",
      render: (data: any) => Number(data)?.toFixed(4),
    },
    {
      title: "Google Map Link",
      key: "google_map_address",
      render: (data: any) => (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${data.lat},${data.long}`}
          target="_blank"
          rel="noreferrer"
          style={{
            whiteSpace: "nowrap",
          }}
        >
          Google Map
        </a>
      ),
    },
    // {
    //   title: "Actions",
    //   key: "action",
    //   render: (data: any) => (
    //     <Space>
    //       <span>-</span>
    //       {/* <Tag
    //         onClick={() => {
    //           // onUpdate(data.id);
    //           showClientEditAddressDrawer(data);
    //         }}
    //         className="cursor-pointer"
    //         color={"warning"}
    //       >
    //         Edit Details
    //       </Tag> */}
    //       {/* <Popconfirm
    //         okText="Yes"
    //         cancelText="No"
    //         title="Are you sure to delete?"
    //         // onConfirm={() => onDelete(data.id)}
    //       >
    //         <Tag color="error">Delete</Tag>
    //       </Popconfirm> */}
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div className=" p-3 py-2">
      <div className="d-flex justify-content-between align-items-center pb-3">
        <div className="th-font-size-18 th-font-weight-600 primary-color-text align-middle">
          <div> Addresses</div>
        </div>
        <div>
          {hasPermission("pi.create") && (
            <Button
              size="middle"
              className="fw-bold"
              type="primary"
              onClick={() => {
                showClientAddAddressDrawer();
              }}
            >
              Add address
            </Button>
          )}
        </div>
      </div>
      <div className="row gap-3">
        <div className="th-font-size-16 th-font-weight-600 ">
          Billing Addresses(
          {address_list.filter((obj) => obj.is_billing === true).length}) :
        </div>

        <Table
          columns={columns}
          dataSource={address_list.filter((obj) => obj.is_billing === true)}
          pagination={false}
          // loading={loading}
          rowKey={"id"}
          scroll={{
            x: "100%",
          }}
        />
        {/* {address_list?.map((address, index) => {
          if (address?.is_billing)
            return (
              <>
                <AddressCard
                  address={address}
                  showClientEditAddressDrawer={showClientEditAddressDrawer}
                />
              </>
            );
        })} */}
      </div>
      <div className="row gap-3 mt-3">
        <div className="th-font-size-16 th-font-weight-600 ">
          Shipping Addresses(
          {address_list.length}) :
        </div>
        <Table
          columns={columns}
          dataSource={address_list}
          pagination={false}
          // loading={loading}
          rowKey={"id"}
          scroll={{
            x: "100%",
          }}
        />
        {/* {address_list?.map((address, index) => {
          if (!address?.is_billing)
            return (
              <>
                <AddressCard
                  address={address}
                  showClientEditAddressDrawer={showClientEditAddressDrawer}
                />
              </>
            );
        })} */}
      </div>
    </div>
  );
};

export default AddressList;
