import { Button, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getPurchaseOrders } from "../../../../apis/po.api";
import { fetchPIfromId } from "../../../../apis/pro_forma_invoice.api";
import TaxAmount from "../../../../components/pro_forma_invoice/tax_amount";
import { getFormattedDateAndTime } from "../../../../helpers/date_helper";
import { statusOptions } from "../../../dashboard";
import { status_type } from "../../../order/order_details";

export function MobileOrderDetails() {
  const { estimateId } = useParams<{ estimateId: string }>();
  const [estimate, setEstimate] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [poList, setPOList] = useState<any[]>([]);

  useEffect(() => {
    if (!estimateId) return;

    setLoading(true);
    fetchPIfromId(estimateId).then((data) => {
      setEstimate(data);
      setLoading(false);
    });
    getPurchaseOrders({ estimate: estimateId }).then((data) => {
      setPOList(data?.results);
    });
  }, [estimateId]);

  const columns = [
    {
      title: "S.no",
      dataIndex: "index",
      render: (value: any, data: any, index: number) => <>{index + 1}</>,
    },
    {
      title: "BOQ Item",
      key: "boq_item",
      dataIndex: "boq_item",
      width: 200,
      render: (_: unknown, record: any) => {
        return record["boq_item"];
      },
    },
    {
      title: "MRP",
      key: "mrp",
      dataIndex: "mrp",
      width: 100,
    },
    {
      title: "Category",
      key: "category_name",
      dataIndex: "category_name",
      width: 100,
    },
    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "quantity",
      width: 50,
    },
    {
      title: "Unit",
      key: "unit",
      dataIndex: "unit",
      width: 60,
    },
    {
      title: "Selling Price",
      key: "unit_cost_price",
      width: 100,
      render: (data: { [x: string]: string }) =>
        parseFloat(data["unit_cost_price"]),
    },
    {
      title: "Tax",
      key: "tax",
      render: (data: { tax_value: any }) => {
        var tax = Number(data?.tax_value);
        return (
          <>
            <div>{tax}%</div>
          </>
        );
      },
    },
    {
      title: "Tax Type",
      key: "tax_type",
      render: (data: { tax_type: string }) => (
        <>
          {data?.tax_type === "igst" && "IGST"}
          {data?.tax_type === "cgst_sgst" && "CGST & SGST"}
          {data?.tax_type === "none" && "None"}
        </>
      ),
    },
    {
      title: "Lead time",
      key: "lead_time",
      render: (data: any) => {
        return (
          <div style={{ maxWidth: 60 }}>
            {/* {parseFloat(data["lead_time"]) > -1 ? (
              <>{data["lead_time"]} days</>
            ) : (
              <></>
            )} */}
            {data["lead_time"] && data["lead_time"] !== null && (
              <>{data["lead_time"]} days</>
            )}
          </div>
        );
      },
    },
    {
      title: "Remarks",
      key: "remarks",
      render: (data: any) => {
        return <div style={{ maxWidth: 200 }}> {data["remarks"]}</div>;
      },
    },
    {
      title: "Amount",
      key: "amount",
      render: (data: { [x: string]: number }) => {
        return data["total_price"];
      },
    },
  ];

  const poColumns = [
    {
      title: "Po No.",
      key: "po_number",
      dataIndex: "po_number",
      // fixed: "left",
    },
    {
      title: "Dispatch Time",
      key: "dispatch_time",
      render: (data: { dispatch_time: any }) => (
        <span>{getFormattedDateAndTime(data?.dispatch_time)}</span>
      ),
    },
    {
      title: "Status",
      key: "po_delivery_status",
      width: 200,
      render: (data: any) => (
        <>
          {data?.is_deleted ? (
            <>PO Cancelled</>
          ) : (
            <>
              {
                status_type?.find((s) => s.value === data?.po_delivery_status)
                  ?.label
              }
            </>
          )}
        </>
      ),
    },
    {
      title: "Delivery link",
      key: "live_location_link",
      render: (data: any) => (
        <div style={{ width: 160 }}>
          {data?.live_location_link ? (
            <Tag
              className="cursor-pointer"
              onClick={() => {
                window.open(
                  data?.live_location_link?.includes("https://")
                    ? data?.live_location_link
                    : "https://" + data?.live_location_link,
                  "_blank"
                );
              }}
              color="blue"
            >
              Location link
            </Tag>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <h3 className="fw-bold mb-3">Order Details</h3>
        <Link to="/orders">
          <Button type="link" size="small">
            Back
          </Button>
        </Link>
      </div>

      {loading && (
        <div className="text-center alert alert-info">Loading...</div>
      )}
      <div className="rounded shadow-sm p-3 mb-4">
        <h5 className="fw-bold">{estimate?.lead_id}</h5>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: ".5rem 0",
          }}
        >
          <div>
            <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
              Created By
            </div>
            {estimate?.created_by}
          </div>
          <div>
            <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
              Status
            </div>
            {
              statusOptions.find((s) => s.value === estimate?.estimate_status)
                ?.label
            }
          </div>
        </div>
      </div>
      {estimate?.ship_address && (
        <div className="rounded shadow-sm p-3 mb-4">
          <div className="row">
            <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
              Ship To
            </div>

            <div className="row">
              <div className="col-4 th-font-weight-500">Name </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{estimate?.ship_address?.name}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 th-font-weight-500">Address </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{estimate?.ship_address?.address}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 th-font-weight-500">Pincode </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{estimate?.ship_address?.pincode}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 th-font-weight-500">Contact person </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{estimate?.ship_address?.contact_person_name}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 th-font-weight-500">Contact number</div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{estimate?.ship_address?.contact_person_contact}</div>
                </div>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-4">GST No.</div>
              <div className="col-8 d-flex">
                <div>:</div>
                <div className="w-100 px-2">
                  {estimate?.ship_address?.gst_no}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {estimate?.bill_address && (
        <div className="rounded shadow-sm p-3 mb-4">
          <div className="row">
            <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
              Bill To
            </div>

            <div className="row">
              <div className="col-4 th-font-weight-500">Name </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{estimate?.bill_address?.name}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 th-font-weight-500">Address </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{estimate?.bill_address?.address}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 th-font-weight-500">Pincode </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{estimate?.bill_address?.pincode}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 th-font-weight-500">Contact person </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{estimate?.bill_address?.contact_person_name}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 th-font-weight-500">Contact number</div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{estimate?.bill_address?.contact_person_contact}</div>
                </div>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-4">GST No.</div>
              <div className="col-8 d-flex">
                <div>:</div>
                <div className="w-100 px-2">
                  {estimate?.bill_address?.gst_no}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <h5 className="fw-bold mb-3">Order Items</h5>
      <Table
        columns={columns}
        dataSource={estimate?.estimate_items}
        pagination={false}
        rowKey="id"
        scroll={{
          x: "max-content",
        }}
        className="mb-3"
      />
      <div className="rounded shadow-sm p-3 mb-4" style={{ fontSize: 14 }}>
        <TaxAmount items={estimate?.estimate_items ?? []} />
        {estimate?.estimate_additional_charges?.map(
          (each: any, ind: number) => (
            <>
              <div className="d-flex justify-content-between" key={ind}>
                <div className="">
                  {each?.charge_type}
                  {each?.charge_type?.toLowerCase()?.includes("discount")
                    ? " (as per item)"
                    : ` (@${each?.tax_value + "%"} ${each?.tax_type})`}
                </div>
                <div className="">
                  {Number(
                    Number(each?.amount) +
                      Number(each?.amount) * Number(each?.tax_value) * 0.01
                  ).toFixed(2)}
                </div>
              </div>
              {each.remark && each.remark !== "" && (
                <div>Remark: {each.remark}</div>
              )}
            </>
          )
        )}
        {parseFloat(estimate?.coupon_discount_amount ?? "0") > 0 && (
          <>
            <div className="d-flex justify-content-between">
              <div className="">Coupon Discount</div>
              <div className="">
                ₹ {Number(estimate?.coupon_discount_amount).toFixed(2)}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="th-font-size-12">Coupon Code</div>
              <div className="th-font-size-12">
                {estimate?.coupon_data?.coupon_code}
              </div>
            </div>
          </>
        )}
        <div className="d-flex justify-content-between mt-3">
          <div className="">
            <h6>Total Amount</h6>
          </div>
          <div className="">
            {Number(estimate?.total_estimate_amount)}
            (Rounded off)
          </div>
        </div>
      </div>
      <h5 className="fw-bold mb-3">POs</h5>
      <Table
        columns={poColumns}
        dataSource={poList}
        pagination={false}
        rowKey="id"
        scroll={{
          x: "max-content",
        }}
        className="mb-3"
      />
      <div className="rounded shadow-sm p-3 mb-4">
        <h5 className="fw-bold">Terms and Conditions</h5>
        <div
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {estimate?.terms_and_condition?.description}
        </div>
      </div>
    </div>
  );
}
