import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { fetchUserDetails } from "../../../../apis/user.api";
import { range } from "../../../client/add_client_booking_form";
import { dispatchDateAllowChangeIDs } from "../../po_details/po_order_details";
const { Option } = Select;

export interface BankDetails {
  bank_account_number: string;
  bank_ifsc: string;
  branch_upi: string;
}

const type_of_order_options = [
  {
    id: 1,
    label: "PO",
    value: "po",
  },
  {
    id: 2,
    label: "WO",
    value: "wo",
  },
  {
    id: 3,
    label: "Internal",
    value: "internal",
  },
  {
    id: 4,
    label: "Estimate",
    value: "estimate",
  },
];
const status_type: Array<any> = [
  // {
  //   id: 1,
  //   label: "Order Raised",
  //   value: "order_raised",
  // },
  {
    id: 2,
    label: "Dispatch Pending",
    value: "dispatch_pending",
  },
  {
    id: 5,
    label: "In Warehouse",
    value: "in_store",
  },
  {
    id: 3,
    label: "Dispatched",
    value: "dispatched",
  },
  {
    id: 4,
    label: "Delivered",
    value: "delivered",
  },
];
export interface POCreateOrderDetailsProps {
  client_data: any;
  setData: Function;
  data: any;
}

const POCreateOrderDetails = ({
  client_data,
  setData,
  data,
}: POCreateOrderDetailsProps) => {
  const [user_details, setUserDetails] = useState<any>(null);
  const [po_type, setPOType] = useState("po");
  const [po_delivery_status, setPODeliveryStatus] =
    useState("dispatch_pending");

  useEffect(() => {
    fetchUserDetails().then((res) => {
      setUserDetails(res);
    });
  }, []);

  useEffect(() => {
    if (po_type != data?.type_of_order && data?.type_of_order) {
      setData("type_of_order", po_type);
    }
  }, [po_type]);

  useEffect(() => {
    if (
      po_delivery_status != data?.po_delivery_status &&
      data?.po_delivery_status
    ) {
      setData("po_delivery_status", po_delivery_status);
    }
  }, [po_delivery_status]);

  useEffect(() => {
    if (data?.type_of_order != po_type && data?.type_of_order) {
      setPOType(data?.type_of_order);
    }
    if (
      data?.po_delivery_status != po_delivery_status &&
      data?.po_delivery_status
    ) {
      setPODeliveryStatus(data?.po_delivery_status);
    }
  }, [data]);
  return (
    <div className="p-3 py-2 row mb-2">
      <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
        Order Details
      </div>
      <div className="col">
        <div className="row">
          <div className="col-4 th-font-weight-500">Created By</div>
          <div className="col-8 pb-1">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{user_details?.name}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Client </div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div>{client_data?.f_name}</div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Status</div>
          <div className="col-8">
            <div className="d-flex ">
              <div className="pe-1">:</div>
              <div className="w-100">
                <Select
                  className="w-100"
                  key={"po_delivery_status"}
                  value={data?.po_delivery_status}
                  onChange={(e) => {
                    setPODeliveryStatus(e);
                  }}
                >
                  {status_type?.map((each, index) => (
                    <Option value={each?.value} key={each?.id}>
                      {each?.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Order Type</div>
          <div className="col-8">
            <div className="d-flex ">
              <div className="pe-1">:</div>
              <div className="w-100">
                <Select
                  className="w-100"
                  key={"type_of_order"}
                  value={po_type}
                  onChange={(e) => {
                    setPOType(e);
                  }}
                >
                  {type_of_order_options?.map((each, index) => (
                    <Option value={each?.value} key={each?.id}>
                      {each?.label}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row py-2">
          <div className="col-4 th-font-weight-500">Delivery Date</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="w-100">
                <DatePicker
                  className="w-100"
                  placeholder="Select Deadline"
                  value={
                    dayjs(data?.delivery_date) ??
                    dayjs(moment().format("YYYY-MM-DD").toString())
                  }
                  onChange={(e: any, dateString) => {
                    setData("delivery_date", dateString);
                  }}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="row py-2">
          <div className="col-4 th-font-weight-500">Dispatch Time & Date</div>
          <div className="col-8">
            <div className="d-flex">
              <div className="pe-1">:</div>
              <div className="w-100">
                {dispatchDateAllowChangeIDs.includes(user_details?.id) ? (
                  <DatePicker
                    showSecond={false}
                    showTime={{
                      minuteStep: 30,
                      format: "HH:mm",
                      hideDisabledOptions: true,
                    }}
                    disabledTime={(current: any) => {
                      return {
                        disabledHours: () => range(0, 10).concat(range(18, 24)),
                      };
                    }}
                    className="w-100"
                    placeholder="Select Dispatch Time"
                    value={dayjs(data?.dispatch_time ?? null)}
                    onChange={(e: any, dateString) => {
                      setData("dispatch_time", dateString);
                    }}
                  />
                ) : (
                  <div>
                    {dayjs(data?.dispatch_time).format("DD MMM YYYY, HH:mm")}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default POCreateOrderDetails;
